import { filter, isEmpty } from "lodash";
import { ReactComponent as NotificationIcon } from "../assets/icons/Notification.svg";
import { ReactComponent as UserCircleIcon } from "../assets/icons/UserCircle.svg";
import { useUI } from "../UIContext";
import { useNotifications } from "../NotificationsContext";

export default function HeaderButtons() {
  const { setLocation } = useUI();
  const { notifications } = useNotifications();
  const alerts = filter(notifications, { status: "Approved" });

  return (
    <div className="flex flex-row gap-2 justify-items-end px-1 right-4 translate-y-[2px] absolute">
      <button onClick={() => setLocation("/settings")}>
        <UserCircleIcon className="h-[21px]" />
      </button>
      <button onClick={() => setLocation("/notifications")}>
        {!isEmpty(alerts) && (
          <div className="absolute bg-ppi-green text-white flex flex-col items-center justify-center min-w-[13px] h-[13px] rounded-full text-[10px] font-bold translate-y-[-3px] translate-x-[10px]">
            <div>{alerts.length}</div>
          </div>
        )}
        <NotificationIcon className="h-[21px]" />
      </button>
    </div>
  );
}
