import { useState } from "react";
import { kebabCase } from "lodash";
import AppLayout from "../../Layout";
import { ReactComponent as DetailsIcon } from "../../assets/icons/Details.svg";
import { ReactComponent as DetailsGreenIcon } from "../../assets/icons/DetailsGreen.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import { useUI } from "../../UIContext";
import { useJob } from "../JobContext";
import FooterButtons from "./buttons";
import ItemsList from "./items_list";
import DetailsDrawer from "../../components/JobDetails";
import EditDrawer from "../../components/EditLineItem";
import ProgressBar from "../../components/progress";
import { FormattedMessage } from "react-intl";
import RoomHeader from "./header";
import HeaderButtons from "../../components/header_buttons";
import JobRoomSkeleton from "./skeleton";
import HeaderTitle from "../../components/header_title";

export default function ScheduleRoomDisplay() {
  const { setLocation } = useUI();
  const {
    filtered_line_items: line_items,
    job_id,
    line_item,
    loading: loading_line_items,
    new_line_item,
    room: room_route,
    phase,
    record_id,
    job,
    setLineItem,
    updateLineItem,
  } = useJob();

  const [new_notes, setNotes] = useState<string>("");

  const [open, setOpen] = useState<string | null>(null);

  const sample_line_item = line_items?.[0] || {};

  const loading = !!(!room_route || !sample_line_item || loading_line_items);

  function FooterEditButton() {
    return (
      <div className="flex flex-row items-center justify-center grow">
        <div
          className="flex flex-row items-center justify-center bg-ppi-blue border-2 border-black rounded-full text-white h-[50px] w-[50px] cursor-pointer"
          onClick={() => {
            setOpen(open === "edit" ? null : "edit");
          }}
        >
          <EditIcon className="h-[21px]" />
        </div>
      </div>
    );
  }

  function FooterButton() {
    return (
      <div className="flex flex-row items-end justify-end grow">
        <div
          className="flex flex-col items-center text-center cursor-pointer"
          onClick={() => {
            setOpen(open === "details" ? null : "details");
          }}
        >
          <div>
            {open === "details" ? (
              <DetailsGreenIcon className="h-[21px]" />
            ) : (
              <DetailsIcon className="h-[21px]" />
            )}
          </div>
          <div
            className={`text-xs2 leading-xs2 ${
              open === "details" && "text-ppi-green"
            } font-medium`}
          >
            <FormattedMessage id="Details" />
          </div>
        </div>
      </div>
    );
  }
  const changed_to_incomplete =
    new_line_item?.installation_status === "Incomplete" &&
    new_line_item.installation_status !== line_item?.installation_status;
  return (
    <AppLayout
      details={
        <div
          className="fixed top-0 bg-[#F4F5F8] flex-1 h-full w-full z-50 pt-[72px] pb-[88px] overflow-y-auto pointer-events-auto"
          style={{
            transform:
              open === "details"
                ? "translate3d(0vw, 0, 0)"
                : "translate3d(100vw, 0, 0)",
            transition: "transform .5s cubic-bezier(0, .52, 0, 1)",
          }}
        >
          <DetailsDrawer job_id={job_id} setOpen={setOpen} type="install" />
        </div>
      }
      edit={
        <>
          <div
            className="fixed top-0 bg-[#F4F5F8] border-t-[2px] border-black flex-1 w-full z-50 pointer-events-auto"
            style={{
              height: "calc(100% - 144px)",
              transform:
                open === "edit"
                  ? "translate3d(0, 70px, 0)"
                  : "translate3d(0, 100vh, 0)",
              transition: "transform .5s cubic-bezier(0, .52, 0, 1)",
            }}
          >
            {job && line_item && new_line_item ? (
              <EditDrawer
                is_ready={!changed_to_incomplete || !!new_notes}
                job_info={job}
                line_item={line_item}
                new_line_item={new_line_item}
                new_notes={new_notes}
                setLineItem={setLineItem}
                setNotes={setNotes}
                setOpen={setOpen}
                updateLineItem={updateLineItem}
              />
            ) : (
              <FormattedMessage id="Loading..." />
            )}
          </div>
          {!open && <FooterButtons setOpen={setOpen} />}
        </>
      }
      footer_edit={<FooterEditButton />}
      footer={<FooterButton />}
      header={
        <>
          <HeaderTitle title={job?.mobile_app_header} />
          <div className="flex flex-row items-center justify-items-end px-1  absolute w-full">
            <div className="grow">
              <button
                onClick={() =>
                  setLocation(`/job/${job_id}/${kebabCase(phase)}/${record_id}`)
                }
                className="border-2 border-black text-white bg-ppi-blue font-bold px-2 py-1 rounded-md"
              >
                <FormattedMessage id="Back" />
              </button>{" "}
            </div>
            <HeaderButtons />
          </div>
        </>
      }
    >
      <div className="flex flex-col gap-4 px-4 z-0 overflow-y-touch pb-[144px]">
        {loading ? (
          <JobRoomSkeleton />
        ) : (
          <>
            <RoomHeader />
            <ProgressBar line_items={line_items} />
            <ItemsList />
          </>
        )}
      </div>
    </AppLayout>
  );
}
