import moment from "moment";
import { ReactComponent as CalendarIcon } from "../assets/icons/Calendar.svg";
import { ReactComponent as CalendarGreenIcon } from "../assets/icons/CalendarGreen.svg";
import { ReactComponent as ForwardArrowIcon } from "../assets/icons/ForwardArrow.svg";
import { FormattedMessage } from "react-intl";
import Calendar from "./calendar";
import { useSchedule } from "./ScheduleContext";

export default function ScheduleHeader() {
  const { day_object, open_calendar, setDayObject, setOpenCalendar } =
    useSchedule();

  function getHeaderText(day_object: any) {
    const today = moment().startOf("day");
    const reference = day_object.clone().startOf("day");
    const yesterday = today.clone().subtract(1, "days").startOf("day");
    const tomorrow = today.clone().add(1, "days").startOf("day");
    const isToday = today.isSame(reference, "d");
    const isYesterday = day_object.isSame(yesterday, "d");
    const isTomorrow = day_object.isSame(tomorrow, "d");
    if (isToday) {
      return "Today";
    }
    if (isYesterday) {
      return "Yesterday";
    }
    if (isTomorrow) {
      return "Tomorrow";
    }
    return reference.format("ll");
  }

  return (
    <>
      <div className="flex flex-row items-center">
        <div className="flex flex-row items-center grow">
          <div className="font-bold text-xl text-center">
            <FormattedMessage id={getHeaderText(day_object)} />
          </div>
          <div className="h-[28px] p-1">
            <button
              onClick={() => {
                const day_object_ = day_object.clone().add(1, "days");
                setDayObject(day_object_);
              }}
            >
              <ForwardArrowIcon className="h-[21px]" />
            </button>
          </div>
        </div>
        <div className="flex flex-row justify-items-center">
          <button
            onClick={() => {
              const flip = !open_calendar;
              setOpenCalendar(flip);
            }}
          >
            {open_calendar ? (
              <CalendarGreenIcon className="h-[21px]" />
            ) : (
              <CalendarIcon className="h-[21px]" />
            )}
          </button>
        </div>
      </div>
      {open_calendar && <Calendar />}
    </>
  );
}
