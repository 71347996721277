import moment from "moment";
import { useEffect } from "react";
import { useUser } from "../UserContext";
import SignInEmailForm from "./email_form";
import SignInPinPad from "./pin_pad";
import { useUI } from "../UIContext";
export default function SignIn() {
  const { consoleLog } = useUI();
  const { loading, login, getAndSetUser } = useUser();

  // Get cached query if it exists go straigh into login

  async function getCachedQuery() {
    const cached_query = localStorage.getItem("saved_user");
    if (
      typeof cached_query === "string" &&
      cached_query !== "undefined" &&
      cached_query !== "null"
    ) {
      const { where, time } = JSON.parse(cached_query);
      const is_recent = moment().diff(moment(time), "days") < 1;
      if (is_recent && where) {
        consoleLog("GETTING RECENT USER", where);
        getAndSetUser(where);
      } else {
        localStorage.removeItem("saved_user");
      }
    }
    return null;
  }

  useEffect(() => {
    !loading && getCachedQuery();
  });

  if (!!login) return <SignInPinPad />;
  return <SignInEmailForm />;
}
