import { indexOf } from "lodash";

function changeRole({ role, user }) {
  const user_level = indexOf(
    ["Admin", "Area Manager", "Foreman", "Crew"],
    user?.scheduling_role
  );
  const req_level = indexOf(["Admin", "Area Manager", "Foreman", "Crew"], role);
  const check = user_level <= req_level;
  console.log("Check", role, user_level, req_level, check);
  return check;
}

const rules = {
  Admin: {
    static: [
      "simulate:roles",
      "view:field-management",
      "view:qi",
      "view:lookup",
      "view:trucks",
    ],
    dynamic: {
      "change:role": changeRole,
    },
  },
  "Area Manager": {
    static: [
      "simulate:roles",
      "view:field-management",
      "view:qi",
      "view:lookup",
      "view:trucks",
    ],
    dynamic: {
      "change:role": changeRole,
    },
  },
  Foreman: {
    static: [],
  },
  Crew: {
    static: [],
  },
  Example: {
    static: [
      "create:collection",
      "test:beta",
      "view:collections",
      "view:details:person",
    ],
    dynamic: {
      "edit:collection": ({ collection, user }) => {
        const curator = collection?.curator?._id;
        const user_ = user?._id;
        return curator === user_;
      },
    },
  },
};

export default rules;
