import { ReactComponent as BackArrowIcon } from "../assets/icons/BackArrow.svg";
// import { useSettings } from "../Settings/SettingsContext";
// import { useUI } from "../UIContext";
import { useUser } from "../UserContext";

export default function LayoutHeader({
  back,
  header,
  header_bottom,
}: {
  back?: boolean;
  header?: any;
  header_bottom?: any;
}) {
  // const { language, setLocation, toggleLanguage } = useUI();
  // const { simulated_role } = useSettings();
  const { user } = useUser();

  if (!user) return null;
  const { first_name, last_name } = user;

  function BackButton() {
    return (
      <div className="flex flex-row justify-items-start px-1 absolute">
        <div>
          <BackArrowIcon className="h-[21px] " />
        </div>
      </div>
    );
  }
  return (
    <header
      className={`fixed top-0 w-full flex flex-col justify-center bg-white h-[72px] border-b-2 border-black z-50`}
    >
      <div className="flex flex-col justify-center p-2">
        {header || (
          <>
            {first_name} {last_name}
          </>
        )}
        {back && <BackButton />}
      </div>
      {header_bottom && (
        <div className="fixed top-[72px] w-full">{header_bottom}</div>
      )}
    </header>
  );
}
//shadow-[0_4px_6px_-1px_rgba(0,0,0,0.25)]
