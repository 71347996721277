import {
  filter,
  groupBy,
  // isEmpty,
  kebabCase,
  // keys,
  map,
  orderBy,
  // snakeCase,
  // sortBy,
  sum,
  uniq,
} from "lodash";
import { FormattedMessage } from "react-intl";
import { getPercentages, translateRoom } from "../../../context/functions";
import { useUI } from "../../../UIContext";
import { useQIJob } from "../QIJobContext";

export default function QILocations() {
  const { setLocation } = useUI();
  const { job_id, line_items, phase } = useQIJob();

  const by_location = groupBy(line_items, function (p: any) {
    return p?.room || "no location";
  });

  const rooms = orderBy(
    uniq(
      map(line_items, function (i: any) {
        return i?.room || "no location";
      })
    )
  );

  function showCompletion(complete: number, total: number) {
    const num = complete / total;
    switch (true) {
      case num === 1:
        return (
          <div className="font-semibold text-ppi-green">
            {complete}/{total}
          </div>
        );
      case num > 0 && num < 1:
        return (
          <div className="font-semibold text-yellow-500">
            {complete}/{total}
          </div>
        );
      default:
        return (
          <div className="font-semibold text-red-600">
            {complete}/{total}
          </div>
        );
    }
  }

  return (
    <div className="flex flex-col gap-2 text-black">
      {rooms.map((room: any, idx: number) => {
        const items = by_location[room || "no location"];
        const completed_items = sum(
          map(filter(items, ["qi_status", "Pass"]), "quantity")
        );
        const total_items = sum(map(items, "quantity"));
        return (
          <div
            key={idx}
            className={`px-2 text-xs bg-white border-2 border-black rounded-md`}
            onClick={() => {
              setLocation(
                `/qi/${job_id}/${kebabCase(phase)}/${kebabCase(room)}`
              );
            }}
          >
            <div className="flex flex-row py-3 text-xs2 leading-xs2">
              <div className="grow">
                {room === "no location" ? (
                  <span className="text-gray-400 italic">
                    <FormattedMessage id="no location" />
                  </span>
                ) : (
                  <span className="font-bold">{translateRoom(room)}</span>
                )}
              </div>
              <div className="flex flex-row justify-end">
                {showCompletion(completed_items, total_items)}
                {false && (
                  <div className="flex flex-row bg-gray-300 w-12 shadow h-1">
                    <div
                      className={`bg-green-500`}
                      style={{
                        width: getPercentages({
                          items,
                          status_field: "qi_status",
                          status_check: "Pass",
                        }).complete,
                      }}
                    />
                    <div
                      className={`bg-red-600`}
                      style={{
                        width: getPercentages({
                          items,
                          status_field: "qi_status",
                          status_check: "Fail",
                        }).in_progress,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  // return (
  //   <div>
  //     <table className="w-full">
  //       <thead>
  //         <tr className="text-xs text-left border-b border-gray-500">
  //           <th className="pl-1">
  //             <FormattedMessage id="Locations" />
  //           </th>
  //           <th></th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {rooms.map((room: any, idx: number) => {
  //           const items = by_location[room || "no location"];
  //           return (
  //             <tr
  //               key={idx}
  //               className={`text-xs px-1 ${
  //                 idx % 2 === 0 ? "bg-white" : "bg-slate-100"
  //               }`}
  //               onClick={() => {
  //                 setLocation(
  //                   `/qi/${job_id}/${snakeCase(phase)}/${kebabCase(room)}`
  //                 );
  //               }}
  //             >
  //               <td className="py-3 pl-2">
  //                 {room === "no location" ? (
  //                   <span className="text-gray-400 italic">no location</span>
  //                 ) : (
  //                   room
  //                 )}
  //               </td>
  //               <td className="py-1 pr-2 text-right">
  //                 <div className="flex flex-row justify-end">
  //                   <div className="flex flex-row bg-gray-300 w-12 shadow h-1">
  //                     <div
  //                       className={`bg-green-500`}
  //                       style={{
  //                         width: getPercentages({
  //                           items,
  //                           status_field: "qi_status",
  //                           status_check: "Pass",
  //                         }).complete,
  //                       }}
  //                     />
  //                     <div
  //                       className={`bg-red-600`}
  //                       style={{
  //                         width: getPercentages({
  //                           items,
  //                           status_field: "qi_status",
  //                           status_check: "Fail",
  //                         }).in_progress,
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //           );
  //         })}
  //       </tbody>
  //     </table>
  //     {isEmpty([]) && false && (
  //       <div className="flex flex-col items-center justify-center h-16">
  //         <div className="font-semibold text-gray-300 italic">
  //           No Subdivisions Loaded
  //         </div>
  //       </div>
  //     )}
  //   </div>
  // );
}
