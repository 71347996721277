import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  REACT_APP_TABLE_CONTACTS,
  REACT_APP_TABLE_LIEN_RELEASES,
  REACT_APP_BACKEND_API,
} from "../context/variables";
import Decline from "./decline";
import AcceptSign from "./accept_sign";
import Release from "./release";
import RaiseIssue from "./issue";
import { useUser } from "../UserContext";
import {
  LienRelease as LienReleaseType,
  LienReleaseFields,
} from "../context/types";

export default function LienRelease({ record_id }: { record_id: string }) {
  const where = `{3.EX.${record_id}}`;
  const { user } = useUser();
  const [view, setView] = useState<string>("release");
  const [loading, setLoading] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [lien_release, setLienRelease] = useState<{
    id: string;
    data: LienReleaseType[];
  } | null>(null);

  const getAndSetLienRelease = useCallback(
    async (where?: string) => {
      setLoading(true);
      const { data: lien_release_ } = await axios.post(
        (REACT_APP_BACKEND_API || "") + "/api/quickbase",
        {
          table_id: REACT_APP_TABLE_LIEN_RELEASES,
          where: where || lien_release?.id,
          fields: LienReleaseFields,
        }
      );
      console.log({ lien_release_ });
      setLienRelease(lien_release_);
      setLoading(false);
    },
    [lien_release]
  );

  const updateLienRelease = async (record: any) => {
    const records = [record];
    console.log({ records });
    try {
      setUpdating(true);
      await axios.post(
        (REACT_APP_BACKEND_API || "") + "/api/quickbase/update",
        {
          records,
          table_id: REACT_APP_TABLE_LIEN_RELEASES,
        }
      );
      await getAndSetLienRelease();
      setView("release");
    } catch (err) {
      console.log("ERROR UPDATING LIEN RELEASE", err);
    } finally {
      setUpdating(false);
    }
  };

  const updateContactSignature = async () => {
    if (!user) return;
    const { name, record_id } = user;
    try {
      setUpdating(true);
      await axios.post(
        (REACT_APP_BACKEND_API || "") + "/api/quickbase/update",
        {
          records: [
            {
              record_id: record_id,
              signature: name,
              signature_date: new Date(),
            },
          ],
          table_id: REACT_APP_TABLE_CONTACTS,
        }
      );
      await getAndSetLienRelease();
      setView("release");
    } catch (err) {
      console.log("ERROR UPDATING CONTACT SIGNATURE", err);
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (!!record_id && !loading && lien_release?.id !== where) {
      getAndSetLienRelease(where);
    }
  }, [record_id, lien_release, loading, where, getAndSetLienRelease]);

  const release = lien_release?.data?.[0];
  if (!release) return <>Loading...</>;
  const views: { [key: string]: any } = {
    accept: (
      <AcceptSign
        updating={updating}
        updateContactSignature={updateContactSignature}
        setView={setView}
      />
    ),
    decline: <Decline setView={setView} />,
    issue: (
      <RaiseIssue
        lien_release={release}
        updating={updating}
        setView={setView}
        updateLienRelease={updateLienRelease}
      />
    ),
    release: (
      <Release
        lien_release={release}
        updating={updating}
        updateLienRelease={updateLienRelease}
        setView={setView}
      />
    ),
  };
  return views[view];
}
