import { FormattedMessage } from "react-intl";

export default function EditItemHeader({
  has_changes,
  loading,
  cancelChanges,
  updateLineItem,
}: {
  has_changes: boolean;
  loading: boolean;
  cancelChanges: any;
  updateLineItem: any;
}) {
  return (
    <div className="fixed top-0 w-full flex flex-row bg-white border-b-2 border-black items-center font-semibold px-4 py-2">
      <div className="grow">
        <button
          className="bg-ppi-blue text-white font-bold border-2 border-black rounded-md px-2 py-1"
          onClick={cancelChanges}
        >
          <FormattedMessage id="Cancel" />
        </button>
      </div>
      <div className="absolute left-0 text-center pointer-events-none w-full">
        <FormattedMessage id="View Line Item" />
      </div>
      <div>
        <button
          disabled={!has_changes}
          className={`${
            !has_changes || loading ? "bg-gray-400" : "bg-ppi-green"
          } text-white font-bold border-2 border-black rounded-md px-2 py-1`}
          onClick={updateLineItem}
        >
          <FormattedMessage id={loading ? "Saving..." : "Save"} />
        </button>
      </div>
    </div>
  );
}
