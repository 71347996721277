import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useUser } from "../UserContext";
import { REACT_APP_SC } from "../context/variables";

export default function HomeEmailForm() {
  const { setLogin } = useUser();
  const [email, setEmail] = useState<string>("");

  function validEmail(email: string) {
    const validEmailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!email?.match(validEmailRegex) && email;
  }
  const is_valid = validEmail(email);

  return (
    <div
      className={`flex flex-col text-slate-400 items-center justify-center gap-8 px-2 py-4 h-screen w-screen bg-blue-800`}
    >
      <div className="absolute top-24 text-white text-center text-3xl font-bold">
        {REACT_APP_SC ? "Field Operations" : "Piedmont Plumbers"}
      </div>
      <div className="w-[80%]">
        <input
          className="p-2 w-full border border-slate-300 text-black rounded-md shadow-[inset_0_2px_4px_0_rgba(0,0,0,0.5)]"
          onChange={e =>
            setEmail(e.target.value.replace(/\s/g, "").toLowerCase())
          }
          placeholder="Enter email address"
          value={email}
        />
      </div>
      <div className="h-12">
        <button
          className={`${
            is_valid ? "text-slate-300" : "text-slate-300/10"
          } font-bold uppercase`}
          disabled={!is_valid}
          onClick={() => setLogin(email)}
        >
          <FormattedMessage id="Continue" />
        </button>
      </div>
    </div>
  );
}
