import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { useLocation } from "wouter";
import { User } from "./context/types";
import {
  REACT_APP_DEVELOPMENT,
  REACT_APP_TABLE_CONTACTS,
  REACT_APP_BACKEND_API,
} from "./context/variables";

console.log("TESTING LIVE", !!REACT_APP_DEVELOPMENT);

interface UserValues {
  loading: boolean;
  login: string | null;
  pin: string;
  simulated_role: string | undefined;
  simulated_user: string | undefined;
  user: User | null;
  wrong_user: boolean;
  getAndSetUser: (query: any) => void;
  setLogin: (login: string | null) => void;
  setPin: any;
  setSimulatedRole: (role: string | undefined) => void;
  setSimulatedUser: (record_id: string | undefined) => void;
  setUser: (user: User | null) => void;
  setWrongUser: (setting: boolean) => void;
}

export const UserContext = createContext<UserValues>({
  loading: false,
  login: null,
  pin: "",
  simulated_role: undefined,
  simulated_user: undefined,
  user: null,
  wrong_user: false,
  getAndSetUser: () => {},
  setLogin: () => {},
  setPin: () => {},
  setSimulatedRole: () => {},
  setSimulatedUser: () => {},
  setUser: () => {},
  setWrongUser: () => {},
});

export const useUser = () => useContext(UserContext);

const UserContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  function getCachedLogin() {
    const cached_login = localStorage.getItem("login");
    if (
      typeof cached_login === "string" &&
      cached_login !== "undefined" &&
      cached_login !== "null"
    ) {
      return cached_login;
    }
    return null;
  }
  const [, setLocation] = useLocation();
  const [wrong_user, setWrongUser] = useState<boolean>(false);
  const [login, setLogin] = useState<string | null>(getCachedLogin());
  const [loading, setLoading] = useState<boolean>(false);
  const [pin, setPin] = useState<string>("");
  const [user, setUser] = useState<User | null>(null);
  const [simulated_role, setSimulatedRole] = useState<string | undefined>(
    undefined
  );
  const [simulated_user, setSimulatedUser] = useState<string | undefined>(
    undefined
  );

  const query = {
    pd: `{'13'.XE.'${login}'}AND{'70'.XE.'${pin}'}`,
    easley: `{'13'.XE.'${login}'}AND{'65'.XE.'${pin}'}`,
  }[REACT_APP_DEVELOPMENT ? "pd" : "easley"];

  const getAndSetUser = useCallback(
    async (where: string, redirect?: boolean) => {
      if (!!user) return;
      setLoading(true);
      const { data } = await axios.post(
        (REACT_APP_BACKEND_API || "") + "/api/quickbase",
        {
          table_id: REACT_APP_TABLE_CONTACTS,
          where,
        }
      );
      const query_user = data?.data?.[0];
      if (query_user) {
        localStorage.setItem(
          "saved_user",
          JSON.stringify({
            time: new Date(),
            where,
          })
        );
        setUser(query_user);
        redirect &&
          setLocation(
            query_user?.scheduling_role === "Crew" ? "/schedule" : "/"
          );
      } else {
        console.log("CLEARING PIN");
        setPin("");
        setWrongUser(true);
        localStorage.removeItem("saved_user");
      }
      setLoading(false);
    },
    [user, setLocation]
  );

  useEffect(() => {
    if (!!login) {
      localStorage.setItem("login", login);
    }
  }, [login]);

  useEffect(() => {
    if (!!loading || !!user || !login || pin.length !== 4) return;
    async function loginUser() {
      console.log("INPUT USER", query);
      await getAndSetUser(query as string, true);
    }
    loginUser();
  }, [loading, login, pin, query, user, getAndSetUser]);

  return (
    <UserContext.Provider
      value={{
        loading,
        login,
        pin,
        simulated_role,
        simulated_user,
        user,
        wrong_user,
        getAndSetUser,
        setPin,
        setLogin,
        setSimulatedRole,
        setSimulatedUser,
        setUser,
        setWrongUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
