import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { map, startsWith, uniq } from "lodash";
import {
  REACT_APP_TABLE_PHASE_TICKETS,
  REACT_APP_TABLE_PROJECTS,
  REACT_APP_BACKEND_API,
} from "../../context/variables";
interface DetailsValues {
  loading: boolean;
  phases: any[];
  project: any | null;
  tabs: any;
  setTabs: (tabs: any) => void;
}

export const DetailsContext = createContext<DetailsValues>({
  loading: false,
  phases: [],
  project: {},
  tabs: {},
  setTabs: () => {},
});

export const useDetails = () => useContext(DetailsContext);

function getQITabs(type: string) {
  const initial_tabs = window.localStorage.getItem(`details:${type}`);
  if (typeof initial_tabs === "string" && startsWith(initial_tabs, "{")) {
    return JSON.parse(initial_tabs);
  } else {
    return {};
  }
}

export default function DetailsContextProvider({
  children,
  job_id,
  type,
}: {
  children: any;
  job_id: string;
  type: string;
}) {
  const [project, setProject] = useState<any | null>(null);
  const [phase_tickets, setPhaseTickets] = useState<any | null>(null);
  const [tabs, setTabs] = useState<any>(getQITabs(type));
  const [loading, setLoading] = useState<boolean>(false);
  const [loading_phase_tickets, setLoadingPhaseTickets] =
    useState<boolean>(false);

  async function getAndSetProject(where: string) {
    setLoading(true);
    const { data } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      {
        table_id: REACT_APP_TABLE_PROJECTS,
        where,
      }
    );
    setProject(data);
    setLoading(false);
  }

  async function getAndSetPhaseTickets(where: string) {
    setLoadingPhaseTickets(true);
    const { data } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      {
        table_id: REACT_APP_TABLE_PHASE_TICKETS,
        where,
      }
    );
    setPhaseTickets(data);
    setLoadingPhaseTickets(false);
  }

  useEffect(() => {
    const query = `{6.EX.${job_id}}`;
    !loading &&
      !!job_id &&
      (!project || project?.id !== query) &&
      getAndSetProject(query);
  });

  useEffect(() => {
    const query = `{14.EX.${job_id}}`;
    !loading_phase_tickets &&
      !!job_id &&
      (!phase_tickets || phase_tickets?.id !== query) &&
      getAndSetPhaseTickets(query);
  });

  useEffect(() => {
    localStorage.setItem(`details:${type}`, JSON.stringify(tabs));
  }, [tabs, type]);

  const phases = uniq(map(phase_tickets?.data, "phase"));
  return (
    <DetailsContext.Provider
      value={{ loading, phases, project: project?.data?.[0], tabs, setTabs }}
    >
      {children}
    </DetailsContext.Provider>
  );
}
