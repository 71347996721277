import { FormattedMessage } from "react-intl";
import { useUser } from "../../../UserContext";
import { useQIJob } from "../QIJobContext";
import { REACT_APP_TABLE_PROJECT_LINE_ITEMS } from "../../../context/variables";

export default function RoomEditButtons({ setOpen }: { setOpen: any }) {
  const { user } = useUser();
  const {
    line_item,
    // loading_line_item,
    loading_update,
    new_line_item,
    setLineItem,
    updateQIItem,
  } = useQIJob();

  if (!line_item) return null;
  const { record_id, qi_status } = line_item;

  const base_changes = {
    date: new Date(),
    old_status: qi_status,
    //      related_schedule: schedule_id,
    related_contact: user?.record_id,
    related_project_line_item: record_id,
  };

  if (!new_line_item) return null;
  const not_ready = line_item.record_id !== new_line_item.record_id;
  async function setToFail() {
    const new_status = qi_status === "Fail" ? "Select Status" : "Fail";
    const records = [
      {
        record_id,
        qi_status: new_status,
      },
    ];
    const changes = [{ ...base_changes, new_status }];

    updateQIItem({
      changes,
      records,
      table_id: REACT_APP_TABLE_PROJECT_LINE_ITEMS,
    });
  }

  function ReadyButtons() {
    return (
      <>
        <button
          className={`${
            qi_status === "Fail" ? "bg-red-600" : "bg-gray-400"
          } text-white text-xl font-bold py-1 w-full rounded-md border-2 border-black`}
          disabled={!!not_ready}
          onClick={() => {
            false && setToFail();
            setOpen("edit");
            setLineItem({
              ...new_line_item,
              qi_status: "Fail",
            });
          }}
        >
          <FormattedMessage id={"Fail"} />
        </button>
        <button
          className={`${
            qi_status === "Pass"
              ? "bg-ppi-green text-white"
              : "bg-gray-400 text-white"
          }
      } text-xl font-bold py-1 w-full rounded-md border-2 border-black`}
          disabled={!!not_ready}
          onClick={async () => {
            const new_status = qi_status === "Pass" ? "Select Status" : "Pass";
            const records = [
              {
                record_id,
                qi_status: new_status,
              },
            ];
            const changes = [{ ...base_changes, new_status }];
            updateQIItem({
              changes,
              records,
              table_id: REACT_APP_TABLE_PROJECT_LINE_ITEMS,
            });
          }}
        >
          <FormattedMessage id={"Pass"} />
        </button>
      </>
    );
  }

  return (
    <div
      className={`absolute bottom-[72px] left-0 bg-white border-t-2 border-black px-4 w-full z-40 ${
        loading_update
          ? "opacity-10 animate-pulse pointer-events-none"
          : "opacity-100 pointer-events-auto"
      }`}
    >
      <div className="flex flex-row items-center gap-4 h-[72px]">
        <ReadyButtons />
      </div>
    </div>
  );
}
