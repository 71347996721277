import { FormattedMessage } from "react-intl";
import { useUI } from "../UIContext";
import { formatDate } from "../context/functions";
import { Notification } from "../context/types";

export default function NotificationItem({
  notification: data,
}: {
  notification: Notification;
}) {
  const { setLocation } = useUI();

  const {
    date,
    description,
    destination_record_id,
    notification,
    status,
    time,
  } = data;
  return (
    <div
      className="flex flex-col bg-white border-2 border-black rounded-md px-3 py-1"
      onClick={() => setLocation(`/lien-release/${destination_record_id}`)}
    >
      <div className="flex flex-row">
        <div className="font-bold grow">
          <FormattedMessage id={notification || "not provided"} />
        </div>
        <div>{formatDate(date)}</div>
      </div>

      <div className="flex flex-row">
        <div className="grow">
          <FormattedMessage id={status || "not provided"} />
        </div>
        <div className="uppercase">{time}</div>
      </div>

      <div className="flex flex-row">
        <div className="grow">{description}</div>
        <div></div>
      </div>
    </div>
  );
}
