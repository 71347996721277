export default function KPICardHolderSkeleton({ children }: { children: any }) {
  return (
    <div className="flex flex-col bg-white p-4 border-2 border-[#F4F5F8] rounded-md shadow-md">
      <div className="pb-[18px] mb-4 text-center text-xl font-bold">
        <div className="m-auto h-5 w-[180px] bg-[#F4F5F8] rounded-md"></div>
      </div>
      <div>{children}</div>
    </div>
  );
}
