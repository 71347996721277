import moment from "moment";
export default function ChangeDate({ date }: { date: string }) {
  function formatTimestamp(timestamp: string) {
    const now = moment();
    const date = moment(timestamp);

    if (now.diff(date, "days") === 0) {
      return date.format("h:mmA");
    } else if (now.diff(date, "days") === 1) {
      return "Yesterday, " + date.format("h:mmA");
    } else if (now.diff(date, "weeks") === 0) {
      return date.format("dddd, h:mmA");
    } else if (now.diff(date, "years") === 0) {
      return date.format("MMM D, h:mmA");
    } else {
      return date.format("MMM D, YYYY, h:mmA");
    }
  }
  return <div>{formatTimestamp(date)}</div>;
}
