import { FormattedMessage } from "react-intl";
import { getAddressLink } from "../../context/functions";

export default function JobInformation({ job }: { job: any }) {
  const {
    address,
    builder,
    job_id,
    lot,
    note_general,
    project_address,
    subdivision,
  } = job;

  return (
    <div className="flex flex-col gap-4">
      <div>
        <table className="ppi-table w-full">
          <tbody>
            <tr>
              <td className="w-1/3">
                <FormattedMessage id="Job ID" />
              </td>
              <td>{job_id}</td>
            </tr>
            <tr>
              <td className="w-1/3">
                <FormattedMessage id="Subdivision" />
              </td>
              <td>{subdivision}</td>
            </tr>
            <tr>
              <td className="w-1/3">
                <FormattedMessage id="Lot" />
              </td>
              <td>{lot}</td>
            </tr>
            <tr>
              <td className="w-1/3">
                <FormattedMessage id="Builder" />
              </td>
              <td>{builder}</td>
            </tr>
            <tr>
              <td className="w-1/3">
                <FormattedMessage id="Address" />
              </td>
              <td>
                <a
                  href={getAddressLink(project_address || address)}
                  className="text-blue-600 underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {project_address || address}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {false && (
        <div>
          <table className="w-full">
            <tbody>
              <tr>
                <th className="border-b border-ppi-border text-left">
                  <FormattedMessage id="Phase" />
                </th>
                <th className="border-b border-ppi-border px-2">
                  <FormattedMessage id="Install" />
                </th>
                <th className="border-b border-ppi-border">
                  <FormattedMessage id="QI" />
                </th>
              </tr>
              {["Water & Sewer", "Slab", "Rough", "Trim"].map(
                (phase: string) => {
                  return (
                    <tr key={phase}>
                      <td className="py-2">
                        <FormattedMessage id={phase} />
                      </td>
                      <td className="px-2 py-2"></td>
                      <td className="py-2"></td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      )}
      {false && note_general && (
        <div className="border-t border-ppi-border mt-2 pt-1">
          {note_general}
        </div>
      )}
    </div>
  );
}
