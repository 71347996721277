import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as DetailsIcon } from "../../../assets/icons/Details.svg";
import { ReactComponent as DetailsGreenIcon } from "../../../assets/icons/DetailsGreen.svg";
import AppLayout from "../../../Layout";
import QIJobLocations from "./locations";
import { useUI } from "../../../UIContext";
import { useQIJob } from "../QIJobContext";
import DetailsDrawer from "../../../components/JobDetails";
import Header from "./header";
import HeaderButtons from "../../../components/header_buttons";
import ProgressBar from "./progress";
import RecordActivity from "./activity";
import Billable from "./billable";
import QIRecordSkeleton from "./skeleton";
import HeaderTitle from "../../../components/header_title";

export default function RecordPage() {
  const { setLocation } = useUI();
  const [open, setOpen] = useState<boolean>(false);
  const { job_id, loading, loading_line_items, project } = useQIJob();

  function FooterButton() {
    return (
      <div
        className="flex flex-col items-center text-center cursor-pointer"
        onClick={() => {
          const flip = !open;
          setOpen(flip);
        }}
      >
        <div>
          {open ? (
            <DetailsGreenIcon className="h-[21px]" />
          ) : (
            <DetailsIcon className="h-[21px]" />
          )}
        </div>
        <div
          className={`text-xs2 leading-xs2 ${
            open && "text-ppi-green"
          } font-medium`}
        >
          <FormattedMessage id="Details" />
        </div>
      </div>
    );
  }

  function LineItems() {
    return (
      <>
        <ProgressBar />
        <QIJobLocations />
      </>
    );
  }

  return (
    <AppLayout
      details={
        <div
          className="fixed bg-[#F4F5F8] flex-1 h-full w-full z-50 pt-[72px] pb-[88px] overflow-y-auto pointer-events-auto"
          style={{
            transform: open
              ? "translate3d(0vw, 0, 0)"
              : "translate3d(100vw, 0, 0)",
            transition: "transform .3s cubic-bezier(0, .52, 0, 1)",
          }}
        >
          <DetailsDrawer job_id={job_id} setOpen={setOpen} type="qi" />
        </div>
      }
      footer={<FooterButton />}
      header={
        <>
          <HeaderTitle title={project?.mobile_app_header} />
          <div className="flex flex-row items-center justify-items-end px-1  absolute w-full">
            <div className="grow">
              <button
                onClick={() => setLocation(`/qi`)}
                className="border-2 border-black text-white bg-ppi-blue font-bold px-2 py-1 rounded-md"
              >
                <FormattedMessage id="Back" />
              </button>{" "}
            </div>
            <HeaderButtons />
          </div>
        </>
      }
    >
      <div className="flex flex-col gap-4 px-4 overflow-y-touch pb-[72px]">
        {loading || loading_line_items ? (
          <QIRecordSkeleton />
        ) : (
          <>
            <Header />
            <LineItems />
            <Billable />
            <RecordActivity />
          </>
        )}{" "}
      </div>
    </AppLayout>
  );
}
