import { dropRight, includes, join } from "lodash";
import { FormattedMessage } from "react-intl";
import PreLoader from "../PreLoader";
import { useUser } from "../UserContext";
import { REACT_APP_SC } from "../context/variables";

export default function HomePinPad() {
  const { loading, login, pin, wrong_user, setLogin, setPin, setWrongUser } =
    useUser();

  function PinPad() {
    return (
      <div className={`grid grid-cols-3 justify-content-center gap-6`}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, "Clear", 0, "Delete"].map(
          (btn: number | string, idx: number) => {
            if (includes([9, 11], idx)) {
              return (
                <div key={btn}>
                  <button
                    className="flex flex-col text-xs items-center justify-center h-16 w-16"
                    onClick={() => {
                      if (wrong_user) {
                        setWrongUser(false);
                      }
                      if (idx === 9) {
                        setPin("");
                      } else {
                        setPin(join(dropRight(pin), ""));
                      }
                    }}
                  >
                    <div
                      className={
                        !REACT_APP_SC ? `text-slate-200` : "text-slate-500"
                      }
                    >
                      {idx === 9 ? (
                        <FormattedMessage id="Clear" />
                      ) : (
                        <FormattedMessage id="Delete" />
                      )}
                    </div>
                  </button>
                </div>
              );
            }
            return (
              <div key={btn}>
                <button
                  className={`flex flex-col ${
                    !REACT_APP_SC
                      ? "bg-blue-800 border-slate-200"
                      : "bg-none border-slate-400"
                  } border mx-auto items-center justify-center text-2xl h-16 w-16 rounded-full shadow-[0_4px_6px_-1px_rgba(0,0,0,0.25)]`}
                  onClick={() => {
                    if (wrong_user) {
                      setWrongUser(false);
                    }
                    setPin(pin + btn);
                  }}
                >
                  <div
                    className={
                      !REACT_APP_SC ? `text-slate-200` : "text-slate-500"
                    }
                  >
                    {btn}
                  </div>
                </button>
              </div>
            );
          }
        )}
      </div>
    );
  }

  return (
    <div
      className={
        !REACT_APP_SC
          ? `flex flex-col text-slate-400 items-center justify-center gap-12 px-2 py-4 h-screen w-screen bg-blue-800 text-slate-200`
          : `flex flex-col text-slate-400 items-center justify-center gap-12 px-2 py-4 h-screen w-screen text-slate-500`
      }
    >
      <div
        className={`text-sm font-semibold cursor-pointer`}
        onClick={() => {
          setLogin(null);
          localStorage.removeItem("login");
        }}
      >
        {login}
      </div>
      <div className={`flex flex-col justify-center h-10 font-semibold`}>
        {wrong_user ? (
          <div className="text-yellow-300">Wrong Email or Pin Entered</div>
        ) : (
          <div className={`text-4xl`}>{pin}</div>
        )}
      </div>
      <div className="flex flex-col items-center justify-center h-[330px]">
        {loading ? (
          <PreLoader color="#94a3b8" size={20} text="Signing in..." />
        ) : (
          <PinPad />
        )}
      </div>
    </div>
  );
}
