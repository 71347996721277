import JobsByStatusKPI from "./jobs_by_status";
import TopSubdivisionsKPI from "./top_subdivisions";
import ActiveJobsKPI from "./active_jobs";

export default function HomeKPI() {
  return (
    <>
      <JobsByStatusKPI />
      <TopSubdivisionsKPI />
      <ActiveJobsKPI />
    </>
  );
}
