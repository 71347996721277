import moment from "moment";
import { orderBy } from "lodash";
import ChangeDate from "./change_date";
import StatusChange from "./status_change";
import { ChangeLog } from "../../../context/types";
export default function LineItemChangeLog({ logs }: { logs?: ChangeLog[] }) {
  if (!logs) return null;
  return (
    <div className="flex flex-col gap-2">
      {orderBy(logs, (log: ChangeLog) => moment(log.date), ["desc"]).map(
        (log: any) => {
          const { contact, date, old_status, new_status, notes, record_id } =
            log;
          return (
            <div
              key={record_id}
              className="flex flex-col border-2 border-black bg-white px-2 py-1 rounded-lg text-gray-600 text-xs2"
            >
              <div className="flex flex-row items-center">
                <StatusChange status={{ old_status, new_status }} />
                <ChangeDate date={date} />
              </div>
              <div className="flex flex-row items-start">
                <div className="shrink">{notes}</div>
                <div className="flex-grow text-right whitespace-nowrap">
                  {contact}
                </div>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
}
