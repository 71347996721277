import { FormattedMessage } from "react-intl";
import { ClimbingBoxLoader } from "react-spinners";
export default function PreLoader({
  color = "#9ca3af",
  height = 330,
  size = 14,
  text = "Loading...",
  values,
}: {
  color?: string;
  height?: number;
  size?: number;
  text?: string;
  values?: any;
}) {
  return (
    <div
      className={`flex flex-col items-center justify-center h-[${height}px]`}
    >
      <ClimbingBoxLoader color={color} size={size} />
      <div className="text-gray-400 text-center text-xs">
        <FormattedMessage id={text || "Loading..."} values={values} />
      </div>
    </div>
  );
}
