import { random } from "lodash";
export default function QIRecordSkeleton() {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <div className="skeleton h-6 w-12"></div>
        <div className="skeleton h-[40px] w-28"></div>
      </div>
      <div className="skeleton w-full h-8"></div>
      <div className="flex flex-col gap-3">
        {[1, 2, 3, 4, 5].map((idx: number) => {
          return (
            <div
              className="skeleton w-full h-[44px] card-skeleton"
              key={idx}
              style={{
                animation: `skeleton-loading linear ${random(1.5, 2.75)}s`,
                // opacity: loading ? 1 : 0,
              }}
            ></div>
          );
        })}
      </div>
      <div className="flex flex-col items-end">
        <div className="skeleton h-[36px] w-20"></div>
      </div>
      <div className="skeleton h-[140px] w-full"></div>
    </div>
  );
}
