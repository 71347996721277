import { ReactComponent as CalendarIcon } from "../assets/icons/CalendarGreen.svg";
import { ReactComponent as ChecklistIcon } from "../assets/icons/Checklist.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/Search.svg";
import { FormattedMessage } from "react-intl";
import { useUI } from "../UIContext";
// Change for upate
export default function HomeMenu() {
  const { setLocation } = useUI();

  const home_menu = [
    { icon: SearchIcon, label: "Lookup", route: "/lookup" },
    { icon: ChecklistIcon, label: "QI Queue", route: "/qi" },
    { icon: CalendarIcon, label: "My Schedule", route: "/schedule" },
  ];

  function MenuButton({ btn, long }: { btn: any; long?: boolean }) {
    const { icon: ItemIcon, label, route } = btn;
    return (
      <div>
        <button
          className="bg-white border-2 border-black text-slate-700 font-semibold shadow-md rounded-lg w-full"
          style={{
            padding: long ? "10px" : "16px 24px",
          }}
          onClick={() => setLocation(route)}
        >
          <div
            className={`flex ${
              long ? "flex-row gap-2" : "flex-col gap-1"
            } items-center justify-center w-full`}
          >
            <div style={{ width: long ? 24 : 36 }}>
              <ItemIcon
                width={long ? "24" : "36"}
                height={long ? "24" : "36"}
              />
            </div>
            <FormattedMessage id={label} />
          </div>
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="grid grid-cols-2 gap-6 w-full">
        {home_menu.map((btn: any) => {
          return <MenuButton key={btn.route} btn={btn} />;
        })}
      </div>
    </div>
  );
}
