import { useState } from "react";
import LayoutFooter from "./footer";
import LayoutHeader from "./header";
import QuickAction from "./quick_action";

export default function AppLayout({
  children,
  details,
  edit,
  footer_edit,
  footer,
  header,
  header_bottom,
}: {
  children: any;
  details?: any;
  edit?: any;
  footer_edit?: any;
  footer?: any;
  header?: any;
  header_bottom?: any;
}) {
  const [quick_action, setQuickAction] = useState<boolean>(false);

  return (
    <>
      <LayoutHeader header={header} header_bottom={header_bottom} />
      <div className="fixed top-0 w-full h-screen py-[72px] flex-1 overflow-y-auto bg-[#F4F5F8]">
        <div className="fixed top-0 w-full overflow-hidden h-screen z-40 pointer-events-none">
          {details}
          {edit}
          {
            <div
              className="absolute bg-[#F4F5F8] flex-1 w-full z-50 pointer-events-auto"
              style={{
                height: "calc(100% - 144px)",
                transform: quick_action
                  ? "translate3d(0, 72px, 0)"
                  : "translate3d(0, 100vh, 0)",
                transition: "transform .3s cubic-bezier(0, .52, 0, 1)",
              }}
            >
              <QuickAction setOpen={setQuickAction} />
            </div>
          }
        </div>
        <div className="min-h-full w-full pt-4 pb-[72px] overflow-y-touch">
          {children}
        </div>
      </div>
      <LayoutFooter
        footer={footer}
        edit={footer_edit}
        admin={{
          quick_action,
          setQuickAction,
        }}
      />
    </>
  );
  // return (
  //   <div className="sticky top-0 flex flex-col bg-[#F4F5F8] h-screen">
  //     <LayoutHeader header={header} />
  //     {details}
  //     <div className="py-4 grow">{children}</div>
  //     {edit}
  //     <LayoutFooter footer={footer} />
  //   </div>
  // );
}
