import LookupItem from "./Lookup";
import QIItem from "./QI";
import ScheduleItem from "./Schedule";

export default function ResultsItem({
  item,
  type,
}: {
  item: any;
  type?: string;
}) {
  const result_item: { [key: string]: any } = {
    lookup: <LookupItem project={item} />,
    qi: <QIItem phase_ticket={item} />,
    schedule: <ScheduleItem job={item} />,
  };

  return result_item[type || "lookup"];
}
