import { Route, Router, Switch } from "wouter";
import makeCachedMatcher from "wouter/matcher";
import { pathToRegexp } from "path-to-regexp";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";
import Home from "./Home";
import Trucks from "./Trucks";
import JobLookupResults from "./Lookup";
import NotFoundPage from "./NotFoundPage";
import Schedule from "./Schedule";
import TrucksContextProvider from "./Trucks/TrucksContext";
import QIQueue from "./QIQueue";
import QIJob from "./QIQueue/QIJob";
import QIJobContextProvider from "./QIQueue/QIJob/QIJobContext";
import Settings from "./Settings";
import SignIn from "./SignIn";
import { useUser } from "./UserContext";
import NotAuthorizedPage from "./NotAuthorizedPage";
import JobContextProvider from "./Job/JobContext";
import Job from "./Job";
import JobRecord from "./Job/Record";
import JobRoom from "./Job/Room";
import QIContextProvider from "./QIQueue/QIContext";
import Can from "./components/Can";
import QIJobRecord from "./QIQueue/QIJob/Record";
import QIJobRoom from "./QIQueue/QIJob/Room";
import LookupContextProvider from "./Lookup/LookupContext";
import ProjectContextProvider from "./Project/ProjectContext";
import Project from "./Project";
import ScheduleContextProvider from "./Schedule/ScheduleContext";
import NotificationsContextProvider from "./NotificationsContext";
import Notifications from "./Notifications";
import LienRelease from "./LienRelease";
const convertPathToRegexp = (path: string) => {
  let keys: any[] = [];
  const regexp = pathToRegexp(path, keys);
  return { keys, regexp };
};

const customMatcher = makeCachedMatcher(convertPathToRegexp);

export default function App() {
  const { user } = useUser();
  function TruckScheduleChild({
    children,
    truck_no,
  }: {
    children: any;
    truck_no?: string;
  }) {
    return (
      <Can
        perform="view:trucks"
        yes={() => (
          <TrucksContextProvider truck_no={truck_no}>
            {children}
          </TrucksContextProvider>
        )}
        no={() => <NotAuthorizedPage />}
      />
    );
  }

  function JobChild({
    children,
    job_id,
    phase,
    record_id,
    room,
  }: {
    children: any;
    job_id: string;
    phase: string;
    record_id?: string;
    room?: string;
  }) {
    return (
      <JobContextProvider
        job_id={job_id}
        phase={phase}
        record_id={record_id}
        room={room}
      >
        {children}
      </JobContextProvider>
    );
  }

  function ScheduleChild({ children }: { children: any }) {
    //  throw new Error("This is a test error");
    return <ScheduleContextProvider>{children}</ScheduleContextProvider>;
  }

  function LookupProviderChild({ children }: { children: any }) {
    return (
      <Can
        perform="view:lookup"
        yes={() => <LookupContextProvider>{children}</LookupContextProvider>}
        no={() => <NotAuthorizedPage />}
      />
    );
  }

  // function QIProviderChild({ children }: { children: any }) {
  //   return (
  //     <Can
  //       perform="view:qi"
  //       yes={() => <QIContextProvider>{children}</QIContextProvider>}
  //       no={() => <NotAuthorizedPage />}
  //     />
  //   );
  // }
  function QIJobProviderChild({
    children,
    job_id,
    phase,
    room,
  }: {
    children: any;
    job_id: string;
    phase?: string;
    room?: string;
  }) {
    return (
      <QIJobContextProvider job_id={job_id} phase={phase} room={room}>
        {children}
      </QIJobContextProvider>
    );
  }

  const errorBoundaryConfig = {
    FallbackComponent: ErrorFallback,
    onReset: () => {
      // Reset any state or perform necessary cleanup
    },
    onError: (error: any, info: any) => {
      // Log the error or perform additional error handling steps
      console.error(error, info);
    },
  };

  if (!user) return <SignIn />;
  return (
    <ErrorBoundary {...errorBoundaryConfig}>
      <NotificationsContextProvider>
        <QIContextProvider>
          <ScheduleChild>
            <Router matcher={customMatcher}>
              <Switch>
                <Route path="/">
                  <Home />
                </Route>
                <Route path="/qi/:job_id/:phase/:room">
                  {({ job_id, phase, room }) => (
                    <QIJobProviderChild
                      job_id={job_id}
                      phase={phase}
                      room={room}
                    >
                      <QIJobRoom />
                    </QIJobProviderChild>
                  )}
                </Route>
                <Route path="/qi/:job_id/:phase">
                  {({ job_id, phase }) => (
                    <QIJobContextProvider job_id={job_id} phase={phase}>
                      <QIJobRecord />
                    </QIJobContextProvider>
                  )}
                </Route>
                <Route path="/qi/:job_id">
                  {({ job_id }) => (
                    <QIJobContextProvider job_id={job_id}>
                      <QIJob />
                    </QIJobContextProvider>
                  )}
                </Route>
                <Route path="/qi">
                  <QIQueue />
                </Route>
                <Route path="/project/:job_id">
                  {({ job_id }) => (
                    <ProjectContextProvider job_id={job_id}>
                      <Project />
                    </ProjectContextProvider>
                  )}
                </Route>
                <Route path="/schedule">
                  <ScheduleChild>
                    <Schedule />
                  </ScheduleChild>
                </Route>
                <Route path="/lookup">
                  <LookupProviderChild>
                    <JobLookupResults />
                  </LookupProviderChild>
                </Route>
                <Route path="/job/:job_id/:phase/:record_id/:room">
                  {({ job_id, phase, record_id, room }) => (
                    <ScheduleChild>
                      <JobChild
                        job_id={job_id}
                        phase={phase}
                        record_id={record_id}
                        room={room}
                      >
                        <JobRoom />
                      </JobChild>
                    </ScheduleChild>
                  )}
                </Route>
                <Route path="/job/:job_id/:phase/:record_id">
                  {({ job_id, phase, record_id }) => (
                    <ScheduleChild>
                      <JobChild
                        job_id={job_id}
                        phase={phase}
                        record_id={record_id}
                      >
                        <JobRecord />
                      </JobChild>
                    </ScheduleChild>
                  )}
                </Route>
                <Route path="/job/:job_id/:phase">
                  {({ job_id, phase }) => (
                    <ScheduleChild>
                      <JobChild job_id={job_id} phase={phase}>
                        <Job />
                      </JobChild>
                    </ScheduleChild>
                  )}
                </Route>
                <Route path="/lien-release/:record_id">
                  {({ record_id }) => <LienRelease record_id={record_id} />}
                </Route>
                <Route path="/trucks">
                  <TruckScheduleChild>
                    <Trucks />
                  </TruckScheduleChild>
                </Route>
                <Route path="/settings">
                  <Settings />
                </Route>
                <Route path="/notifications">
                  <Notifications />
                </Route>
                <Route>
                  <NotFoundPage />
                </Route>
              </Switch>
            </Router>
          </ScheduleChild>
        </QIContextProvider>
      </NotificationsContextProvider>
    </ErrorBoundary>
  );
}
