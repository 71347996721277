import { FormattedMessage } from "react-intl";

export default function KPICardHolder({
  children,
  title,
}: {
  children: any;
  title: string;
}) {
  return (
    <div className="flex flex-col bg-white p-4 border-2 border-black rounded-md shadow-md">
      <div className="pb-4 mb-4 border-b-2 border-black text-center text-xl font-bold">
        <FormattedMessage id={title} />
      </div>
      <div>{children}</div>
    </div>
  );
}
