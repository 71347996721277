import moment from "moment";
import { FormattedMessage } from "react-intl";
import { isEmpty, orderBy } from "lodash";
import { useQIJob } from "../QIJobContext";
import { formatTimestamp } from "../../../context/functions";
import { Activity } from "../../../context/types";

export default function RecordActivity() {
  const { activities } = useQIJob();
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center text-xl">
        <div className="font-bold grow">
          <FormattedMessage id="Activity" />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {isEmpty(activities) ? (
          <div className="my-2 text-center font-bold text-gray-400/30 italic">
            <FormattedMessage id="No activities for phase" />
          </div>
        ) : (
          <>
            {orderBy(
              activities,
              (a: Activity) => {
                return moment(a.date_created);
              },
              ["desc"]
            ).map((activity: Activity) => {
              const { amount, billable, contact, date_created, note } =
                activity;
              return (
                <div
                  key={date_created}
                  className="flex flex-col border-2 border-black bg-white rounded-md text-xs2 text-ppi-light-text px-4 py-2"
                >
                  <div className="flex flex-row">
                    <div className="font-bold text-black grow">
                      <FormattedMessage
                        id={billable ? "Billable" : "Not Billable"}
                      />
                    </div>
                    <div>{formatTimestamp(date_created)}</div>
                  </div>
                  <div className="flex flex-row">
                    <div className="grow">{billable ? `$${amount}` : ""}</div>
                    <div>{contact}</div>
                  </div>
                  <div className="border-t-2 border-black py-2 mt-2">
                    {note}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
