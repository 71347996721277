import { FormattedMessage } from "react-intl";
import AppLayout from "./Layout";
import { useUI } from "./UIContext";

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: any;
}) {
  const { setLocation } = useUI();
  return (
    <AppLayout
      header={
        <div className="font-bold text-center">
          <FormattedMessage id="An Error Occured" />
        </div>
      }
    >
      <div className="min-h-[350px] flex flex-col items-center justify-center gap-6">
        <div className="text-center">
          <div className="font-bold text-5xl">Oops!</div>
          <div className="font-semibold">
            <FormattedMessage id="Something went wrong." />
          </div>
        </div>

        <div>
          <p>{error.message}</p>
        </div>

        <div className="flex flex-row gap-4">
          <div>
            <button
              className="bg-ppi-blue text-white font-bold border-2 border-black px-2 rounded-md"
              onClick={resetErrorBoundary}
            >
              <FormattedMessage id="Try Again" />
            </button>
          </div>
          <div>
            <button
              className="bg-ppi-blue text-white font-bold border-2 border-black px-2 rounded-md"
              onClick={() => setLocation("/")}
            >
              <FormattedMessage id="Return Home" />
            </button>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
