import { FormattedMessage } from "react-intl";
import BackArrowIcon from "../../assets/icons/BackArrow.svg";
import BackArrowWhiteIcon from "../../assets/icons/BackArrowWhite.svg";
import { sortBy } from "lodash";
export default function SelectComponent({
  id,
  items,
  label,
  options = [],
  value,
  handleChange,
}: {
  id: string;
  items: any[];
  label: string;
  options?: any[];
  value: any;
  handleChange: any;
}) {
  return (
    <div key={id} className="flex flex-col gap-1">
      <div>
        <select
          className={`${
            value ? "bg-ppi-blue text-white" : "bg-white text-black"
          } font-bold text-xs2 h-10 px-4 w-full border-2 border-black rounded-md`}
          id={label}
          value={value || ""}
          onChange={(e: any) => {
            handleChange(id, e.target.value);
          }}
          style={{
            appearance: "none",
            backgroundImage: value
              ? `url(${BackArrowWhiteIcon})`
              : `url(${BackArrowIcon})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right 10px center",
            WebkitAppearance: "none",
            MozAppearance: "none",
          }}
        >
          <option value="">
            <FormattedMessage id={label} /> <>{false && `(${items.length})}`}</>
          </option>
          {sortBy(options).map((item: any, idx: number) => {
            const key_value = item || `undefined ${idx}`;
            return (
              <option key={key_value} value={key_value}>
                <FormattedMessage id={item || "undefined"} />
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
