import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import moment, { Moment } from "moment";
import { useUser } from "../UserContext";
import { filter, groupBy } from "lodash";
import {
  Schedule,
  ScheduleData,
  ScheduleFields as fields,
} from "../context/types";
import {
  REACT_APP_TABLE_SCHEDULES,
  REACT_APP_BACKEND_API,
} from "../context/variables";

const initial_day_object = moment().startOf("day");

interface ScheduleValues {
  day_object: Moment;
  loading: boolean;
  open_calendar: boolean;
  schedule: Schedule[] | null;
  schedule_by_date: Schedule[];
  schedule_by_job_id: { [key: string]: Schedule[] };
  getAndSetSchedule: (where: string) => void;
  setDayObject: (day_object: Moment) => void;
  setOpenCalendar: (setting: boolean) => void;
  setSchedule: (schedule: ScheduleData) => void;
}

export const ScheduleContext = createContext<ScheduleValues>({
  day_object: initial_day_object,
  loading: false,
  open_calendar: false,
  schedule: null,
  schedule_by_date: [],
  schedule_by_job_id: {},
  getAndSetSchedule: () => {},
  setDayObject: () => {},
  setOpenCalendar: () => {},
  setSchedule: () => {},
});

export const useSchedule = () => useContext(ScheduleContext);

export default function ScheduleContextProvider({
  children,
}: {
  children: any;
}) {
  const { user } = useUser();

  const [schedule, setSchedule] = useState<ScheduleData | null>(null);
  const where = user ? `{166.HAS.${user.pin}}AND{301.XEX.'true'}` : "";

  const [day_object, setDayObject] = useState<any>(initial_day_object);
  const [open_calendar, setOpenCalendar] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  async function getAndSetSchedule(where: string) {
    try {
      setLoading(true);
      const { data: scheduled_crew } = await axios.post(
        (REACT_APP_BACKEND_API || "") + "/api/quickbase",
        {
          table_id: REACT_APP_TABLE_SCHEDULES,
          where,
          fields,
        }
      );
      setSchedule(scheduled_crew);
    } catch (err: any) {
      console.log("ERROR LOADING SCHEDULE", err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!!(!user || schedule?.data || loading)) return;
    getAndSetSchedule(where);
  });

  const schedule_by_date = filter(schedule?.data, function (i: Schedule) {
    const { schedule_date } = i;
    const item_start = moment(schedule_date);
    return !!day_object.isSame(item_start, "day");
  });

  const schedule_by_job_id = groupBy(schedule?.data, "job_id");

  return (
    <ScheduleContext.Provider
      value={{
        day_object,
        loading,
        open_calendar,
        schedule: schedule?.data || null,
        schedule_by_date,
        schedule_by_job_id,
        getAndSetSchedule,
        setDayObject,
        setOpenCalendar,
        setSchedule,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
}
