import AppLayout from "../Layout";
import { FormattedMessage } from "react-intl";
import { useUser } from "../UserContext";
import { useUI } from "../UIContext";
import HeaderButtons from "../components/header_buttons";
import HomeMenu from "./menu";
import HomeKPI from "./KPI";

export default function Home() {
  const { setLocation } = useUI();
  const { user } = useUser();

  if (user?.scheduling_role === "Crew") {
    setLocation("/schedule");
    return null;
  }

  return (
    <AppLayout
      header={
        <>
          <div className="font-bold text-xl text-center">
            <FormattedMessage id="Home" />
          </div>
          <HeaderButtons />
        </>
      }
    >
      <div className="flex flex-col gap-6 justify-center pb-[72px] px-6 h-full overflow-y-touch">
        <HomeMenu />
        <HomeKPI />
      </div>
    </AppLayout>
  );
}
