import { ReactComponent as BackArrowIcon } from "../../assets/icons/BackArrow.svg";
import { ReactComponent as DownArrowIcon } from "../../assets/icons/DownArrow.svg";
import { useDetails } from "./DetailsContext";

export default function DetailsItem({
  children,
  tab,
}: {
  children: any;
  tab: any;
}) {
  const { tabs, setTabs } = useDetails();
  const { id, label } = tab;
  const is_open = tabs[id];
  return (
    <div className="flex flex-col bg-white px-4 py-2 border-2 border-black rounded-lg">
      <div className="flex flex-row items-center">
        <div className="font-bold text-sm grow">{label}</div>
        <button
          onClick={() => {
            const flip = !is_open;
            setTabs({ ...tabs, [id]: flip });
          }}
        >
          {is_open ? <DownArrowIcon /> : <BackArrowIcon />}
        </button>
      </div>
      {is_open && (
        <div className="text-xs2 mt-2 border-t-2 border-black py-3">
          {children}
        </div>
      )}
    </div>
  );
}
