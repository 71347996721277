import { FormattedMessage } from "react-intl";
import AppLayout from "../Layout";
import JobDetails from "../components/JobDetails";
import { useUI } from "../UIContext";
import { useProject } from "./ProjectContext";
import HeaderButtons from "../components/header_buttons";

export default function Project() {
  const { setLocation } = useUI();
  const { job_id, project } = useProject();

  return (
    <AppLayout
      header={
        <>
          <div className="font-bold text-xl text-center">
            <FormattedMessage id={project?.mobile_app_header || "loading..."} />
          </div>
          <div className="flex flex-row items-center justify-items-end px-1 translate-y-[2px] absolute w-full">
            <div className="grow">
              <button
                className="border-2 border-black text-white bg-ppi-blue font-bold px-2 py-1 rounded-md"
                onClick={() => setLocation("/lookup")}
              >
                <FormattedMessage id="Back" />
              </button>
            </div>
            <HeaderButtons />
          </div>
        </>
      }
    >
      <JobDetails
        job_id={job_id}
        setOpen={() => console.log("Doesn't Open")}
        type="project"
      />
    </AppLayout>
  );
}
