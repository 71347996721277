import { useEffect } from "react";
import { split } from "lodash";
import PreLoader from "../PreLoader";
import AppLayout from "../Layout";
import { useUI } from "../UIContext";
import { FormattedMessage } from "react-intl";
import { useTrucks } from "./TrucksContext";

export default function Trucks() {
  const { location, setLocation } = useUI();
  const { loading, trucks } = useTrucks();
  const hasOneTruck = trucks?.length === 1;
  console.log("TRUCKS", trucks);
  useEffect(() => {
    if (hasOneTruck && location === "/truck") {
      const truck_no = split(trucks?.[0], "#")?.[1] || "---";
      setLocation(`/truck/${truck_no}`);
    }
  });
  return (
    <AppLayout
      header={
        <div className="font-bold text-2xl">
          <FormattedMessage id="Trucks" />
        </div>
      }
    >
      {loading ? (
        <PreLoader
          color="#9ca3af"
          height={330}
          size={14}
          text="Loading trucks..."
        />
      ) : (
        <div className="flex flex-col gap-3"></div>
      )}
    </AppLayout>
  );
}
