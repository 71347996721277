import { groupBy, map, sum, take } from "lodash";
import { useSchedule } from "./ScheduleContext";

export default function ScheduleProgress() {
  const { schedule_by_date } = useSchedule();
  const total = schedule_by_date?.length;

  const schedule_breakdown = groupBy(schedule_by_date, "filter_submitted");
  if (!total) return null;

  const true_count = schedule_breakdown.true?.length || 0;
  const false_count = schedule_breakdown.false?.length || 0;
  // const both_count = true_count + false_count;
  const item_array = [
    {
      backgroundColor: "#2CA01C",
      width: true_count ? true_count / total : 0,
    },
    {
      backgroundColor: "#FF0000",
      width: false_count ? false_count / total : 0,
    },
    // {
    //   backgroundColor: "#FFD600",
    //   width: both_count - 1,
    // },
  ];

  function getStyle(idx: number, style: any) {
    const { backgroundColor } = style;
    switch (true) {
      case idx === 0:
        return {
          backgroundColor,
          width: `${sum(map(take(item_array), "width")) * 100}%`,
          zIndex: 2,
        };
      case idx === 1:
        return {
          backgroundColor,
          width: `${sum(map(take(item_array, 2), "width")) * 100}%`,
          zIndex: 1,
        };
      case idx === 2:
        return {
          backgroundColor,
          width: `${sum(map(item_array, "width")) * 100}%`,
          zIndex: 0,
        };
    }
  }

  function ProgressBar({ idx, style }: { idx: number; style?: any }) {
    if (!style.width) return null;
    return (
      <div
        className={`absolute border-2 border-black rounded-md h-8`}
        style={getStyle(idx, style)}
      />
    );
  }

  return (
    <div className="relative flex flex-row items-center h-8 outline-2 outline-offset-0 outline-black rounded-md">
      {item_array.map((style: any, idx: number) => {
        return <ProgressBar key={idx} idx={idx} style={style} />;
      })}
    </div>
  );
}
