import { kebabCase, round } from "lodash";
import { FormattedMessage } from "react-intl";
import ResultCard from "../card";
import { PhaseTicket } from "../../../context/types";

export default function QIItem({
  phase_ticket,
}: {
  phase_ticket: PhaseTicket;
}) {
  const {
    complete_installation,
    job_id,
    lot,
    phase = "none",
    street,
    subdivision,
  } = phase_ticket;

  return (
    <ResultCard link={`/qi/${job_id}/${kebabCase(phase)}`}>
      <div className="flex flex-row">
        <div className="font-bold grow">{job_id}</div>
        <div>
          {street || (
            <span className="italic text-gray-400">
              <FormattedMessage id="not provided" />
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-row">
        <div className="grow">{subdivision}</div>
        <div>
          <FormattedMessage id={phase} />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="grow">
          <FormattedMessage id="Lot" />:{" "}
          {lot || (
            <span className="text-gray-400 italic">
              <FormattedMessage id="not provided" />
            </span>
          )}
        </div>
        <div>{round(complete_installation * 100)}%</div>
      </div>
    </ResultCard>
  );
}
