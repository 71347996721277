import { random } from "lodash";
export default function JobDetailsPreloader({ loading }: { loading: boolean }) {
  return (
    <div className="flex flex-col px-4 gap-6">
      <div
        className={`bg-[#E8E8E8] w-full h-[250px] rounded-md card-skeleton`}
        style={{
          animation: `skeleton-loading linear ${random(0.9, 1.75)}s`,
          opacity: loading ? 1 : 0,
        }}
      />
      <div
        className={`bg-[#E8E8E8] w-full h-[44px] rounded-md card-skeleton`}
        style={{
          animation: `skeleton-loading linear ${random(0.9, 1.75)}s`,
          opacity: loading ? 1 : 0,
        }}
      />
      <div
        className={`bg-[#E8E8E8] w-full h-[44px] rounded-md card-skeleton`}
        style={{
          animation: `skeleton-loading linear ${random(0.9, 1.75)}s`,
          opacity: loading ? 1 : 0,
        }}
      />
      <div
        className={`bg-[#E8E8E8] w-full h-[200px] rounded-md card-skeleton`}
        style={{
          animation: `skeleton-loading linear ${random(0.9, 1.75)}s`,
          opacity: loading ? 1 : 0,
        }}
      />
      <div
        className={`bg-[#E8E8E8] w-full h-[44px] rounded-md card-skeleton`}
        style={{
          animation: `skeleton-loading linear ${random(0.9, 1.75)}s`,
          opacity: loading ? 1 : 0,
        }}
      />
      <div
        className={`bg-[#E8E8E8] w-full h-[44px] rounded-md card-skeleton`}
        style={{
          animation: `skeleton-loading linear ${random(0.9, 1.75)}s`,
          opacity: loading ? 1 : 0,
        }}
      />
    </div>
  );
}
