import { FormattedMessage } from "react-intl";
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/ArrowRight.svg";
export default function StatusChange({ status }: { status: any }) {
  const { old_status, new_status } = status;

  const display: { [key: string]: any } = {
    Complete: (
      <div className="text-green-500">
        <FormattedMessage id="Complete" />
      </div>
    ),
    Fail: (
      <div className="text-red-600">
        <FormattedMessage id="Fail" />
      </div>
    ),
    Incomplete: (
      <div className="text-red-600">
        <FormattedMessage id="Incomplete" />
      </div>
    ),
    "Not Started": (
      <div className="text-gray-600">
        <FormattedMessage id="Not Started" />
      </div>
    ),
    Pass: (
      <div className="text-green-500">
        <FormattedMessage id="Pass" />
      </div>
    ),
    "Select Status": (
      <div className="text-gray-600">
        <FormattedMessage id="Select Status" />
      </div>
    ),
  };

  function OldStatus({ old_status }: { old_status: string }) {
    if (!old_status || old_status === "Select Status") return null;
    return (
      <>
        {display[old_status]}
        <div>
          <ArrowRightIcon className="px-1 translate-y-[-2px]" />
        </div>
      </>
    );
  }

  function NewStatus({ new_status }: { new_status: string }) {
    if (!new_status) return null;
    return <>{display[new_status]}</>;
  }

  return (
    <div className="flex flex-row gap-1 font-bold grow">
      {old_status !== new_status ? (
        <>
          <OldStatus old_status={old_status} />
          <NewStatus new_status={new_status} />
        </>
      ) : (
        <span className="text-gray-500">Note</span>
      )}
    </div>
  );
}
