//import PreLoader from "../PreLoader";
import AppLayout from "../Layout";
import { FormattedMessage } from "react-intl";
import { useSchedule } from "./ScheduleContext";
import ScheduleHeader from "./header";
import ScheduleProgress from "./progress";
import ScheduleBody from "./body";
import HeaderButtons from "../components/header_buttons";
import ScheduleSkeleton from "./skeleton";

export default function ScheduleHome() {
  const { loading } = useSchedule();

  return (
    <AppLayout
      header={
        <>
          <div className="font-bold text-xl text-center">
            <FormattedMessage id="My Schedule" />
          </div>
          <HeaderButtons />
        </>
      }
    >
      {loading ? (
        <ScheduleSkeleton />
      ) : (
        <div className="flex flex-col gap-4 px-4">
          <ScheduleHeader />
          <ScheduleProgress />
          <ScheduleBody />
        </div>
      )}
    </AppLayout>
  );
}
/*
 <PreLoader
          color="#9ca3af"
          height={330}
          size={14}
          text="Loading schedule..."
        />
*/
