import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import BackArrowIcon from "../../assets/icons/BackArrow.svg";
import { ReactComponent as RadioIcon } from "../../assets/icons/Radio.svg";
import { ReactComponent as RadioSelectedIcon } from "../../assets/icons/RadioSelected.svg";
import FilterHeader from "./header";
import InputComponent from "./input";
import SelectComponent from "./select";
import { isEqual, map, orderBy, uniq } from "lodash";
import { useUI } from "../../UIContext";

export default function FilterDrawer({
  default_filter,
  filter: master_filter,
  filter_fields,
  header,
  items,
  open,
  setOpen,
  clearFilter,
}: {
  default_filter: any;
  filter: any;
  filter_fields: any[];
  header: string;
  items: any[];
  open: boolean;
  sort?: any;
  setOpen: any;
  clearFilter: any;
  loadFilter: any;
}) {
  const { language } = useUI();
  const is_spanish = language === "es";
  const [filter_, setFilter] = useState<any>(master_filter);
  const [lot, setLot] = useState<string>(master_filter.lot || "");
  const [street, setStreet] = useState<string>(master_filter.street || "");
  const [job_id, setJobID] = useState<string>(master_filter.job_id || "");

  const filter = { ...filter_, lot, street, job_id };

  const has_changes = !isEqual(filter, master_filter);
  const is_default = isEqual(filter, default_filter);
  const { order } = filter;
  const { dir, type } = order;

  useEffect(() => {
    if (open) {
      setFilter(master_filter);
      setLot(master_filter.lot || "");
      setStreet(master_filter.street || "");
      setJobID(master_filter.job_id || "");
    }
  }, [master_filter, open]);

  function handleChange(field: string, value: any) {
    setFilter({
      ...filter_,
      [field]: value,
    });
  }

  function ItemHolder({ children, label }: { children: any; label: string }) {
    return (
      <div>
        <div className="text-xl font-bold">
          <FormattedMessage id={label} />
        </div>
        <div>{children}</div>
      </div>
    );
  }

  function SortButton({ btn }: { btn: any }) {
    const { id, label } = btn;
    const isActive = id === dir;
    return (
      <div className="grow">
        <button
          className={`border-2 border-black text-white font-bold ${
            isActive ? "bg-ppi-green" : "bg-gray-400"
          } rounded-md px-2 py-1 w-full`}
          onClick={() => {
            handleChange("order", {
              ...order,
              dir: id,
            });
          }}
        >
          <FormattedMessage id={label} />
        </button>
      </div>
    );
  }

  function SortOption({ option }: { option: any }) {
    const { id, label } = option;
    const isActive = id === order.type;
    return (
      <div className="grow">
        <button
          className={`text-black text-xl font-bold rounded-md py-1 w-full`}
          onClick={() => {
            handleChange("order", {
              ...order,
              type: id,
            });
          }}
        >
          <div className="flex flex-row gap-3 items-center">
            <div>{isActive ? <RadioSelectedIcon /> : <RadioIcon />}</div>
            <div>
              <FormattedMessage id={label} />
            </div>
          </div>
        </button>
      </div>
    );
  }

  function FilterItem({ field }: { field: any }) {
    const { id, items, options, label, type } = field;
    const InputField = type ? InputComponent : SelectComponent;

    return (
      <InputField
        id={id}
        items={items}
        label={label}
        options={options}
        value={filter[id]}
        handleChange={handleChange}
      />
    );
  }

  return (
    <div className="h-full overflow-y-auto">
      <FilterHeader
        filter={filter}
        has_changes={has_changes}
        header={header}
        setOpen={setOpen}
      />
      <div className="flex flex-col px-6 pt-[55px] pb-[144px] gap-4 overflow-y-touch">
        <div>
          <div className="flex flex-row items-center">
            <div className="text-xl font-bold grow">
              <FormattedMessage id="Filter" />
            </div>
            <div>
              <button
                className={`text-xs uppercase font-bold ${
                  is_default ? "text-gray-400" : "text-black"
                }`}
                disabled={is_default}
                onClick={() => clearFilter()}
              >
                <FormattedMessage id="Clear" />
              </button>
            </div>
          </div>
          <div className="flex flex-col py-3 gap-3">
            {filter_fields.map((field: any) => {
              return <FilterItem key={field.id} field={field} />;
            })}

            <div key="lot" className="flex flex-col gap-1">
              <div>
                <input
                  className="font-bold text-xs2 h-10 w-full border-2 border-black rounded-md px-4"
                  autoComplete="off"
                  onChange={(e: any) => {
                    setLot(e.target.value);
                  }}
                  placeholder={is_spanish ? "Porción" : "Lot"}
                  type="text"
                  name={"lot"}
                  list={`lot_list`}
                  value={lot}
                />
                <datalist id={`lot_list`}>
                  {orderBy(uniq(map(items, "lot"))).map((item: any) => {
                    return (
                      <option className="" key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </datalist>
              </div>
            </div>

            <div key="street" className="flex flex-col gap-1">
              <div>
                <input
                  className="font-bold text-xs2 h-10 w-full border-2 border-black rounded-md px-4"
                  autoComplete="off"
                  onChange={(e: any) => {
                    setStreet(e.target.value);
                  }}
                  placeholder={is_spanish ? "Calle" : "Street"}
                  type="text"
                  name={"street"}
                  list={`street_list`}
                  value={street}
                />
                <datalist id={`street_list`}>
                  {orderBy(uniq(map(items, "street"))).map((item: any) => {
                    return (
                      <option className="" key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </datalist>
              </div>
            </div>

            <div key="job_id" className="flex flex-col gap-1">
              <div>
                <input
                  className="font-bold text-xs2 h-10 w-full border-2 border-black rounded-md px-4"
                  autoComplete="off"
                  onChange={(e: any) => {
                    setJobID(e.target.value);
                  }}
                  placeholder={is_spanish ? "Id del trabajo" : "Job ID"}
                  type="text"
                  name={"job_id"}
                  list={`job_id_list`}
                  value={job_id}
                />
                <datalist id={`job_id_list`}>
                  {orderBy(uniq(map(items, "job_id"))).map((item: any) => {
                    return (
                      <option className="" key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </datalist>
              </div>
            </div>
          </div>
        </div>
        <ItemHolder label="Sort">
          <div className="flex flex-col gap-2 py-2">
            <div className="flex flex-row items-center justify-center gap-4 w-full">
              {[
                { id: "asc", label: "Low to High" },
                { id: "desc", label: "High to Low" },
              ].map((btn: any) => {
                return <SortButton key={btn.id} btn={btn} />;
              })}
            </div>
            <div>
              {false &&
                [
                  { id: "lot", label: "Lot" },
                  { id: "job_id", label: "Job ID" },
                  { id: "phase", label: "Phase" },
                  { id: "status", label: "Status" },
                ].map((sort_option: any) => {
                  return (
                    <SortOption key={sort_option.id} option={sort_option} />
                  );
                })}
              <select
                className="font-bold text-xs2 h-10 px-4 w-full border-2 border-black rounded-md"
                value={type || "lot"}
                onChange={(e: any) => {
                  handleChange("order", {
                    ...order,
                    type: e.target.value,
                  });
                }}
                style={{
                  appearance: "none",
                  backgroundImage: `url(${BackArrowIcon})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "right 10px center",
                  WebkitAppearance: "none",
                  MozAppearance: "none",
                }}
              >
                {[
                  { id: "lot", label: "Lot" },
                  { id: "job_id", label: "Job ID" },
                  { id: "phase", label: "Phase" },
                  { id: "status", label: "Status" },
                ].map((item: any) => {
                  const { id, label } = item;
                  return (
                    <option className="" key={id} value={id}>
                      <FormattedMessage id={label} />
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </ItemHolder>
      </div>
    </div>
  );
}
