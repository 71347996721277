import { useUI } from "../UIContext";
import { FormattedMessage } from "react-intl";
import { ReactComponent as AddIcon } from "../assets/icons/Add.svg";
import { ReactComponent as HomeIcon } from "../assets/icons/Home.svg";
import { ReactComponent as HomeGreenIcon } from "../assets/icons/Home_Green.svg";
import { useUser } from "../UserContext";

export default function LayoutFooter({
  admin,
  edit,
  footer,
}: {
  admin: any;
  edit?: any;
  footer?: any;
}) {
  const { location, setLocation } = useUI();
  const { user } = useUser();

  function HomeButton() {
    const is_home = location === "/";
    return (
      <div className="flex flex-row justify-items-start grow">
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() =>
            setLocation(user?.scheduling_role === "Crew" ? "/schedule" : "/")
          }
        >
          <div>
            {is_home ? (
              <HomeGreenIcon className="h-[21px]" />
            ) : (
              <HomeIcon className="h-[21px]" />
            )}
          </div>
          <div
            className={`text-xs2 leading-xs2 font-medium ${
              is_home && "text-ppi-green"
            }`}
          >
            <FormattedMessage id="Home" />
          </div>
        </div>
      </div>
    );
  }

  function FooterAdminAddButton() {
    const quick_action_regex = /\/(qi|job|project)\/\d+/;
    if (!quick_action_regex.test(location) || true) return null;
    const { quick_action, setQuickAction } = admin;
    return (
      <div className="absolute left-0 flex flex-row items-center justify-center w-full pointer-events-none">
        <div
          className="flex flex-row items-center justify-center border-2 border-black bg-ppi-green rounded-full text-white h-[50px] w-[50px] cursor-pointer pointer-events-auto"
          onClick={() => {
            const flip = !quick_action;
            setQuickAction(flip);
          }}
        >
          <AddIcon className="h-[21px]" />
        </div>
      </div>
    );
  }

  const middle_button =
    user?.scheduling_role !== "Crew" ? edit || <FooterAdminAddButton /> : edit;

  return (
    <footer className="fixed bottom-0 w-full flex flex-col justify-center h-[72px] w-full-0 bg-white border-t-2 border-black z-50">
      <div className="flex flex-row items-center px-8 z-50">
        <HomeButton />
        {middle_button}
        {footer}
      </div>
    </footer>
  );
}
