import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { ReactComponent as FieldManagementIcon } from "./assets/field_management.svg";
import { ReactComponent as LookupIcon } from "./assets/lookup.svg";
import { ReactComponent as QIQueueIcon } from "./assets/qi_queue.svg";
import { ReactComponent as ScheduleIcon } from "./assets/schedule.svg";
import { ReactComponent as TruckIcon } from "./assets/truck.svg";
import { ReactComponent as UserIcon } from "./assets/user.svg";
import { useLocation } from "wouter";
import { compact, includes, kebabCase, map, reduce, split } from "lodash";
import Can from "./components/Can";
import {
  REACT_APP_TABLE_PHASES,
  REACT_APP_BACKEND_API,
} from "./context/variables";
const { REACT_APP_ENV } = process.env;
const isMobile = window.matchMedia("(max-width: 640px)").matches;
const is_local = REACT_APP_ENV === "local";
interface UIValues {
  app_buttons: any[];
  isMobile: boolean;
  language: string;
  location: string;
  navigateTo: (link: string) => void;
  open: boolean;
  phase_names: any;
  phases: any | null;
  popUp: any | null;
  prev_location: any;
  consoleLog: (title: string, data: any) => void;
  setDrawerOpen: (setting: boolean) => void;
  setLanguage: (language: string) => void;
  setLocation: (location: any) => void;
  setPopUp: (element: any) => void;
  toggleLanguage: () => void;
}

export const UIContext = createContext<UIValues>({
  app_buttons: [],
  isMobile: true,
  language: "en",
  location: "",
  navigateTo: () => {},
  open: false,
  phase_names: {},
  phases: null,
  popUp: <></>,
  prev_location: undefined,
  consoleLog: () => {},
  setDrawerOpen: () => {},
  setLanguage: () => {},
  setLocation: () => {},
  setPopUp: () => {},
  toggleLanguage: () => {},
});

export const useUI = () => useContext(UIContext);

const UIContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  function getCachedLanguage() {
    const cached_language = localStorage.getItem("language");
    if (
      typeof cached_language === "string" &&
      cached_language !== "undefined" &&
      cached_language !== "null"
    ) {
      return cached_language;
    }
    return "en";
  }

  const [location, setLocation] = useLocation();
  const [prev_location, setPreviousLocation] = useState<any>();
  const [language, setLanguage] = useState<string>(getCachedLanguage());
  const [popUp, setPopUp] = useState<any | null>(null);
  const [open, setDrawerOpen] = useState<boolean>(false);

  const [loading_phases, setLoadingPhases] = useState<boolean>(false);
  const [phases, setPhases] = useState<any | null>(null);


  
  const app_buttons = compact(
    map(
      [
        {
          icon: LookupIcon,
          label: "Lookup",
          route: "/lookup",
          match: (location: string) =>
            includes(["lookup", "project"], split(location, "/")[1]),
          perform: "view:lookup",
        },
        {
          disabled: true,
          icon: FieldManagementIcon,
          label: "Field Management",
          route: "/field",
          match: (location: string) => "/field" === location,
          perform: "view:field-management",
        },
        {
          icon: ScheduleIcon,
          label: "Schedule",
          route: "/schedule",
          match: (location: string) =>
            includes(["schedule", "job"], split(location, "/")[1]),
        },
        {
          disabled: true,
          icon: TruckIcon,
          label: "Trucks",
          route: "/trucks",
          match: (location: string) => "truck" === split(location, "/")[1],
          perform: "view:trucks",
        },
        {
          icon: QIQueueIcon,
          label: "QI Queue",
          route: "/qi",
          match: (location: string) => "qi" === split(location, "/")[1],
          perform: "view:qi",
        },
        {
          icon: UserIcon,
          label: "Settings",
          route: "/settings",
          match: (location: string) => "/settings" === location,
        },
      ],
      function (btn: any) {
        const { disabled, perform } = btn;
        if (disabled) return false;
        return Can({ perform, yes: () => btn });
      }
    )
  );

  function consoleLog(label: string, data: any) {
    if (!is_local) return;
    console.log(label);
    console.log(data);
  }

  function navigateTo(link: string) {
    window.location.href = link;
    open && setDrawerOpen(false);
  }

  const toggleLanguage = () => {
    if (language === "en") {
      setLanguage("es");
    } else {
      setLanguage("en");
    }
  };

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  useEffect(() => {
    const handlePopState = () => {
      const prevPath = window.location.pathname;
      console.log("PREVIOUS", { prevPath });
      setPreviousLocation(prevPath);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [setPreviousLocation]);

  async function getAndSetPhases() {
    setLoadingPhases(true);
    const payload = {
      table_id: REACT_APP_TABLE_PHASES, // "bsfph2d4g",
    };
    const { data: phases } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      payload
    );
    setPhases(phases);
    setLoadingPhases(false);
  }

  useEffect(() => {
    if (!!phases || !!loading_phases) return;
    getAndSetPhases();
  });
  const phase_names = reduce(
    phases?.data,
    (acc: any, p: any) => {
      const id = kebabCase(p.phase);
      acc[id] = p.phase;
      return acc;
    },
    {}
  );

  return (
    <UIContext.Provider
      value={{
        app_buttons,
        isMobile,
        language,
        location,
        navigateTo,
        open,
        popUp,
        phase_names,
        phases: phases?.data || [],
        prev_location,
        consoleLog,
        setDrawerOpen,
        setLanguage,
        setLocation,
        setPopUp,
        toggleLanguage,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export default UIContextProvider;
