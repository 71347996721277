import { FormattedMessage } from "react-intl";
import { useQIJob } from "../QIJobContext";

export default function ItemsListItem() {
  const { filtered_line_items, new_line_item, setLineItem } = useQIJob();

  return (
    <div className="flex flex-col gap-4 text-xs2 leading-xs2 text-ppi-light-text pb-[72px]">
      {filtered_line_items.map((line_item: any, idx: number) => {
        const {
          description,
          line_item_id,
          qi_status,
          quantity,
          record_id,
          installation_status,
        } = line_item;

        const is_active = record_id === new_line_item?.record_id;

        const qi_display: { [key: string]: any } = {
          Pass: (
            <span className="text-green-500">
              <FormattedMessage id="Pass" /> ({quantity})
            </span>
          ),
          Fail: (
            <span className="text-red-600">
              <FormattedMessage id="Fail" /> ({quantity})
            </span>
          ),
          "Select Status": (
            <span className={is_active ? "text-gray-300" : "text-gray-400"}>
              <FormattedMessage id="Select Status" /> ({quantity})
            </span>
          ),
        };

        const display: { [key: string]: any } = {
          Complete: (
            <span className="text-green-500">
              <FormattedMessage id="Complete" />
            </span>
          ),
          Incomplete: (
            <span className="text-red-600">
              <FormattedMessage id="Incomplete" />
            </span>
          ),
          "Not Started": (
            <span className={is_active ? "text-gray-300" : "text-gray-400"}>
              <FormattedMessage id="Not Started" />
            </span>
          ),
        };

        //   const is_loading = record_id === loading_line_item;

        return (
          <div
            key={idx}
            className={`${
              is_active ? "bg-blue-600 text-white" : "bg-white"
            } border-2 border-black rounded-md px-3 py-1`}
            onClick={() => setLineItem(line_item)}
          >
            {false ? (
              <div className="flex flex-col gap-2 py-1 animate-pluse">
                <div className="flex flex-row gap-2 grow">
                  <div className="grow">
                    <div className="bg-gray-400/40 w-12 h-3 rounded-sm"></div>
                  </div>
                  <div className="bg-gray-400/40 w-20 h-3 rounded-sm"></div>
                </div>
                <div className="flex flex-row items-end gap-2 font-bold whitespace-nowrap">
                  <div className="grow">
                    <div className="bg-gray-400/40 w-48 h-3 rounded-sm"></div>
                  </div>
                  <div className="bg-gray-400/40 w-24 h-3 rounded-sm"></div>
                </div>
              </div>
            ) : (
              <div className={`flex flex-col py-1`}>
                <div className="flex flex-row">
                  <div className="font-bold grow">
                    {line_item_id || (
                      <span
                        className={`italic font-normal ${
                          is_active ? "text-gray-300" : "text-gray-400"
                        }`}
                      >
                        <FormattedMessage id="not provided" />
                      </span>
                    )}
                  </div>
                  <div className="font-bold whitespace-nowrap">
                    {qi_display[qi_status || "Select Status"]}
                  </div>
                </div>
                <div className="flex flex-row gap-1">
                  <div className="line-clamp-2 grow">{description}</div>
                  <div className="font-bold whitespace-nowrap">
                    {display[installation_status || "Not Started"]}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
