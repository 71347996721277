import { useQI } from "../../QIQueue/QIContext";
import {
  Chart as ChartJS,
  ChartOptions,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { filter, map, random, sum } from "lodash";
import KPICardHolder from "./card_holder";
import { Doughnut } from "react-chartjs-2";
import { FormattedMessage } from "react-intl";
import KPICardHolderSkeleton from "./card_holder_skeleton";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function JobsByStatusKPI() {
  const { loading, phase_tickets } = useQI();
  const legend = [
    { status: "Not Started", color: "#B1B1B1" },
    { status: "Pending", color: "#83B57C" },
    { status: "In-Progress", color: "#FFB865" },
    { status: "Incomplete", color: "#F66363" },
  ];
  const data = {
    // labels,
    datasets: [
      {
        data: map(legend, function ({ status }: { status: string }) {
          return filter(phase_tickets, { status })?.length || 0;
        }),
        backgroundColor: map(legend, "color"),
        borderColor: ["#000000"],
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<"doughnut"> = {
    cutout: 46,
  };
  if (loading) {
    return (
      <KPICardHolderSkeleton>
        <div className="grid grid-cols-2 items-center">
          <div className="flex flex-col gap-4">
            {legend.map(({ status }: { status: string }) => {
              return (
                <div
                  className="my-1 h-8 w-24 bg-[#F4F5F8] rounded-md"
                  key={status}
                  style={{
                    animation: `skeleton-loading linear ${random(0.9, 1.75)}s`,
                  }}
                ></div>
              );
            })}
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="w-full aspect-square bg-[#F4F5F8] rounded-full"></div>
          </div>
        </div>
      </KPICardHolderSkeleton>
    );
  }
  return (
    <KPICardHolder title="Jobs by Status">
      <div className="grid grid-cols-2 items-center">
        <div className="flex flex-col gap-4">
          {legend.map((item: any) => {
            const { color, status } = item;
            const quantity = filter(phase_tickets, { status })?.length || 0;
            const percentage = phase_tickets ? (
              <span className="text-gray-400">
                {((quantity * 100) / phase_tickets.length).toFixed()}%
              </span>
            ) : (
              ""
            );
            return (
              <div key={status} className="flex flex-col">
                <div className="flex flex-row gap-2 items-center">
                  <div
                    className={`h-4 w-4 rounded-full`}
                    style={{
                      backgroundColor: color,
                    }}
                  >
                    &nbsp;
                  </div>
                  <div className="font-bold">{quantity}</div>
                </div>
                <div className="text-xs2">
                  <span className="font-semibold">
                    <FormattedMessage id={status} />
                  </span>{" "}
                  {percentage}
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="absolute text-4xl font-bold">
            {sum(data?.datasets?.[0]?.data)}
          </div>
          <Doughnut
            className="translate-y-[-4px]"
            data={data}
            options={options}
          />
        </div>
      </div>
    </KPICardHolder>
  );
}
