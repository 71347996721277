import { useState } from "react";
import AppLayout from "../Layout";
import HeaderButtons from "../components/header_buttons";
import ItemHolder from "./item_holder";
import { useUI } from "../UIContext";
import { FormattedMessage } from "react-intl";
import { LienRelease } from "../context/types";

export default function RaiseIssue({
  lien_release,
  updating,
  setView,
  updateLienRelease,
}: {
  lien_release: LienRelease;
  updating: boolean;
  setView: any;
  updateLienRelease: any;
}) {
  const { language } = useUI();
  const [field_notes, setFieldNotes] = useState<string>(
    lien_release?.field_notes || ""
  );

  function HeaderBottom() {
    if (updating) {
      return (
        <div className="flex flex-row items-center border-b-2 border-black bg-white px-4 py-2 w-full h-[50px] pointer-events-auto">
          <div className="font-bold grow">
            <FormattedMessage id="Raise Issue" />
          </div>
          <div className="font-bold">
            <FormattedMessage id="Updating..." />
          </div>
        </div>
      );
    }
    return (
      <div className="flex flex-row items-center border-b-2 border-black bg-white px-4 py-2 w-full">
        <div className="grow">
          <button
            className="bg-ppi-red text-white font-bold border-2 border-black rounded-md h-8 px-2"
            onClick={() => setView("release")}
          >
            <FormattedMessage id="Undo" />
          </button>
        </div>
        <div>
          <button
            className={`${
              !!field_notes ? "bg-ppi-green" : "bg-gray-400"
            } text-white font-bold border-2 border-black rounded-md h-8 px-2`}
            disabled={!field_notes}
            onClick={() =>
              updateLienRelease({
                date_accepted: new Date(),
                field_notes,
                record_id: lien_release?.record_id,
                signature: lien_release?.contact_signature,
                status: "Issue",
              })
            }
          >
            <FormattedMessage id="Submit" />
          </button>
        </div>
        <div className="absolute left-0 font-bold text-center w-full pointer-events-none">
          <FormattedMessage id="Raise Issue" />
        </div>
      </div>
    );
  }

  return (
    <AppLayout
      header={
        <>
          <div className="font-bold text-xl text-center">
            <FormattedMessage id="Lien Release" />
          </div>
          <div className="flex flex-row justify-items-end px-1 translate-y-[2px] absolute">
            <button
              className="bg-ppi-blue text-white font-bold border-2 border-black rounded-md h-8 px-2"
              onClick={() => setView("release")}
            >
              <FormattedMessage id="Back" />
            </button>
          </div>
          <HeaderButtons />
        </>
      }
      header_bottom={<HeaderBottom />}
    >
      <div className="px-4 pt-[50px]">
        <div className="flex flex-col gap-2">
          <ItemHolder>
            <textarea
              className="w-full"
              onBlur={() => {
                const the_zoom = (document?.body?.style as any)?.zoom;
                if (the_zoom) {
                  (document.body.style as any).zoom = "100%";
                  // document.body.style.transform = 'scale(1)';
                }
              }}
              onChange={(e: any) => setFieldNotes(e.target.value)}
              placeholder={
                language === "es"
                  ? "Describa su problema, pregunta o inquietud..."
                  : "Describe your issue, question or concern..."
              }
              rows={5}
              value={field_notes}
            />
          </ItemHolder>
        </div>
      </div>
    </AppLayout>
  );
}
