export type Activity = {
  amount: string;
  billable: string;
  contact: string;
  date_created: string;
  note: string;
};

export type ChangeLog = {
  contact: string;
  date: string;
  old_status: string;
  new_status: string;
  notes: string;
  record_id: string;
};

export type LienRelease = {
  builder: string;
  contact: string;
  contact_address: string;
  contact_company: string;
  contact_email: string;
  contact_signature: string;
  contact_signature_date: string;
  date_accepted: string;
  date_created: string;
  field_notes: string;
  job_id: string;
  lot: number;
  phase: string;
  record_id: string;
  status: string;
  street: string;
  subdivision: string;
  total: number;
};

export type LineItem = {
  category: string;
  description: string;
  installation_status: string;
  line_item_id: string;
  phase: string;
  qi_status: string;
  quantity: number;
  record_id: string;
  room: string;
  subcategory: string;
};
export type Notification = {
  date: string;
  date_created: string;
  description: string;
  destination_record_id: string;
  notification: string;
  status: string;
  time: string;
};

export type PhaseTicket = {
  builder: string;
  complete_installation: number;
  job_id: number;
  lot: string | null;
  phase: string;
  record_id: string;
  status: string;
  street: string;
  subdivision: string;
  walk_status: string;
};

export type Project = {
  area_manager: string;
  bsr: string;
  builder: string;
  email_a_m: string;
  email_bsr: string;
  email_s_s: string;
  email_w_m: string;
  job_id: number;
  lot: string | null;
  mobile_app_header: string;
  note_general: string;
  notes_field: string;
  phone_a_m: string;
  phone_bsr: string;
  phone_s_s: string;
  phone_w_m: string;
  phase: string;
  project_address: string;
  record_id: number;
  status: string;
  street: string;
  subdivision: string;
  subdivision_supervisor: string;
  warranty_manager: string;
};

export type QIFilter = {
  builder: string;
  job_id: string;
  lot?: number;
  order: Order;
  phase: string;
  status: string;
  street: string;
  subdivision: string;
  [key: string]: string | number | Order | undefined;
};

export type Schedule = {
  address: string;
  builder: string;
  county: string; // No County Data on Schedule, Requested in components/ResultsItem/Schedule/bottom
  filter_submitted: boolean;
  job_id: number;
  lot: string | null;
  mobile_app_header: string;
  notes_field: string;
  notes_office: string;
  of_sinks: number;
  of_toilets: number;
  of_tubs: number;
  of_water_heaters: number;
  phase: string;
  priority: string;
  record_id: number;
  related_phase_ticket: number;
  schedule_date: string;
  subdivision: string;
  timeslot: string;
  type: string;
};

export type User = {
  address: string;
  city: string;
  company: string;
  contact_type: string[];
  country: string;
  email: string;
  first_name: string;
  internal_role_s: string[];
  job_title: string;
  last_name: string;
  name: string;
  phone: string;
  pin: string;
  postal_code: string;
  record_id: number;
  scheduling_role: string;
  state_region: string;
  street_1: string;
  street_2: string;
};

// Intermediate ("Data") Types
export type ActivityData = {
  id: string;
  data: Activity[];
};

export type LineItemData = {
  id: string;
  data: LineItem[];
};

export type NotificationData = {
  id: string;
  data: Notification[];
};

export type Order = {
  type: string;
  dir: string;
};

export type ProjectData = {
  id: string;
  data: Project[];
};

export type ScheduleData = {
  id: string;
  data: Schedule[];
};

// Type Fields, Used to Query only needed data
export const ActivityFields = [
  "amount",
  "billable",
  "contact",
  "date_created",
  "note",
];

export const ChangeLogFields = [
  "contact",
  "date",
  "old_status",
  "new_status",
  "notes",
  "record_id",
];
export const LienReleaseFields = [
  "builder",
  "contact",
  "contact_address",
  "contact_company",
  "contact_email",
  "contact_signature",
  "contact_signature_date",
  "date_accepted",
  "date_created",
  "field_notes",
  "job_id",
  "lot",
  "phase",
  "record_id",
  "status",
  "street",
  "subdivision",
  "total",
];

export const LineItemFields = [
  "category",
  "description",
  "installation_status",
  "line_item_id",
  "phase",
  "qi_status",
  "quantity",
  "record_id",
  "room",
  "subcategory",
];

export const NotificationFields = [
  "date",
  "date_created",
  "description",
  "destination_record_id",
  "notification",
  "status",
  "time",
];

export const PhaseTicketFields = [
  // "address",
  // "area_manager",
  "builder",
  "complete_installation",
  // "checklist_1",
  // "checklist_10",
  // "checklist_2",
  // "checklist_3",
  // "checklist_4",
  // "checklist_5",
  // "checklist_6",
  // "checklist_7",
  // "checklist_8",
  // "checklist_9",
  // "crew",
  // "filter_installation_complete",
  // "filter_qi_complete",
  // "filter_trigger_qi",
  // "header",
  "job_id",
  "lot",
  // "note_current_phase",
  // "note_qi",
  // "of_fixtures",
  "phase",
  // "phase_code",
  // "priority",
  // "project_address",
  // "project_email_a_m",
  // "project_name",
  // "project_note_general",
  "record_id",
  "status",
  "street",
  "subdivision",
  // "trigger_qi_queue_comment",
  "walk_status",
];

export const ProjectLookupFields = [
  "builder",
  "job_id",
  "lot",
  "phase",
  "street",
  "subdivision",
];

export const ProjectFields = [
  "area_manager",
  "bsr",
  "builder",
  "email_a_m",
  "email_bsr",
  "email_s_s",
  "email_w_m",
  "job_id",
  "lot",
  "mobile_app_header",
  "note_general",
  "notes_field",
  "phone_a_m",
  "phone_bsr",
  "phone_s_s",
  "phone_w_m",
  "phase",
  "project_address",
  "record_id",
  "status",
  "street",
  "subdivision",
  "subdivision_supervisor",
  "warranty_manager",
];

export const ScheduleFields = [
  "address",
  "builder",
  "county",
  "filter_submitted",
  "job_id",
  "lot",
  "mobile_app_header",
  "notes_field",
  "notes_office",
  "of_sinks",
  "of_toilets",
  "of_tubs",
  "of_water_heaters",
  "phase",
  "priority",
  "record_id",
  "related_phase_ticket",
  "schedule_date",
  "subdivision",
  "timeslot",
  "type",
];

export const UserFields = [
  "address",
  "city",
  "company",
  "contact_type",
  "country",
  "email",
  "first_name",
  "internal_role_s",
  "job_title",
  "last_name",
  "name",
  "phone",
  "pin",
  "postal_code",
  "record_id",
  "scheduling_role",
  "state_region",
  "street_1",
  "street_2",
];

export const default_filter: QIFilter = {
  builder: "",
  job_id: "",
  order: {
    type: "lot",
    dir: "asc",
  },
  phase: "",
  status: "",
  street: "",
  subdivision: "",
};
