import { useUI } from "../../UIContext";

export default function ResultCard({
  children,
  link,
}: {
  children: any;
  link: string;
}) {
  const { setLocation } = useUI();
  return (
    <div
      className={`border-2 border-black rounded-md px-3 py-1 text-xs bg-white`}
      onClick={() => {
        setLocation(link);
      }}
    >
      {children}
    </div>
  );
}
