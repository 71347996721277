import { pick } from "lodash";
import rules from "../context/rbac";
import { useUser } from "../UserContext";
var _ = require("lodash");

export const check = (
  rules: any,
  perform: string,
  data?: any,
  roles_?: any
) => {
  //if (!roles) return false;
  const roles = roles_ || ["Visitor"];

  const permissions = _.map(roles, function (o: string) {
    return rules[o];
  });

  if (!permissions || permissions.length === 0) {
    return false;
  }

  const staticPermissions = _.compact(_.uniq(_.flatMap(permissions, "static")));

  const dynamicPermissions = _.compact(
    _.map(_.compact(_.flatMap(permissions, "dynamic")), function (o: any) {
      return o[perform];
    })
  );

  if (staticPermissions && staticPermissions.includes(perform)) {
    return true;
  }

  if (dynamicPermissions && dynamicPermissions.length > 0) {
    const dynamicCondition = _.map(dynamicPermissions, function (f: any) {
      return f(data);
    });

    return _.some(dynamicCondition);
  }

  return false;
};

export default function Can({
  perform,
  data,
  yes = () => null,
  no = () => null,
}: {
  perform?: string;
  data?: any;
  yes: () => any;
  no?: () => any;
}) {
  const { user, simulated_role } = useUser();
  const roles = simulated_role
    ? [simulated_role]
    : pick(user, ["internal_roles_s", "scheduling_role"]);
  if (!perform) return yes();
  return check(rules, perform, data, roles) ? yes() : no();
}
