import { useEffect, useState } from "react";
import { constant, orderBy, random, times } from "lodash";
import { FormattedMessage } from "react-intl";
import { useQI } from "./QIContext";
import { ReactComponent as DownArrowIcon } from "../assets/icons/DownArrow.svg";
import { ReactComponent as UpArrowGreenIcon } from "../assets/icons/UpArrowGreen.svg";
import ResultsItem from "../components/ResultsItem";
import { PhaseTicket } from "../context/types";

export default function ResultsTypeHolder({
  label,
  results,
}: {
  label: string;
  results: any;
}) {
  const cached_state = localStorage.getItem(`QIQueue:${label}`);
  const { filter, loading } = useQI();
  const { dir, type } = filter.order;
  const [open, setOpen] = useState<boolean>(!(cached_state === "false"));

  useEffect(() => {
    localStorage.setItem(`QIQueue:${label}`, JSON.stringify(open));
  }, [label, open]);

  function calculateNumElements() {
    const screenHeight = window.innerHeight;
    const availableHeight = (screenHeight - 114) / 2; // Subtracting 144px
    const elementHeightWithGap = 60 + 16; // Element height + gap
    const calculatedNumElements = Math.floor(
      availableHeight / elementHeightWithGap
    );
    return calculatedNumElements;
  }

  return (
    <div className="flex flex-col gap-4">
      <div
        className="flex flex-row items-center font-bold text-xl px-8 cursor-pointer"
        onClick={() => {
          const flip = !open;
          setOpen(flip);
        }}
      >
        <div
          className={`${
            loading || open ? "text-ppi-green" : "text-black"
          } grow`}
        >
          <FormattedMessage id={label} />
        </div>
        <div>{loading || open ? <UpArrowGreenIcon /> : <DownArrowIcon />}</div>
      </div>
      <div className="flex flex-col gap-3 px-3 text-ppi-light-text">
        {loading ? (
          <>
            {times(calculateNumElements(), constant(null)).map(
              (job: any, idx: number) => {
                return (
                  <div
                    className="bg-[#E8E8E8] w-full h-[60px] rounded-md card-skeleton"
                    key={idx}
                    style={{
                      animation: `skeleton-loading linear ${random(
                        0.9,
                        1.75
                      )}s`,
                      opacity: loading ? 1 : 0,
                    }}
                  ></div>
                );
              }
            )}
          </>
        ) : (
          <>
            {open && (
              <>
                {orderBy(results, [type], [dir as "desc" | "asc"]).map(
                  (job: PhaseTicket, idx: number) => {
                    return <ResultsItem key={idx} item={job} type="qi" />;
                  }
                )}
              </>
            )}
          </>
        )}{" "}
      </div>
    </div>
  );
}
