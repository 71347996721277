import { useState } from "react";
import { map, sample, uniq, kebabCase } from "lodash";
import { FormattedMessage } from "react-intl";
import AppLayout from "../../Layout";
import PreLoader from "../../PreLoader";
import { ReactComponent as BackArrowGreenIcon } from "../../assets/icons/BackArrowGreen.svg";
import { ReactComponent as DetailsIcon } from "../../assets/icons/Details.svg";
import { ReactComponent as DetailsGreenIcon } from "../../assets/icons/DetailsGreen.svg";
import { ReactComponent as DoubleCheckGreenIcon } from "../../assets/icons/DoubleCheck.svg";
import { ReactComponent as CheckRedIcon } from "../../assets/icons/Check.svg";
import { useQIJob } from "./QIJobContext";
import { useUI } from "../../UIContext";
import DetailsDrawer from "../../components/JobDetails";

//const checklist_fields = [106, 107, 108, 109, 110, 111, 112, 113, 114, 115];
export default function QIQueueJob() {
  const { setLocation } = useUI();
  const { job_id, loading, phase_tickets } = useQIJob();
  const [open, setOpen] = useState<boolean>(false);

  const qi_state: { [key: string]: any } = {
    Fail: <CheckRedIcon />,
    Pass: <DoubleCheckGreenIcon />,
  };

  function FooterButton() {
    return (
      <div
        className="flex flex-col items-center text-center cursor-pointer"
        onClick={() => {
          const flip = !open;
          setOpen(flip);
        }}
      >
        <div>
          {open ? (
            <DetailsGreenIcon className="h-[21px]" />
          ) : (
            <DetailsIcon className="h-[21px]" />
          )}
        </div>
        <div
          className={`text-xs2 leading-xs2 ${
            open && "text-ppi-green"
          } font-medium`}
        >
          <FormattedMessage id="Details" />
        </div>
      </div>
    );
  }
  const phases = uniq(map(phase_tickets, "phase"));
  console.log({ phase_tickets, phases });
  return (
    <AppLayout
      details={
        <div
          className="relative bg-[#F4F5F8] flex-1 h-full w-full z-50 pt-[72px] pb-[88px] overflow-y-auto pointer-events-auto"
          style={{
            transform: open
              ? "translate3d(0vw, 0, 0)"
              : "translate3d(100vw, 0, 0)",
            transition: "transform .3s cubic-bezier(0, .52, 0, 1)",
          }}
        >
          <DetailsDrawer job_id={job_id} setOpen={setOpen} type="qi" />
        </div>
      }
      footer={<FooterButton />}
      header={
        <>
          <div className="font-bold text-xl text-center">
            <FormattedMessage
              id={sample(phase_tickets)?.street || "loading..."}
            />
          </div>
          <div className="flex flex-row justify-items-end px-1 translate-y-[2px] absolute">
            <button onClick={() => setLocation("/qi")}>
              <BackArrowGreenIcon className="h-[21px]" />
            </button>
          </div>
        </>
      }
    >
      {loading ? (
        <PreLoader text="Loading Job {job_id} ..." values={{ job_id }} />
      ) : (
        <>
          <div className="flex flex-col gap-4">
            <div className="font-bold text-xl px-8">
              <FormattedMessage id="Quality Inspection" />
            </div>
            <div className="flex flex-col bg-white font-bold text-sm border-y border-ppi-border ppi-table-row px-4">
              {[
                { id: "Water & Sewer", value: "Pass" },
                { id: "Slab", value: "Pass" },
                { id: "Rough", value: "Fail" },
                { id: "Trim", value: "" },
              ].map((phase: any) => {
                const { id, value } = phase;
                const link = `/qi/${job_id}/${kebabCase(id)}`;
                return (
                  <div
                    key={id}
                    className="cursor-pointer"
                    onClick={() => setLocation(link)}
                  >
                    <div className="flex flex-row items-center border-b border-ppi-border py-3">
                      <div className="grow">
                        <FormattedMessage id={id} />
                      </div>
                      <div className="px-4">{qi_state[value]}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </AppLayout>
  );
}
