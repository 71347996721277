import { FormattedMessage } from "react-intl";
export default function HeaderTitle({ title }: { title?: string }) {
  return (
    <div className="font-bold text-xl text-center">
      {title ? (
        <FormattedMessage id={title} />
      ) : (
        <span className="text-ppi-skeleton">
          <FormattedMessage id={"Loading..."} />
        </span>
      )}
    </div>
  );
}
