import { useEffect, useState } from "react";
import axios from "axios";
import { includes, isEqual, merge } from "lodash";
import { FormattedMessage } from "react-intl";
import ButtonSet from "./button_set";
import {
  REACT_APP_TABLE_PLI_LOG,
  REACT_APP_TABLE_PROJECT_LINE_ITEMS,
  REACT_APP_BACKEND_API,
} from "../../context/variables";
import { useUI } from "../../UIContext";
import { useUser } from "../../UserContext";
import DetailsItemOpen from "./item_open";
import ChangeLog from "./ChangeLog";
import EditItemHeader from "./header";
import {
  ChangeLog as ChangeLogType,
  ChangeLogFields,
  LineItem,
  Project,
  Schedule,
} from "../../context/types";

type ProjectSchedule = Pick<Project, keyof Project & keyof Schedule> &
  Pick<Schedule, keyof Project & keyof Schedule>;

export default function EditDrawerBody({
  is_ready,
  job_info,
  line_item,
  new_line_item,
  new_notes,
  qi,
  setLineItem,
  setNotes,
  setOpen,
  updateLineItem,
}: {
  is_ready: boolean;
  job_info: ProjectSchedule;
  line_item: LineItem;
  new_line_item: LineItem;
  new_notes: string;
  qi?: boolean;
  setLineItem: any;
  setNotes: any;
  setOpen: any;
  updateLineItem: any;
}) {
  const { consoleLog, language } = useUI();
  const { user } = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const { installation_status, qi_status } = line_item || {};

  const [change_log, setChangeLog] = useState<{
    id: string;
    data: ChangeLogType[];
  } | null>(null);

  const {
    category,
    description,
    installation_status: new_installation_status,
    line_item_id,
    phase,
    qi_status: new_qi_status,
    quantity,
    record_id,
    subcategory,
  } = new_line_item;

  const has_changes =
    !isEqual(
      {
        installation_status: new_installation_status,
        qi_status: new_qi_status,
      },
      { installation_status, qi_status }
    ) || !!new_notes;

  const records = [
    merge(
      {
        record_id,
        installation_status: new_installation_status,
        qi_status: new_qi_status,
      },
      qi
        ? {
            qi_notes: new_notes,
          }
        : {
            installation_notes: new_notes,
          }
    ),
  ];
  const qi_has_changes = qi_status !== new_qi_status;

  const base_state = {
    date: new Date(),
    notes: new_notes,
    related_contact: user?.record_id,
    related_project_line_item: record_id,
  };

  const qi_state = {
    old_status: qi_status,
    new_status: new_qi_status,
  };
  const installation_has_changes =
    installation_status !== new_installation_status;

  const installation_state = {
    old_status: installation_status,
    new_status: new_installation_status,
    related_schedule: job_info?.record_id,
  };

  function getChanges() {
    switch (true) {
      case qi_has_changes && installation_has_changes:
        return [
          merge({}, base_state, qi_state),
          merge({}, base_state, installation_state),
        ];
      case qi_has_changes:
        return [merge({}, base_state, qi_state)];
      case installation_has_changes:
        return [merge({}, base_state, installation_state)];
      case !!new_notes:
        return [merge({}, base_state)];
    }
  }

  const changes = getChanges();

  const payload = {
    changes,
    records,
    table_id: REACT_APP_TABLE_PROJECT_LINE_ITEMS,
  };

  const placeholder = language === "es" ? "Dejar una nota" : "Leave a note";
  const where = `{6.EX.${record_id}}`;
  async function getAndSetChangeLog(where: string) {
    const { data: change_log } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      {
        table_id: REACT_APP_TABLE_PLI_LOG,
        where,
        fields: ChangeLogFields,
      }
    );
    setChangeLog(change_log);
  }

  useEffect(() => {
    getAndSetChangeLog(where);
  }, [installation_status, qi_status, where]);

  function setStatus(field: string, status: string) {
    const new_item = { ...new_line_item, [field]: status };
    console.log("SETTING  NEW ITEM", new_item);
    setLineItem(new_item);
  }

  return (
    <div className="h-full overflow-y-auto pt-[55px]">
      <EditItemHeader
        has_changes={has_changes && !!is_ready}
        loading={loading}
        cancelChanges={() => {
          consoleLog("CANCELLING - EditLineItem", line_item);
          setNotes("");
          setLineItem(line_item);
          setOpen(null);
        }}
        updateLineItem={async () => {
          try {
            setLoading(true);
            consoleLog("UPDATING - EditLineItem", line_item);
            await updateLineItem(payload);
            await getAndSetChangeLog(where);
            setNotes("");
          } catch (err: any) {
            console.log("ERROR UPDATING ITEM", err);
          } finally {
            setLoading(false);
          }
        }}
      />
      <div className="flex flex-col gap-4 p-4 min-h-full">
        <DetailsItemOpen
          tab={{
            label: line_item_id || (
              <span className="italic font-normal text-gray-400">
                <FormattedMessage id="not provided" />
              </span>
            ),
          }}
        >
          <table className="w-full">
            <tbody>
              <tr>
                <td className="align-top font-bold text-right pr-2 w-1/2">
                  Description
                </td>
                <td className="align-top pl-2">{description}</td>
              </tr>
              <tr>
                <td className="align-top font-bold text-right pr-2 w-1/2">
                  Category
                </td>
                <td className="align-top pl-2">{category}</td>
              </tr>
              <tr>
                <td className="align-top font-bold text-right pr-2 w-1/2">
                  Subcategory
                </td>
                <td className="align-top pl-2">{subcategory}</td>
              </tr>
              <tr>
                <td className="align-top font-bold text-right pr-2 w-1/2">
                  Phase
                </td>
                <td className="align-top pl-2">{phase}</td>
              </tr>
              <tr>
                <td className="align-top font-bold text-right pr-2 w-1/2">
                  Quantity
                </td>
                <td className="align-top pl-2">{quantity}</td>
              </tr>
            </tbody>
          </table>
        </DetailsItemOpen>
        <div className="flex flex-col gap-2">
          <ButtonSet
            config={{
              label: {
                negative: "Incomplete",
                positive: "Complete",
              },
              status: {
                negative: new_installation_status === "Incomplete",
                positive: new_installation_status === "Complete",
              },
              setStatus: {
                negative: () => {
                  setStatus(
                    "installation_status",
                    new_installation_status === "Incomplete"
                      ? "Not Started"
                      : "Incomplete"
                  );
                },
                positive: () => {
                  setStatus(
                    "installation_status",
                    new_installation_status === "Complete"
                      ? "Not Started"
                      : "Complete"
                  );
                },
              },
            }}
          />
          {includes(["Admin", "Area Manager"], user?.scheduling_role) && (
            <ButtonSet
              config={{
                label: {
                  negative: "Fail",
                  positive: "Pass",
                },
                status: {
                  negative: new_qi_status === "Fail",
                  positive: new_qi_status === "Pass",
                },
                setStatus: {
                  negative: () => {
                    setStatus(
                      "qi_status",
                      new_qi_status === "Fail" ? "Select Status" : "Fail"
                    );
                  },
                  positive: () => {
                    setStatus(
                      "qi_status",
                      new_qi_status === "Pass" ? "Select Status" : "Pass"
                    );
                  },
                },
              }}
            />
          )}
        </div>
        <div>
          <textarea
            className="border-2 border-black rounded-md shadow-inner p-2 w-full"
            onChange={(e: any) => setNotes(e.target.value)}
            placeholder={placeholder}
            rows={5}
            value={new_notes}
          />
        </div>
        <ChangeLog logs={change_log?.data} />
      </div>
    </div>
  );
}
