import { FormattedMessage } from "react-intl";
import { ReactComponent as CloseIcon } from "../assets/icons/Close.svg";
import { ReactComponent as CalendarAddIcon } from "../assets/icons/CalendarAdd.svg";

export default function QuickAction({ setOpen }: { setOpen: any }) {
  function Header() {
    return (
      <div className="sticky top-0 flex flex-row bg-white border-b border-ppi-border items-center min-h-[64px] font-semibold px-4">
        <div className="h-6 grow">
          <button
            className="text-ppi-red"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="absolute left-0 text-center text-lg pointer-events-none w-full">
          <FormattedMessage id="Quick Actions" />
        </div>
      </div>
    );
  }

  // function ItemHolder({ children, label }: { children: any; label: string }) {
  //   return (
  //     <div className="border border-ppi-border bg-white p-4 rounded-md">
  //       <div className="border-b border-ppi-border font-bold">
  //         <FormattedMessage id={label} />
  //       </div>
  //       <div>{children}</div>
  //     </div>
  //   );
  // }

  function ItemButton({ icon, label }: { icon: any; label: string }) {
    return (
      <div className="flex flex-row items-center border border-ppi-border bg-white p-6 rounded-xl text-xl">
        <div className="font-bold grow">
          <FormattedMessage id={label} />
        </div>
        <div>{icon}</div>
      </div>
    );
  }

  return (
    <div className="h-full overflow-y-auto">
      <Header />
      <div className="flex flex-col p-4 gap-4">
        <ItemButton label="Create Schedule" icon={<CalendarAddIcon />} />
      </div>
    </div>
  );
}
