import axios from "axios";
import { createContext, useContext, useState } from "react";
import {
  REACT_APP_TABLE_CONTACTS,
  REACT_APP_BACKEND_API,
} from "../context/variables";
import { User, UserFields } from "../context/types";

interface SettingsValues {
  loading: boolean;
  simulated_role: string | undefined;
  simulated_user: string | undefined;
  user_options: User[] | null;
  setSimulatedRole: (role: string | undefined) => void;
  setSimulatedUser: (record_id: string | undefined) => void;
}

export const SettingsContext = createContext<SettingsValues>({
  loading: false,
  simulated_role: undefined,
  simulated_user: undefined,
  user_options: null,
  setSimulatedRole: () => {},
  setSimulatedUser: () => {},
});

export const useSettings = () => useContext(SettingsContext);

const SettingsContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [simulated_role, setSimulatedRole] = useState<string | undefined>(
    undefined
  );
  const [simulated_user, setSimulatedUser] = useState<string | undefined>(
    undefined
  );
  const [user_options, setUserOptions] = useState<{
    id: string;
    data: User[];
  } | null>(null);

  const users_query = `{64.EX.${simulated_role}}`;

  async function getAndSetUserOptions(where: string) {
    setLoading(true);
    const { data: user_options } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      {
        table_id: REACT_APP_TABLE_CONTACTS,
        where,
        fields: UserFields,
      }
    );
    setUserOptions(user_options);
    setLoading(false);
  }
  const should_check = !user_options || user_options?.id !== users_query;
  should_check &&
    !!simulated_role &&
    !loading &&
    getAndSetUserOptions(users_query);
  return (
    <SettingsContext.Provider
      value={{
        loading,
        simulated_role,
        simulated_user,
        user_options: user_options?.data || null,
        setSimulatedRole,
        setSimulatedUser,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
