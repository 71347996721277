import { useEffect, useState } from "react";
import { useJob } from "./JobContext";
import { FormattedMessage } from "react-intl";
import { filter, kebabCase } from "lodash";
import AppLayout from "../Layout";
import PreLoader from "../PreLoader";
import { useSchedule } from "../Schedule/ScheduleContext";
import { useUI } from "../UIContext";
import { ReactComponent as DetailsIcon } from "../assets/icons/Details.svg";
import { ReactComponent as DetailsGreenIcon } from "../assets/icons/DetailsGreen.svg";
import DetailsDrawer from "../components/JobDetails";
import HeaderButtons from "../components/header_buttons";
import HeaderTitle from "../components/header_title";
import { Schedule } from "../context/types";

export default function RecordPage() {
  const { setLocation } = useUI();
  const { schedule } = useSchedule();
  const { job_id, loading, phase, project } = useJob();
  const [open, setOpen] = useState<boolean>(false);

  const records = filter(schedule, {
    job_id: parseInt(job_id),
    phase,
  }) as Schedule[];

  function FooterButton() {
    return (
      <div
        className="flex flex-col items-center text-center cursor-pointer"
        onClick={() => {
          const flip = !open;
          setOpen(flip);
        }}
      >
        <div>
          {open ? (
            <DetailsGreenIcon className="h-[21px]" />
          ) : (
            <DetailsIcon className="h-[21px]" />
          )}
        </div>
        <div
          className={`text-xs2 leading-xs2 ${
            open && "text-ppi-green"
          } font-medium`}
        >
          <FormattedMessage id="Details" />
        </div>
      </div>
    );
  }

  useEffect(() => {
    const record_id = records?.[0]?.record_id;
    if (records?.length === 1 && !!record_id) {
      setLocation(`/job/${job_id}/${kebabCase(phase)}/${record_id}`);
    }
  });

  return (
    <AppLayout
      details={
        <div
          className="relative bg-[#F4F5F8] flex-1 h-full w-full z-50 pt-[72px] pb-[88px] overflow-y-auto pointer-events-auto"
          style={{
            transform: open
              ? "translate3d(0vw, 0, 0)"
              : "translate3d(100vw, 0, 0)",
            transition: "transform .3s cubic-bezier(0, .52, 0, 1)",
          }}
        >
          <DetailsDrawer job_id={job_id} setOpen={setOpen} type="install" />
        </div>
      }
      footer={<FooterButton />}
      header={
        <>
          <HeaderTitle title={project?.mobile_app_header} />
          <div className="flex flex-row items-center justify-items-end px-1 translate-y-[2px] absolute w-full">
            <div className="grow">
              <button
                className="border-2 border-black text-white bg-ppi-blue font-bold px-2 py-1 rounded-md"
                onClick={() => setLocation("/schedule")}
              >
                <FormattedMessage id="Back" />
              </button>
            </div>
            <HeaderButtons />
          </div>
        </>
      }
    >
      {loading ? (
        <PreLoader text={`Loading Job ${job_id}...`} />
      ) : (
        <div className="flex flex-col gap-4 z-0"></div>
      )}
    </AppLayout>
  );
}
