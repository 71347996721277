import moment from "moment";

import DatePicker from "react-datepicker";
import "./react-datepicker.css";
import { useSchedule } from "./ScheduleContext";

export default function Calendar() {
  //  const [open_calendar, setOpenCalendar] = useState<boolean>(false);
  const { day_object, setDayObject, setOpenCalendar } = useSchedule();
  const date = new Date(day_object.clone().toDate());

  return (
    <div className="flex flex-col items-center pt-2">
      <DatePicker
        inline
        selected={date}
        className="bg-transparent text-center p-1"
        onChange={(date: any) => {
          console.log("CHANGING");
          setDayObject(moment(date));
          setOpenCalendar(false);
        }}
      />
    </div>
  );

  // return (
  //   <div className="flex flex-row items-center">
  //     {open_calendar ? (
  //       <div className="border border-slate-300 mx-auto shadow-inner rounded-md overflow-hidden">
  //         <DatePicker
  //           selected={date}
  //           className="bg-transparent text-center p-1"
  //           onChange={(date: any) => {
  //             setDayObject(moment(date));
  //             setOpenCalendar(false);
  //           }}
  //         />
  //       </div>
  //     ) : (
  //       <>
  //         <PreviousDayOverviewHeader />
  //         <CurrentDayOverviewHeader setOpenCalendar={setOpenCalendar} />
  //         <NextDayOverviewHeader />
  //       </>
  //     )}
  //   </div>
  // );
}
