import { some } from "lodash";
export default function JobContacts({ job }: { job: any }) {
  const {
    area_manager,
    bsr,
    builder,
    // crew,
    // ct_email,
    email_a_m,
    email_builder,
    email_bsr,
    email_s_s,
    email_w_m,
    // ft_crew,
    // ft_email,
    phone_a_m,
    phone_builder,
    phone_bsr,
    phone_s_s,
    phone_w_m,
    project_area_manager,
    project_a_m_email,
    project_a_m_phone,
    subdivision_supervisor,
    warranty_manager,
  } = job;

  const job_contacts = [
    {
      label: "Area Manager",
      name: area_manager || project_area_manager,
      email: email_a_m || project_a_m_email,
      phone: phone_a_m || project_a_m_phone,
    },
    {
      label: "Subdivision Superviser",
      name: subdivision_supervisor,
      email: email_s_s,
      phone: phone_s_s,
    },
    {
      label: "Warranty Manager",
      name: warranty_manager,
      email: email_w_m,
      phone: phone_w_m,
    },
    {
      label: "BSR",
      name: bsr,
      email: email_bsr,
      phone: phone_bsr,
    },
    {
      label: "Builder",
      name: builder,
      email: email_builder,
      phone: phone_builder,
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      {job_contacts.map((contact: any) => {
        const { email, label, name, phone } = contact;
        if (!some([email, name, phone])) return null;
        return (
          <div className="flex flex-row" key={label}>
            <div className="font-bold grow">{label}</div>
            <div className="flex flex-col text-right">
              <div>{name}</div>
              <div className="text-blue-600 underline">
                <a href={`mailto:${email}`}>{email}</a>
              </div>
              <div className="text-blue-600">
                <a href={`tel:${phone}`}>{phone}</a>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
//|| <span className="italic text-gray-400">no general notes</span>
