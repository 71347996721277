import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
import { useSchedule } from "./ScheduleContext";
import ResultsItem from "../components/ResultsItem";
import { Schedule } from "../context/types";
export default function DayDetail() {
  const { schedule_by_date } = useSchedule();
  const schedule = schedule_by_date;

  return (
    <div className="flex flex-col gap-4">
      {isEmpty(schedule_by_date) ? (
        <div className="flex flex-col items-center justify-center min-h-[400px]">
          <div className="font-semibold text-gray-300 italic">
            <FormattedMessage id="No Jobs for this Day" />
          </div>
        </div>
      ) : (
        <div className="flex flex-col text-ppi-light-text gap-2">
          {schedule.map((job: Schedule, idx: number) => {
            return <ResultsItem key={idx} item={job} type="schedule" />;
          })}
        </div>
      )}
    </div>
  );
}
