export default function SearchReducer(state: any, action: any) {
  function filterActions() {
    switch (action.type) {
      case "LOAD_FILTER":
        return action.filter;
      case "HANDLE_CHANGE":
        return {
          ...state,
          [action.filter.field]: action.filter.value,
        };
      case "CLEAR_FILTER":
        return action.filter;
      default:
        return state;
    }
  }
  let new_filter = filterActions();
  window.localStorage.setItem("lookup_filter", JSON.stringify(new_filter));
  return new_filter;
}
