import { useState } from "react";
import { useJob } from "../JobContext";
import { FormattedMessage } from "react-intl";
import { useUI } from "../../UIContext";
import axios from "axios";
import { Schedule } from "../../context/types";
import {
  REACT_APP_TABLE_SCHEDULES,
  REACT_APP_BACKEND_API,
} from "../../context/variables";

export default function JobHeader() {
  const { setLocation } = useUI();
  const { job, getAndSetJobs } = useJob();

  const [loading_submit, setLoading] = useState<boolean>(false);
  const { filter_submitted, record_id, type } = (job as Schedule) || {};

  const className = `${
    filter_submitted ? "bg-gray-400 text-slate-50" : "bg-ppi-green text-white"
  } border-2 border-black font-bold rounded-md py-1 px-3`;
  const text = filter_submitted ? (
    <FormattedMessage id="Submitted" />
  ) : (
    <FormattedMessage id={loading_submit ? "Submitting..." : "Submit"} />
  );
  return (
    <div className="flex flex-row items-center text-xl">
      <div className="font-bold grow">{type}</div>
      <div>
        <button
          className={className}
          disabled={loading_submit || filter_submitted}
          onClick={async () => {
            setLoading(true);
            await axios.post(REACT_APP_BACKEND_API + "/api/quickbase/update", {
              table_id: REACT_APP_TABLE_SCHEDULES,
              records: [{ record_id, filter_submitted: true }],
            });
            getAndSetJobs();
            setLoading(false);
            setLocation("/schedule");
          }}
        >
          {text}
        </button>
      </div>
    </div>
  );
}
