import { kebabCase } from "lodash";
import { ReactComponent as NoteIcon } from "../../../assets/icons/Note.svg";
import { ReactComponent as AlertRedIcon } from "../../../assets/icons/AlertRed.svg";
import { ReactComponent as AlertYellowIcon } from "../../../assets/icons/AlertYellow.svg";
import { ReactComponent as AlertGreenIcon } from "../../../assets/icons/AlertGreen.svg";
import { ReactComponent as BoxIcon } from "../../../assets/icons/Box.svg";
import { ReactComponent as CheckedBoxIcon } from "../../../assets/icons/CheckedBox.svg";
import ResultCard from "../card";
import ScheduleItemBottom from "./bottom";
import { Schedule } from "../../../context/types";
export default function ScheduleItem({ job }: { job: Schedule }) {
  const {
    builder,
    filter_submitted,
    job_id,
    lot,
    notes_office,
    phase,
    priority,
    record_id,
    subdivision,
    timeslot,
    type,
  } = job;

  function NoteCheck({ notes_office }: { notes_office?: string }) {
    if (!notes_office) return null;
    return (
      <div className="w-[18px]">
        <NoteIcon className="h-[18px] " />
      </div>
    );
  }

  function PriorityCheck({ priority }: { priority: string }) {
    const icons: { [key: string]: any } = {
      Low: <AlertGreenIcon className="h-[18px] " />,
      Medium: <AlertYellowIcon className="h-[18px] " />,
      High: <AlertRedIcon className="h-[18px] " />,
      Critical: <AlertRedIcon className="h-[10px] animate-ping" />,
    };
    if (!priority) return null;
    return <div className="w-[22px]">{icons[priority]}</div>;
  }

  function SubmittedCheck({ filter_submitted }: { filter_submitted: boolean }) {
    return (
      <div className="w-[15px] text-right">
        {filter_submitted ? (
          <CheckedBoxIcon className="h-[21px] " />
        ) : (
          <BoxIcon className="h-[21px] " />
        )}
      </div>
    );
  }

  return (
    <ResultCard link={`/job/${job_id}/${kebabCase(phase)}/${record_id}`}>
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="flex flex-row items-end gap-2 grow">
            <div className="text-[15px] leading-xs2 font-bold w-[40px] text-right">
              {type}
            </div>
            <div className="italic">{timeslot === "0" ? "" : timeslot}</div>
          </div>
          <div className="flex flex-row items-center">
            <NoteCheck notes_office={notes_office} />
            <PriorityCheck priority={priority} />
            <SubmittedCheck filter_submitted={filter_submitted} />
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <div className="text-xs2 leading-xs2 font-semibold w-[40px] text-right">
            {lot}
          </div>
          <div className="italic">{subdivision}</div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <div className="text-xs2 leading-xs2 font-semibold w-[40px] text-right">
            {job_id}
          </div>
          <div className="italic">{builder}</div>
        </div>
        <ScheduleItemBottom job={job} />
        {notes_office && (
          <div className="border-t-2 border-black mt-2 py-2 whitespace-pre-line">
            {notes_office}
          </div>
        )}
      </div>
    </ResultCard>
  );
}
