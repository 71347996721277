import { random } from "lodash";
export default function ScheduleSkeleton() {
  return (
    <div className="flex flex-col gap-4 px-4">
      <div className="flex flex-row items-center justify-between">
        <div className="skeleton h-8 w-32"></div>
        <div className="skeleton h-6 w-6"></div>
      </div>
      <div className="skeleton w-full h-8"></div>
      <div className="flex flex-col gap-3">
        {[1, 2, 3].map((idx: number) => {
          return (
            <div
              className="skeleton w-full h-[120px] card-skeleton"
              key={idx}
              style={{
                animation: `skeleton-loading linear ${random(1.5, 2.75)}s`,
                // opacity: loading ? 1 : 0,
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
}
