import { startsWith } from "lodash";
import { FormattedMessage } from "react-intl";
import ResultCard from "../card";
import { Project } from "../../../context/types";

export default function LookupItem({ project }: { project: Project }) {
  const {
    job_id,
    lot,
    phase = "none",
    street,
    subdivision,
    status: status_,
  } = project;

  const status = startsWith(status_, "<a style")
    ? "Schedule Not Submitted"
    : status_;

  return (
    <ResultCard link={`/project/${job_id}`}>
      <div className="flex flex-row">
        <div className="font-bold grow">{job_id}</div>
        <div>
          <FormattedMessage id={phase} />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="grow">{subdivision}</div>
        <div>
          {status ? (
            <FormattedMessage id={status} />
          ) : (
            <span className="text-gray-400 italic">
              <FormattedMessage id="not provided" />
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-row">
        <div>
          <FormattedMessage id="Lot" />:{" "}
          {lot || (
            <span className="text-gray-400 italic">
              <FormattedMessage id="not provided" />
            </span>
          )}
        </div>
        <div className="text-right grow">{street}</div>
      </div>
    </ResultCard>
  );
}
