import { ReactComponent as ForwardArrowCircleIcon } from "../../assets/icons/ForwardArrowCircle.svg";

export default function DetailsLink({
  label,
  onClick,
}: {
  label: any;
  onClick: any;
}) {
  return (
    <div
      className="flex flex-col bg-white px-4 py-2 border-2 border-black rounded-lg cursor-pointer"
      onClick={onClick}
    >
      <div className="flex flex-row text-sm items-center">
        <div className="font-bold grow">{label}</div>
        <button>
          <ForwardArrowCircleIcon />
        </button>
      </div>
    </div>
  );
}
