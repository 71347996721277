import { useState } from "react";
import axios from "axios";
import { compact, every, map, sample } from "lodash";
import { useJob } from "../JobContext";
import { FormattedMessage } from "react-intl";
import { translateRoom } from "../../context/functions";
import {
  REACT_APP_TABLE_PROJECT_LINE_ITEMS,
  REACT_APP_BACKEND_API,
} from "../../context/variables";
import { useUser } from "../../UserContext";

export default function Header() {
  const { user } = useUser();
  const { filtered_line_items, job, getAndSetLineItems, setUpdateLoading } =
    useJob();

  const [loading_submit_all, setLoading] = useState<boolean>(false);

  const sample_line_item = sample(filtered_line_items);

  const all_complete = every(filtered_line_items, {
    installation_status: "Complete",
  });

  const records = map(filtered_line_items, function (i: any) {
    const { record_id } = i;
    return {
      record_id,
      installation_status: "Complete",
    };
  });

  const changes = compact(
    map(filtered_line_items, function (i: any) {
      const { installation_status, record_id } = i;
      if (installation_status === "Complete") return null;
      return {
        date: new Date(),
        old_status: installation_status,
        new_status: "Complete",
        notes: "Completed All",
        related_schedule: job?.record_id, // Is this needed for QI?
        related_contact: user?.record_id,
        related_project_line_item: record_id,
      };
    })
  );

  function SubmitAllButton() {
    const className = `${
      all_complete ? "bg-gray-400 text-slate-50" : "bg-ppi-green text-white"
    } border-2 border-black font-bold rounded-md px-4 py-1`;
    const text = all_complete ? (
      <FormattedMessage id="Submitted" />
    ) : (
      <FormattedMessage
        id={loading_submit_all ? "Submitting..." : "Complete All"}
      />
    );
    return (
      <button
        className={className}
        disabled={loading_submit_all || all_complete}
        onClick={async () => {
          setLoading(true);
          setUpdateLoading(true);
          await axios.post(REACT_APP_BACKEND_API + "/api/quickbase/update", {
            table_id: REACT_APP_TABLE_PROJECT_LINE_ITEMS,
            changes,
            records,
          });
          getAndSetLineItems();
          setLoading(false);
          setUpdateLoading(false);
        }}
      >
        {text}
      </button>
    );
  }

  const room = sample_line_item?.room;
  return (
    <div className="flex flex-row items-center text-xl">
      <div className="font-bold grow">
        {room ? translateRoom(room) : <FormattedMessage id="Loading..." />}
      </div>
      <div>
        <SubmitAllButton />
      </div>
    </div>
  );
}
