import { FormattedMessage } from "react-intl";
import AppLayout from "../Layout";
//import BackButton from "../components/back_button";
import HeaderButtons from "../components/header_buttons";
import ItemHolder from "./item_holder";
import { getAddressLink } from "../context/functions";

export default function RaiseIssue({ setView }: { setView: any }) {
  let name = "Amberleah Morris",
    company = "Piedmont Plumbers Inc.",
    phone = "704-777-0048",
    email = "amberleah@processdistillery.com",
    address = "713 Burghley Circle Green, South Carolina";

  function HeaderBottom() {
    return (
      <div className="flex flex-row items-center border-b-2 border-black bg-white px-4 py-2 w-full">
        <div className="grow">
          <button
            className="bg-ppi-red text-white font-bold border-2 border-black rounded-md h-8 px-2"
            onClick={() => setView("accept")}
          >
            <FormattedMessage id="Undo" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <AppLayout
      header={
        <>
          <div className="font-bold text-xl text-center">
            <FormattedMessage id="Lien Release" />
          </div>
          <div className="flex flex-row justify-items-end px-1 translate-y-[2px] absolute">
            <button
              className="bg-ppi-blue text-white font-bold border-2 border-black rounded-md h-8 px-2"
              onClick={() => setView("accept")}
            >
              <FormattedMessage id="Back" />
            </button>
          </div>
          <HeaderButtons />
        </>
      }
      header_bottom={<HeaderBottom />}
    >
      <div className="px-4 pt-[50px]">
        <div className="flex flex-col gap-2">
          <ItemHolder label="Please Contact">
            <div className="flex flex-col gap-4">
              <div>
                <table className="ppi-table w-full">
                  <tbody>
                    <tr>
                      <td className="w-1/3">
                        <FormattedMessage id="Name" />
                      </td>
                      <td>{name}</td>
                    </tr>
                    <tr>
                      <td className="w-1/3">
                        <FormattedMessage id="Company" />
                      </td>
                      <td>{company}</td>
                    </tr>
                    <tr>
                      <td className="w-1/3">
                        <FormattedMessage id="Phone" />
                      </td>
                      <td className="text-ppi-blue">
                        <a href={`tel:+1${phone}`}>{phone}</a>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-1/3">
                        <FormattedMessage id="Email" />
                      </td>
                      <td className="text-ppi-blue">
                        <a href={`mailto:${email}`}>{email}</a>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-1/3">
                        <FormattedMessage id="Address" />
                      </td>
                      <td>
                        <a
                          href={getAddressLink(address)}
                          className="text-blue-600 underline"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {address}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ItemHolder>
        </div>
      </div>
    </AppLayout>
  );
}
