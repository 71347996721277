import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { filter, find, isEmpty, kebabCase } from "lodash";
import {
  Activity,
  ActivityData,
  ActivityFields,
  LineItem,
  LineItemData,
  LineItemFields,
  PhaseTicket,
  PhaseTicketFields,
  Project,
  ProjectData,
  ProjectFields,
} from "../../context/types";
import {
  REACT_APP_TABLE_PHASE_ACTIVITY,
  REACT_APP_TABLE_PHASE_TICKETS,
  REACT_APP_TABLE_PROJECTS,
  REACT_APP_TABLE_PROJECT_LINE_ITEMS,
  REACT_APP_BACKEND_API,
} from "../../context/variables";

type PhaseTicketData = {
  id: string;
  data: PhaseTicket[];
};

interface QIJobValues {
  activities: Activity[];
  filtered_line_items: any[];
  line_items: LineItem[] | null;
  job_id: string;
  job_phase: any | null;
  line_item: LineItem | null;
  loading: boolean;
  loading_activities: boolean;
  // loading_line_item: any | null;
  loading_line_items: boolean;
  loading_update: boolean;
  new_line_item: LineItem | null;
  phase: string;
  phase_ticket: PhaseTicket | null;
  phase_tickets: PhaseTicket[];
  project: Project | null;
  query: string;
  room?: string;
  // getAndSetItem: (line_item: any) => void;
  getAndSetLineItems: (query: string) => void;
  setLineItem: (line_item: any) => void;
  setLoading: (setting: boolean) => void;
  setLoadingUpdate: (setting: boolean) => void;
  updatePhaseActivity: (activities: any) => void;
  updateQIItem: (payload: any) => void;
}

export const QIJobContext = createContext<QIJobValues>({
  activities: [],
  filtered_line_items: [],
  line_items: null,
  job_id: "",
  job_phase: null,
  line_item: null,
  loading: false,
  loading_activities: false,
  // loading_line_item: null,
  loading_line_items: false,
  loading_update: false,
  new_line_item: null,
  phase: "",
  phase_ticket: null,
  phase_tickets: [],
  project: null,
  query: "",
  //  getAndSetItem: () => {},
  getAndSetLineItems: () => {},
  setLineItem: () => {},
  setLoading: () => {},
  setLoadingUpdate: () => {},
  updatePhaseActivity: () => {},
  updateQIItem: () => {},
});

export const useQIJob = () => useContext(QIJobContext);

export default function QIJobContextProvider({
  children,
  job_id,
  phase,
  room,
}: {
  children: any;
  job_id: string;
  phase?: string;
  room?: string;
}) {
  const [loading, setLoading] = useState<boolean>(false);

  const [activities, setActivities] = useState<ActivityData | null>();
  const [line_items, setLineItems] = useState<LineItemData | null>(null);
  const [line_item, setLineItem] = useState<LineItem | null>(null);
  const [phase_tickets, setPhaseTickets] = useState<PhaseTicketData | null>(
    null
  );
  const [project, setProject] = useState<ProjectData | null>(null);

  const [loading_activities, setLoadingActivities] = useState<boolean>(false);
  const [loading_line_items, setLoadingLineItems] = useState<boolean>(false);
  const [loading_phase_tickets, setLoadingPhaseTickets] =
    useState<boolean>(false);
  const [loading_update, setLoadingUpdate] = useState<boolean>(false);

  const getAndSetLineItems = useCallback(async (where: any) => {
    setLoadingLineItems(true);
    const { data } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      {
        table_id: REACT_APP_TABLE_PROJECT_LINE_ITEMS,
        where,
        fields: LineItemFields,
      }
    );
    setLineItems(data);
    setLoadingLineItems(false);
  }, []);

  async function updateQIItem(payload: any) {
    setLoadingUpdate(true);
    await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase/update",
      payload
    );
    await getAndSetLineItems(line_items?.id);

    // await getAndSetItem(line_item?.id);
    setLoadingUpdate(false);
  }

  const getAndSetActivities = useCallback(
    async (where: any) => {
      setLoadingActivities(true);
      const { data } = await axios.post(
        (REACT_APP_BACKEND_API || "") + "/api/quickbase",
        {
          table_id: REACT_APP_TABLE_PHASE_ACTIVITY,
          where: where || activities?.id,
          fields: ActivityFields,
        }
      );
      setActivities(data);
      setLoadingActivities(false);
    },
    [activities]
  );

  async function updatePhaseActivity(payload: any) {
    setLoadingActivities(true);
    await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase/update",
      payload
    );
    await getAndSetActivities(activities?.id);
    setLoadingActivities(false);
  }

  async function getAndSetProject(where: string) {
    setLoading(true);
    const { data } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      {
        table_id: REACT_APP_TABLE_PROJECTS,
        where,
        fields: ProjectFields,
      }
    );
    setProject(data);
    setLoading(false);
  }

  async function getAndSetPhaseTickets(where: string) {
    setLoadingPhaseTickets(true);
    const { data } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      {
        table_id: REACT_APP_TABLE_PHASE_TICKETS,
        where,
        fields: PhaseTicketFields,
      }
    );
    setPhaseTickets(data);
    setLoadingPhaseTickets(false);
  }

  const phase_names: { [key: string]: string } = {
    rough: "Rough",
    slab: "Slab",
    trim: "Trim",
    "water-sewer": "Water & Sewer",
  };

  const query = `{57.EX.${job_id}}AND{260.EX.true}AND{32.EX.${
    phase_names?.[phase as string]
  }}`;

  useEffect(() => {
    const notSearching = !query || !!loading_line_items || !!line_items;
    if (notSearching) return;
    getAndSetLineItems(query);
  });

  useEffect(() => {
    const query = `{6.EX.${job_id}}`;
    !loading &&
      !!job_id &&
      (!project || project?.id !== query) &&
      getAndSetProject(query);
  });

  useEffect(() => {
    const query = `{14.EX.${job_id}}`;
    !loading_phase_tickets &&
      !!job_id &&
      (!phase_tickets || phase_tickets?.id !== query) &&
      getAndSetPhaseTickets(query);
  });

  const phase_ticket =
    find(phase_tickets?.data, {
      phase: phase_names?.[phase as string],
    }) || null;

  useEffect(() => {
    const record_id = phase_ticket?.record_id;
    const phase_activity_query = `{6.EX.${record_id}}AND{80.EX.true}`;
    if (
      !loading_activities &&
      !!record_id &&
      phase_activity_query !== activities?.id
    ) {
      getAndSetActivities(phase_activity_query);
    }
  }, [activities, loading_activities, phase_ticket, getAndSetActivities]);

  const filtered_line_items = filter(line_items?.data, function (li: any) {
    if (room === "no-location") {
      return !li?.room;
    } else {
      return kebabCase(li?.room) === room;
    }
  });

  !isEmpty(filtered_line_items) &&
    !line_item &&
    setLineItem(filtered_line_items[0]);

  return (
    <QIJobContext.Provider
      value={{
        activities: activities?.data || [],
        filtered_line_items,
        job_id,
        job_phase: line_items,
        line_items: line_items?.data || null,
        line_item:
          find(line_items?.data, { record_id: line_item?.record_id }) || null,
        loading,
        loading_activities,
        // loading_line_item,
        loading_line_items,
        loading_update,
        new_line_item: line_item,
        phase: phase_names?.[phase as string] || "",
        phase_ticket,
        phase_tickets: phase_tickets?.data || [],
        project: project?.data?.[0] || null,
        query,
        room,
        //  getAndSetItem,
        getAndSetLineItems,
        setLineItem,
        setLoading,
        setLoadingUpdate,
        updatePhaseActivity,
        updateQIItem,
      }}
    >
      {children}
    </QIJobContext.Provider>
  );
}
