import AppLayout from "../Layout";
import { useUI } from "../UIContext";
import { useUser } from "../UserContext";
import { FormattedMessage } from "react-intl";

export default function Settings() {
  const { language, setLanguage } = useUI();
  const { user, setPin, setUser } = useUser();
  if (!user) return null;
  const { email, first_name, last_name, phone, scheduling_role } = user;

  function FullName() {
    if (!first_name && !last_name) return null;
    return (
      <p>
        {first_name} {last_name}
      </p>
    );
  }

  function UserInformation() {
    return (
      <div className="bg-white border-y border-ppi-border py-1 px-8">
        <div className="font-bold text-xl text-black">
          <FullName />
        </div>
        <div className="text-ppi-light-text text-xs leading-xs">{email}</div>
        <div className="text-ppi-light-text text-xs leading-xs">{phone}</div>
        <div className="text-ppi-light-text text-xs leading-xs font-bold">
          <FormattedMessage id={scheduling_role} />
        </div>
      </div>
    );
  }

  function UserControls() {
    return (
      <div
        className="bg-white border-ppi-border px-4"
        style={{ borderWidth: "0.5px" }}
      >
        <div className="flex flex-row items-center py-2 px-4 border-b border-ppi-border">
          <div className="grow">Español</div>
          <button
            className="bg-gray-200 rounded-full w-[46px] py-[2px] px-[3px]"
            style={{
              background: language === "en" ? "#e5e7eb" : "#16a34a",
              transition: "1s",
            }}
            onClick={() => {
              const flip = language === "en" ? "es" : "en";
              setLanguage(flip);
            }}
          >
            <div
              className="h-[20px] w-[20px] bg-white rounded-full"
              style={{
                transition: "1s",
                transform: `translateX(${language === "en" ? 0 : 20}px)`,
              }}
            ></div>
          </button>
        </div>
        <div className="py-2 px-4 text-red-600">
          <button
            onClick={() => {
              localStorage.removeItem("saved_user");
              setPin("");
              setUser(null);
            }}
          >
            <FormattedMessage id="Sign Out" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <AppLayout
      header={
        <div className="font-bold text-xl text-center">
          <FormattedMessage id="Account Settings" />
        </div>
      }
    >
      <div className="flex flex-col gap-4">
        <UserInformation />
        <UserControls />
      </div>
    </AppLayout>
  );
}
