import moment from "moment";
import { FormattedMessage } from "react-intl";
import { getAddressLink } from "../context/functions";
import AppLayout from "../Layout";
import HeaderButtons from "../components/header_buttons";
import ItemHolder from "./item_holder";
import BackButton from "../components/back_button";
import PreLoader from "../PreLoader";
import { LienRelease } from "../context/types";

export default function Release({
  lien_release,
  updating,
  updateLienRelease,
  setView,
}: {
  lien_release: LienRelease;
  updating: boolean;
  updateLienRelease: any;
  setView: any;
}) {
  function JobInformation() {
    const { builder, job_id, lot, phase, street, subdivision } =
      lien_release || {};
    return (
      <div className="flex flex-col gap-4">
        <div>
          <table className="ppi-table w-full">
            <tbody>
              <tr>
                <td className="w-1/3">
                  <FormattedMessage id="Job ID" />
                </td>
                <td>{job_id}</td>
              </tr>
              <tr>
                <td className="w-1/3">
                  <FormattedMessage id="Phase" />
                </td>
                <td>
                  {phase ? (
                    <FormattedMessage id={phase} />
                  ) : (
                    <span className="text-gray-400 italic">
                      <FormattedMessage id="not provided" />
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="w-1/3">
                  <FormattedMessage id="Subdivision" />
                </td>
                <td>{subdivision}</td>
              </tr>
              <tr>
                <td className="w-1/3">
                  <FormattedMessage id="Lot" />
                </td>
                <td>{lot}</td>
              </tr>
              {false && (
                <tr>
                  <td className="w-1/3">
                    <FormattedMessage id="Builder" />
                  </td>
                  <td>{builder}</td>
                </tr>
              )}
              <tr>
                <td className="w-1/3">
                  <FormattedMessage id="Address" />
                </td>
                <td>
                  <a
                    href={getAddressLink(street)}
                    className="text-blue-600 underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {street}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function Contractor() {
    const { contact, contact_address, contact_company, contact_email } =
      lien_release || {};
    return (
      <div className="flex flex-col gap-4">
        <div>
          <table className="ppi-table w-full">
            <tbody>
              <tr>
                <td className="w-1/3">
                  <FormattedMessage id="Name" />
                </td>
                <td>{contact}</td>
              </tr>
              <tr>
                <td className="w-1/3">
                  <FormattedMessage id="Company" />
                </td>
                <td>{contact_company}</td>
              </tr>
              <tr>
                <td className="w-1/3">
                  <FormattedMessage id="Email" />
                </td>
                <td className="text-blue-600 underline">
                  <a href={`mailto:${contact_email}`}>{contact_email}</a>
                </td>
              </tr>
              <tr>
                <td className="w-1/3">
                  <FormattedMessage id="Address" />
                </td>
                <td>
                  <a
                    href={getAddressLink(contact_address)}
                    className="text-blue-600 underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {contact_address}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function Release() {
    const {
      total = 0,
      contact = "____",
      date_created = "not provided",
      // lien_release: letter,
    } = lien_release || {};
    // if (letter) return <div>{letter}</div>;
    return (
      <div>
        In consideration of the receipt of the sum of
        <span className="font-bold"> ${total.toFixed(2)} </span>
        the undersigned, as a subcontractor for Piedmont Plumbers Inc.,
        <span className="font-bold"> {contact}</span>, has fully settled all
        terms and conditions of the Contract (as of{" "}
        <span className="font-bold">
          {moment(date_created).format("MM/DD/YYYY")}
        </span>
        ), as well as any other written or oral commitments, agreements, and/or
        understandings in connection with any jobs listed on the payroll report
        dated{" "}
        <span className="font-bold">
          {moment(date_created).format("MM/DD/YYYY")}
        </span>
        . The undersigned has been paid in full (or it will be paid in full via
        proceeds from this pay application) for the labor, services, and
        materials in connection with the Contract, including all work performed
        or any materials provided by its subcontractors, vendors, suppliers,
        materialmen, laborers, or other persons or entities. The undersigned has
        paid in full (or it will pay in full via proceeds from this pay
        application) all its subcontractors, vendors, suppliers, materialmen,
        laborers, and other person or entity providing services, labor, or
        materials to the Project; there are no outstanding claims, demands, or
        rights to liens against the undersigned, the Project, or the Owner in
        connection with the Contract on the part of any person or entity; and no
        claims, demands, or liens have been filed against the undersigned, the
        Project, or the Owner relating to the Contract. The undersigned releases
        and discharges Contractor/Vendor and Owner from all claims, demands, or
        causes of action (including all lien claims and rights) that the
        undersigned has, or might have, under any present or future law, against
        either of them in connection with the Contract. The undersigned hereby
        specifically waives and releases any lien or claim or right to lien in
        connection with the Contract against Contractor/Vendor, Owner, Owner’s
        property, and the Project, and also specifically waives, to the extent
        allowed by law, all liens, claims, or rights of lien in connection with
        the Contract by the undersigned’s subcontractors, materialmen, laborers,
        and all other persons or entities furnishing services, labor, or
        materials in connection with the Contract. The undersigned shall
        indemnify, defend, and hold harmless Contractor/Vendor and Owner from
        any action, proceeding, arbitration, claim, demand, lien, or right to
        lien relating to the Contract, and shall pay any costs, expenses, and/or
        attorneys’ fees incurred by Contractor/Vendor and/or Owner in connection
        therewith.
      </div>
    );
  }

  function HeaderBottom() {
    const record = {
      date_accepted: new Date(),
      signature: lien_release?.contact_signature,
      record_id: lien_release?.record_id,
    };
    if (updating) {
      return (
        <div className="flex flex-row items-center border-b-2 border-black bg-white px-4 py-2 w-full h-[50px] pointer-events-auto">
          <div className="font-bold grow">
            ${(lien_release?.total || 0).toFixed(2)}
          </div>
          <div className="font-bold">
            <FormattedMessage id="Updating..." />
          </div>
        </div>
      );
    }
    return (
      <div className="flex flex-row items-center border-b-2 border-black bg-white px-4 py-2 w-full h-[50px] pointer-events-auto">
        {lien_release?.status !== "Accepted" && (
          <>
            <div className="pointer-events-auto grow">
              <button
                className="bg-ppi-red text-white font-bold border-2 border-black rounded-md h-8 px-2"
                onClick={() => {
                  if (!lien_release?.contact_signature_date) {
                    setView("accept");
                  } else {
                    setView("issue");
                  }
                }}
              >
                <FormattedMessage id="Issue" />
              </button>
            </div>
            <div className="pointer-events-auto">
              <button
                className="bg-ppi-green text-white font-bold border-2 border-black rounded-md h-8 px-2"
                onClick={() => {
                  if (!lien_release?.contact_signature_date) {
                    setView("accept");
                  } else {
                    updateLienRelease({ ...record, status: "Accepted" });
                  }
                }}
              >
                <FormattedMessage id="Accept" />
              </button>
            </div>
          </>
        )}{" "}
        <div className="absolute left-0 font-bold text-center w-full pointer-events-none">
          ${(lien_release?.total || 0).toFixed(2)}
        </div>
      </div>
    );
  }

  return (
    <AppLayout
      header={
        <>
          <div className="font-bold text-xl text-center">
            <FormattedMessage id="Lien Release" />
          </div>
          <BackButton route="/notifications" />
          <HeaderButtons />
        </>
      }
      header_bottom={<HeaderBottom />}
    >
      <div className="px-4 pt-[50px]">
        {lien_release ? (
          <div className="flex flex-col gap-4">
            <ItemHolder label="Job Information">
              <JobInformation />
            </ItemHolder>
            <ItemHolder label="Contractor">
              <Contractor />
            </ItemHolder>
            <ItemHolder label="To all whom it may concern">
              <Release />
            </ItemHolder>
          </div>
        ) : (
          <PreLoader text="Loading lien release..." />
        )}
      </div>
    </AppLayout>
  );
}
