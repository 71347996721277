import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import axios from "axios";
import {
  compact,
  filter as filter_fn,
  join,
  omit,
  orderBy,
  toPairs,
} from "lodash";
import LookupFilterReducer from "./LookupReducer";
//import { useUser } from "../UserContext";
import {
  REACT_APP_TABLE_PROJECTS,
  REACT_APP_BACKEND_API,
} from "../context/variables";
//import { filterResults } from "../context/functions";
import { Project, ProjectFields as fields } from "../context/types";

const testingFilter = false;

const test_filter = {
  builder: "Meritage Homes",
  phase: "Rough",
  subdivision: "Chestnut Grove",
  order: {
    type: "lot",
    dir: "asc",
  },
};

const default_filter = testingFilter
  ? test_filter
  : {
      builder: undefined,
      job_id: undefined,
      lot: undefined,
      order: {
        type: "lot",
        dir: "asc",
      },
      phase: undefined,
      status: undefined,
      street: undefined,
      subdivision: undefined,
    };

type ProjectData = {
  id: string;
  data: Project[];
};

interface LookupValues {
  builders: any[];
  default_filter: any;
  filter: any;
  loading: boolean;
  projects: Project[] | null;
  showing: Project[];
  subdivisions: any[];
  terms: any[];
  clearFilter: () => void;
  // getAndSetProjects: (where: string) => void;
  loadFilter: (filter: any) => void;

  setFilter: (settings: any) => void;
}

export const LookupContext = createContext<LookupValues>({
  builders: [],
  default_filter,
  filter: default_filter,
  loading: false,
  projects: null,
  showing: [],
  subdivisions: [],
  terms: [],
  clearFilter: () => {},
  // getAndSetProjects: () => {},
  loadFilter: () => {},
  setFilter: () => {},
});

export const useLookup = () => useContext(LookupContext);

export default function LookupContextProvider({ children }: { children: any }) {
  //  const { setLocation } = useUI();
  // const { user } = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [projects, setProjects] = useState<ProjectData | null>(null);

  const [loading_builders, setLoadingBuilders] = useState<boolean>(false);
  const [builders, setBuilders] = useState<any | null>(null);

  const [loading_subdivisions, setLoadingSubdivisions] =
    useState<boolean>(false);
  const [subdivisions, setSubdivisions] = useState<any | null>(null);

  function setLookupFilter() {
    const initial_filter =
      window.localStorage.getItem("lookup_filter") || default_filter;
    return typeof initial_filter === "string"
      ? JSON.parse(initial_filter)
      : initial_filter;
  }

  const [filter, dispatch] = useReducer(LookupFilterReducer, setLookupFilter());

  function setFilter({ field, value }: { field: string; value: any }) {
    dispatch({
      type: "HANDLE_CHANGE",
      filter: {
        field,
        value,
      },
    });
  }

  function loadFilter(filter: any) {
    dispatch({
      type: "LOAD_FILTER",
      filter,
    });
  }

  function clearFilter() {
    dispatch({
      type: "LOAD_FILTER",
      filter: default_filter,
    });
  }

  async function getAndSetFilterOptions({
    fields,
    table_id,
    setLoading,
    setData,
  }: {
    fields: any;
    table_id: string;
    setLoading: any;
    setData: any;
  }) {
    let data;
    try {
      setLoading(true);
      const { data: data_ } = await axios.post(
        (REACT_APP_BACKEND_API || "") + "/api/quickbase",
        {
          table_id,
          fields,
        }
      );
      data = data_;
    } catch (err) {
      console.log("ERROR GETTING LOOKUP DATA", err);
    } finally {
      console.log("SETTING DATA", table_id, data);
      setData(data);
      setLoading(false);
    }
  }

  // function filterResults(items: any[]) {
  //   if (isEmpty(items)) return [];

  //   const filters = [
  //     { field: "status" },
  //     { field: "phase" },
  //     { field: "builder" },
  //     { field: "subdivision" },
  //     { field: "street", type: "test" },
  //     { field: "job_id", type: "test" },
  //     { field: "lot", type: "number" },
  //   ];

  //   return filters.reduce((filteredItems, item) => {
  //     const value = filter[item.field];
  //     if (!value) return filteredItems;

  //     if (item.type === "test") {
  //       const regex = new RegExp(value, "i");

  //       return filter_fn(filteredItems, (project: any) => {
  //         const test_result = regex.test(project[item.field]);
  //         return test_result;
  //       });
  //     }

  //     if (item.type === "number") {
  //       return filter_fn(filteredItems, { [item.field]: parseInt(value) });
  //     }

  //     return filter_fn(filteredItems, [item.field, value]);
  //   }, items);
  // }

  const showing_filtered = projects?.data || []; // filterResults(projects?.data || [], filter);

  const showing = orderBy(
    showing_filtered,
    [filter.order.type],
    [filter.order.dir]
  );

  if (!builders && !loading_builders) {
    getAndSetFilterOptions({
      fields: ["company"],
      table_id: "bsd43hiee",
      setData: setBuilders,
      setLoading: setLoadingBuilders,
    });
  }

  if (!subdivisions && !loading_subdivisions) {
    getAndSetFilterOptions({
      fields: ["subdivision_name"],
      table_id: "bsvats3vv",
      setData: setSubdivisions,
      setLoading: setLoadingSubdivisions,
    });
  }

  const terms = filter_fn(
    toPairs(omit(filter, "order")),
    (item: any[]) => !!item[1]
  );

  useEffect(() => {
    const { builder, lot, phase, status, street, subdivision, job_id } = filter;
    const where = join(
      compact([
        builder && `{15.EX."${builder}"}`,
        job_id && `{6.EX."${job_id}"}`,
        lot && `{8.EX."${lot}"}`,
        phase && `{418.CT."${phase}"}`,
        status && `{140.EX."${status}"}`,
        street && `{16.CT."${street}"}`,
        subdivision && `{13.EX."${subdivision}"}`,
      ]),
      "AND"
    );
    async function getAndSetProjects(where: string) {
      try {
        setLoading(true);
        const { data: projects } = await axios.post(
          (REACT_APP_BACKEND_API || "") + "/api/quickbase",
          {
            table_id: REACT_APP_TABLE_PROJECTS,
            where,
            fields,
          }
        );
        setProjects(projects);
        setLoading(false);
      } catch (err) {
        console.log("ERROR GETTING LOOKUP DATA", err);
      }
    }
    if (!!where && where !== projects?.id && !loading) {
      getAndSetProjects(where);
    }
    if (!where && !!projects) {
      setProjects(null);
    }
  }, [filter, loading, projects]);

  return (
    <LookupContext.Provider
      value={{
        builders: builders?.data || [],
        default_filter,
        filter,
        loading,
        showing,
        subdivisions: subdivisions?.data || [],
        projects: projects?.data || null,
        terms,
        clearFilter,
        //  getAndSetProjects,
        loadFilter,
        setFilter,
      }}
    >
      {children}
    </LookupContext.Provider>
  );
}
