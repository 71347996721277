import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  REACT_APP_TABLE_NOTIFICATIONS,
  REACT_APP_BACKEND_API,
} from "./context/variables";
import { useUser } from "./UserContext";
import {
  Notification,
  NotificationData,
  NotificationFields,
} from "./context/types";

export const NotificationsContext = createContext<{
  loading: boolean;
  notifications: Notification[];
}>({
  loading: false,
  notifications: [],
});

export const useNotifications = () => useContext(NotificationsContext);

export default function NotificationsContextProvider({
  children,
}: {
  children: any;
}) {
  const { user } = useUser();
  const [notifications, setNotifications] = useState<NotificationData | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);

  async function getAndSetNotifications(where: string) {
    setLoading(true);
    const { data: notifications_result } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      {
        table_id: REACT_APP_TABLE_NOTIFICATIONS,
        where,
        fields: NotificationFields,
      }
    );
    setNotifications(notifications_result);
    setLoading(false);
  }

  useEffect(() => {
    const notifications_query = `{10.EX.${user?.record_id}}AND{8.XEX.'Pending'}AND{20.EX.true}`;
    if (!!(notifications_query === notifications?.id || loading || !user))
      return;
    getAndSetNotifications(notifications_query);
  });

  return (
    <NotificationsContext.Provider
      value={{
        loading,
        notifications: notifications?.data || [],
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}
