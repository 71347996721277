import { FormattedMessage } from "react-intl";
import { useUser } from "../../UserContext";
import { useJob } from "../JobContext";
import { REACT_APP_TABLE_PROJECT_LINE_ITEMS } from "../../context/variables";

export default function StatusButtons({ setOpen }: { setOpen: any }) {
  const { user } = useUser();
  const {
    line_item,
    loading_update,
    job,
    new_line_item,
    setLineItem,
    updateLineItem,
  } = useJob();
  const { record_id, installation_status } = line_item || {};

  const base_changes = {
    date: new Date(),
    old_status: installation_status,
    related_schedule: job?.record_id,
    related_contact: user?.record_id,
    related_project_line_item: record_id,
  };

  async function setToIncomplete() {
    const new_status =
      installation_status === "Incomplete" ? "Not Started" : "Incomplete";
    const records = [
      {
        record_id,
        installation_status: new_status,
      },
    ];
    const changes = [{ ...base_changes, new_status }];
    updateLineItem({
      changes,
      records,
      table_id: REACT_APP_TABLE_PROJECT_LINE_ITEMS,
    });
  }

  function ReadyButtons() {
    return (
      <>
        <button
          className={`${
            installation_status === "Incomplete" ? "bg-red-600" : "bg-gray-400"
          } border-2 border-black text-white text-xl font-bold py-1 w-full rounded-md`}
          onClick={() => {
            false && setToIncomplete();
            setOpen("edit");
            setLineItem({
              ...new_line_item,
              installation_status: "Incomplete",
            });
          }}
        >
          <FormattedMessage id="Incomplete" />
        </button>
        <button
          className={`${
            installation_status !== "Complete" || loading_update
              ? "bg-gray-400 text-white"
              : "bg-ppi-green text-white"
          }
    border-2 border-black text-xl font-bold py-1 w-full rounded-md`}
          disabled={loading_update}
          onClick={async () => {
            const new_status =
              installation_status === "Complete" ? "Not Started" : "Complete";
            const records = [
              {
                record_id,
                installation_status: new_status,
              },
            ];
            const changes = [{ ...base_changes, new_status }];
            updateLineItem({
              changes,
              records,
              table_id: REACT_APP_TABLE_PROJECT_LINE_ITEMS,
            });
          }}
        >
          <FormattedMessage id={loading_update ? "Updating..." : "Complete"} />
        </button>
      </>
    );
  }

  return (
    <div
      className={`fixed bottom-[72px] left-0 bg-white border-t-2 border-black px-4 w-full z-50 opacity-100 pointer-events-auto`}
    >
      <div className="flex flex-row items-center gap-4 h-[72px]">
        <ReadyButtons />
      </div>
    </div>
  );
}
