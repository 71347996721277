import { useState } from "react";
import { kebabCase, sample } from "lodash";
import AppLayout from "../../../Layout";
import { ReactComponent as DetailsIcon } from "../../../assets/icons/Details.svg";
import { ReactComponent as DetailsGreenIcon } from "../../../assets/icons/DetailsGreen.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { useUI } from "../../../UIContext";
import { useQIJob } from "../QIJobContext";
import FooterButtons from "./buttons";
import ItemsList from "./items_list";
import DetailsDrawer from "../../../components/JobDetails";
import EditLineItem from "../../../components/EditLineItem";
import Header from "./header";
import HeaderButtons from "../../../components/header_buttons";
import ProgressBar from "./progress";
import { FormattedMessage } from "react-intl";
import QIRoomSkeleton from "./skeleton";
import HeaderTitle from "../../../components/header_title";

export default function RoomDisplay() {
  const { setLocation } = useUI();
  const {
    filtered_line_items,
    job_id,
    line_item,
    loading_line_items,
    new_line_item,
    phase,
    project,
    room: room_route,
    setLineItem,
    updateQIItem,
  } = useQIJob();

  const [new_notes, setNotes] = useState<string>("");

  const [open, setOpen] = useState<string | null>(null);

  const sample_line_item = sample(filtered_line_items);

  const loading = !!(!room_route || !sample_line_item);

  function FooterEditButton() {
    return (
      <div className="flex flex-row items-center justify-center absolute left-0 w-full pointer-events-none">
        <div
          className={`flex flex-row items-center justify-center ${
            open === "edit" ? "bg-ppi-green" : "bg-ppi-blue"
          } border-2 border-black rounded-full text-white h-[50px] w-[50px] cursor-pointer pointer-events-auto`}
          onClick={() => {
            setOpen(open === "edit" ? null : "edit");
          }}
        >
          <EditIcon className="h-[21px]" />
        </div>
      </div>
    );
  }

  function FooterButton() {
    return (
      <>
        {!open && <FooterButtons setOpen={setOpen} />}
        <div className="flex flex-row items-end justify-end grow">
          <div
            className="flex flex-col items-center text-center cursor-pointer w-1/5 pointer-events-auto"
            onClick={() => {
              setOpen(open === "details" ? null : "details");
            }}
          >
            <div>
              {open === "details" ? (
                <DetailsGreenIcon className="h-[21px]" />
              ) : (
                <DetailsIcon className="h-[21px]" />
              )}
            </div>
            <div
              className={`text-xs2 leading-xs2 ${
                open === "details" && "text-ppi-green"
              } font-medium`}
            >
              <FormattedMessage id="Details" />
            </div>
          </div>
        </div>
      </>
    );
  }

  function Body() {
    return (
      <div className="flex flex-col gap-8 z-0">
        <ItemsList />
      </div>
    );
  }
  const changed_to_incomplete =
    new_line_item?.installation_status === "Incomplete" &&
    new_line_item.installation_status !== line_item?.installation_status;
  const changed_to_fail =
    new_line_item?.qi_status === "Fail" &&
    new_line_item.qi_status !== line_item?.qi_status;
  let is_ready = (!changed_to_incomplete && !changed_to_fail) || !!new_notes;
  return (
    <AppLayout
      details={
        <div
          className="absolute bg-[#F4F5F8] flex-1 h-full w-full z-50 pointer-events-auto py-[72px] overflow-y-auto"
          style={{
            transform:
              open === "details"
                ? "translate3d(0vw, 0, 0)"
                : "translate3d(100vw, 0, 0)",
            transition: "transform .3s cubic-bezier(0, .52, 0, 1)",
          }}
        >
          <DetailsDrawer job_id={job_id} setOpen={setOpen} type="qi" />
        </div>
      }
      edit={
        <>
          <div
            className="fixed bg-[#F4F5F8] flex-1 w-full z-50 pointer-events-auto"
            style={{
              height: "calc(100% - 144px)",
              transform:
                open === "edit"
                  ? "translate3d(0, 72px, 0)"
                  : "translate3d(0, 100vh, 0)",
              transition: "transform .3s cubic-bezier(0, .52, 0, 1)",
            }}
          >
            {line_item && new_line_item && project ? (
              <EditLineItem
                qi
                is_ready={is_ready}
                job_info={project}
                line_item={line_item}
                new_line_item={new_line_item}
                new_notes={new_notes}
                setLineItem={setLineItem}
                setNotes={setNotes}
                setOpen={setOpen}
                updateLineItem={updateQIItem}
              />
            ) : (
              <>Loading...</>
            )}
          </div>
        </>
      }
      footer_edit={<FooterEditButton />}
      footer={<FooterButton />}
      header={
        <>
          <HeaderTitle title={project?.mobile_app_header} />

          <div className="flex flex-row items-center justify-items-end px-1  absolute w-full">
            <div className="grow">
              <button
                onClick={() => setLocation(`/qi/${job_id}/${kebabCase(phase)}`)}
                className="border-2 border-black text-white bg-ppi-blue font-bold px-2 py-1 rounded-md"
              >
                <FormattedMessage id="Back" />
              </button>{" "}
            </div>
            <HeaderButtons />
          </div>
        </>
      }
    >
      <div className="flex flex-col gap-4 px-4">
        {loading || loading_line_items ? (
          <QIRoomSkeleton />
        ) : (
          <>
            <Header />
            <ProgressBar />
            <Body />
          </>
        )}
      </div>
    </AppLayout>
  );
}
