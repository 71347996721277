import { FormattedMessage } from "react-intl";
import { ReactComponent as CloseWhiteIcon } from "../../assets/icons/CloseWhite.svg";
export default function SearchItems({
  terms,
  setFilter,
}: {
  terms: any[];
  setFilter: any;
}) {
  return (
    <div className="w-full overflow-x-scroll scrollbar-hidden">
      <div className="flex flex-row items-center gap-2 px-4">
        {terms.map((term: any[]) => {
          if (!term[1]) return null;
          const number_terms: { [key: string]: any } = {
            job_id: (
              <>
                <FormattedMessage id="Job ID" /> {term[1]}
              </>
            ),
            lot: (
              <>
                <FormattedMessage id="Lot" /> {term[1]}
              </>
            ),
          };
          return (
            <div key={term[0]}>
              <button
                className="bg-ppi-green text-white font-bold px-2 h-[36px] border-2 border-black rounded-md"
                onClick={() => {
                  setFilter({ field: term[0], value: undefined });
                }}
              >
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <CloseWhiteIcon className="h-4" />
                  </div>
                  <div className="text-xl whitespace-nowrap">
                    {number_terms[term[0]] || <FormattedMessage id={term[1]} />}
                  </div>
                </div>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
