import { FormattedMessage } from "react-intl";
import { useLookup } from "../../Lookup/LookupContext";

export default function FilterHeader({
  filter,
  has_changes,
  header,
  setOpen,
}: {
  filter: any;
  has_changes: boolean;
  header: string;
  setOpen: any;
}) {
  const { loadFilter } = useLookup();

  return (
    <div className="fixed w-full top-0 flex flex-row bg-white border-b-2 border-black items-center min-h-[48px] font-semibold px-4">
      <div className="flex flex-row items-center w-full">
        <div className="grow">
          <button
            className="bg-gray-400 text-white font-bold border-2 border-black rounded-md h-8 px-2"
            onClick={() => {
              setOpen(false);
            }}
          >
            <FormattedMessage id="Cancel" />
          </button>
        </div>
        <div>
          <button
            className={`${
              has_changes ? "bg-ppi-green" : "bg-gray-400"
            } text-white font-bold border-2 border-black rounded-md h-8 px-2`}
            disabled={!has_changes}
            onClick={() => {
              loadFilter(filter);
              setOpen(false);
            }}
          >
            <FormattedMessage id="Save" />
          </button>
        </div>
      </div>
      <div className="absolute left-0 text-center pointer-events-none w-full">
        <FormattedMessage id={header} />
      </div>
    </div>
  );
}
