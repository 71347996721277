import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import axios from "axios";
import { filter as filter_fn, omit, orderBy, toPairs } from "lodash";
import {
  default_filter as default_filter_,
  PhaseTicket,
  PhaseTicketFields as fields,
  QIFilter,
} from "../context/types";
import QIFilterReducer from "./QIReducer";
import { useUser } from "../UserContext";
import {
  REACT_APP_TABLE_PHASE_TICKETS,
  REACT_APP_BACKEND_API,
} from "../context/variables";
import { filterResults } from "../context/functions";

const testingFilter = false;

const test_filter = {
  builder: "Meritage Homes",
  job_id: "",
  lot: 1,
  order: {
    type: "lot",
    dir: "asc",
  },
  phase: "Rough",
  status: "",
  street: "",
  subdivision: "Chestnut Grove",
};

const default_filter = testingFilter ? test_filter : default_filter_;

type PhaseTicketData = {
  id: string;
  data: PhaseTicket[];
};

interface QIValues {
  default_filter: QIFilter;
  filter: QIFilter;
  loading: boolean;
  showing: any;
  // subdivision: string;
  // subdivision_names: any[];
  phase_tickets: PhaseTicket[] | null;
  terms: any[];
  clearFilter: (filter: QIFilter) => void;
  loadFilter: (filter: QIFilter) => void;
  setFilter: ({ field, value }: { field: string; value: any }) => void;
  // setSubdivision: (subdivision: string) => void;
  setPhaseTickets: (phase_tickets: PhaseTicketData | null) => void;
}

export const QIContext = createContext<QIValues>({
  default_filter,
  filter: default_filter,
  loading: false,
  showing: [],

  // subdivision: "",
  // subdivision_names: [],
  phase_tickets: null,
  terms: [],
  clearFilter: () => {},
  loadFilter: () => {},
  setFilter: () => {},
  // setSubdivision: () => {},
  setPhaseTickets: () => {},
});

export const useQI = () => useContext(QIContext);

export default function QIContextProvider({ children }: { children: any }) {
  const { user } = useUser();

  const [loading, setLoading] = useState<boolean>(false);
  // const [subdivision, setSubdivision] = useState<string>("");
  const [phase_tickets, setPhaseTickets] = useState<PhaseTicketData | null>(
    null
  );

  function setQIFilter() {
    const initial_filter =
      window.localStorage.getItem("qi_filter") || default_filter;
    return typeof initial_filter === "string"
      ? JSON.parse(initial_filter)
      : initial_filter;
  }

  const [filter, dispatch] = useReducer(QIFilterReducer, setQIFilter());

  function setFilter({ field, value }: { field: string; value: any }) {
    dispatch({
      type: "HANDLE_CHANGE",
      filter: {
        field,
        value,
      },
    });
  }

  function loadFilter(filter: any) {
    dispatch({
      type: "LOAD_FILTER",
      filter,
    });
  }

  // const subdivision_names = uniqBy(phase_tickets?.data, "subdivision");

  const showing_filtered = filterResults(phase_tickets?.data || [], filter);

  const showing = orderBy(
    showing_filtered,
    [filter.order.type],
    [filter.order.dir]
  );

  const where: { [key: string]: string } = {
    Admin: `{'105'.EX.'true'}`,
    "Area Manager": `{'105'.EX.'true'}AND{'226'.TV.'${user?.email}'}`,
  };

  async function getAndSetPhaseTickets() {
    try {
      setLoading(true);
      const payload = {
        table_id: REACT_APP_TABLE_PHASE_TICKETS,
        fields,
        where: where[user?.scheduling_role || "Area Manager"],
      };
      const { data: phase_tickets } = await axios.post(
        (REACT_APP_BACKEND_API || "") + "/api/quickbase",
        payload
      );
      setPhaseTickets(phase_tickets);
    } catch (err: any) {
      console.log("ERROR GETTING PHASE TICKETS", err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const not_ready =
      !!phase_tickets || loading || !user || user?.scheduling_role === "Crew";
    if (not_ready) return;
    getAndSetPhaseTickets();
  });

  const terms = filter_fn(
    toPairs(omit(filter, "order")),
    (item: any[]) => !!item[1]
  );

  function clearFilter() {
    dispatch({
      type: "LOAD_FILTER",
      filter: default_filter,
    });
  }

  return (
    <QIContext.Provider
      value={{
        default_filter,
        filter,
        loading,
        showing,
        //  subdivision,
        phase_tickets: phase_tickets?.data || null,
        //  subdivision_names,
        terms,
        clearFilter,
        loadFilter,
        setFilter,
        //  setSubdivision,
        setPhaseTickets,
      }}
    >
      {children}
    </QIContext.Provider>
  );
}
