import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { kebabCase, filter, find, includes, map, isEmpty } from "lodash";
import {
  REACT_APP_TABLE_PHASE_ACTIVITY,
  REACT_APP_TABLE_PROJECT_LINE_ITEMS,
  REACT_APP_TABLE_PROJECTS,
  REACT_APP_TABLE_SCHEDULES,
  REACT_APP_BACKEND_API,
} from "../context/variables";
import {
  Activity,
  ActivityData,
  ActivityFields,
  LineItem,
  LineItemData,
  LineItemFields,
  Project,
  ProjectData,
  ProjectFields,
  Schedule,
  ScheduleData,
  ScheduleFields,
} from "../context/types";
import { useUI } from "../UIContext";

interface JobValues {
  activities: Activity[];
  filtered_line_items: LineItem[];
  job_id: string;
  job: Schedule | null;
  jobs: Schedule[];
  line_item: LineItem | null;
  line_items: LineItem[] | null;
  loading: boolean;
  loading_activities: boolean;
  loading_update: boolean;
  new_line_item: any;
  phase: string;
  project: Project | null;
  record_id?: string;
  room?: string;
  getAndSetLineItems: () => void;
  getAndSetJobs: () => void;
  setLineItem: (line_item: any | null) => void;
  setLoading: (setting: boolean) => void;
  setUpdateLoading: (setting: boolean) => void;
  updateLineItem: (payload: any) => void;
  updatePhaseActivity: (activities: any) => void;
}

export const JobContext = createContext<JobValues>({
  activities: [],
  filtered_line_items: [],
  job_id: "",
  job: null,
  jobs: [],
  line_item: null,
  line_items: [],
  loading: false,
  loading_activities: false,
  loading_update: false,
  new_line_item: {},
  phase: "",
  project: null,
  getAndSetLineItems: () => {},
  getAndSetJobs: () => {},
  setLineItem: () => {},
  setLoading: () => {},
  setUpdateLoading: () => {},
  updateLineItem: () => {},
  updatePhaseActivity: () => {},
});

export const useJob = () => useContext(JobContext);

export default function JobContextProvider({
  children,
  job_id,
  phase,
  record_id,
  room,
}: {
  children: any;
  job_id: string;
  phase: string;
  record_id?: string;
  room?: string;
}) {
  // const { user } = useUser();
  const { phase_names } = useUI();

  const line_items_query = `{57.EX.${job_id}}AND{260.EX.true}AND{32.EX.${phase_names[phase]}}`;

  const jobs_query = `{78.EX.${job_id}}`; //AND{125.CT.${phase_names[phase]}}AND{3.EX.${record_id}}`;
  const project_query = `{6.EX.${job_id}}`;
  const [loading, setLoading] = useState<boolean>(false);
  const [loading_update, setUpdateLoading] = useState<boolean>(false);

  const [activities, setActivities] = useState<ActivityData | null>();
  const [loading_activities, setLoadingActivities] = useState<any | null>(null);

  const [jobs, setJobs] = useState<ScheduleData | null>(null);
  const [jobs_loading, setJobsLoading] = useState<boolean>(false);

  const [project, setProject] = useState<ProjectData | null>(null);
  const [project_loading, setProjectLoading] = useState<boolean>(false);

  const [line_items, setLineItems] = useState<LineItemData | null>(null);
  const filtered_line_items = filter(line_items?.data, function (l: any) {
    if (room === "no-location") {
      return !l?.room;
    } else {
      return kebabCase(l?.room) === room;
    }
  });

  const [line_item, setLineItem] = useState<LineItem | null>(null);

  async function getAndSetJobs() {
    setJobsLoading(true);
    const { data: schedule } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      {
        table_id: REACT_APP_TABLE_SCHEDULES,
        where: jobs_query,
        fields: ScheduleFields,
      }
    );
    setJobs(schedule);
    setJobsLoading(false);
  }

  async function getAndSetLineItems() {
    setLoading(true);
    console.log({ line_items_query });
    const { data } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      {
        table_id: REACT_APP_TABLE_PROJECT_LINE_ITEMS,
        where: line_items_query,
        fields: LineItemFields,
      }
    );
    setLineItems(data);
    setLoading(false);
  }

  async function getAndSetProject() {
    setProjectLoading(true);
    const { data } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      {
        table_id: REACT_APP_TABLE_PROJECTS,
        where: project_query,
        fields: ProjectFields,
      }
    );
    setProject(data);
    setProjectLoading(false);
  }

  const getAndSetActivities = useCallback(
    async (where: any) => {
      setLoadingActivities(true);
      const { data: new_activities } = await axios.post(
        (REACT_APP_BACKEND_API || "") + "/api/quickbase",
        {
          table_id: REACT_APP_TABLE_PHASE_ACTIVITY,
          where: where || activities?.id,
          fields: ActivityFields,
        }
      );
      setActivities(new_activities);
      setLoadingActivities(false);
    },
    [activities]
  );

  async function updatePhaseActivity(payload: any) {
    setLoadingActivities(true);
    await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase/update",
      payload
    );
    await getAndSetActivities(activities?.id);
    setLoadingActivities(false);
  }

  async function updateLineItem(payload: any) {
    setUpdateLoading(true);
    await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase/update",
      payload
    );
    await getAndSetLineItems();
    setUpdateLoading(false);
  }

  const phase_name = phase_names[phase];
  const jobs_ = jobs?.data || [];
  const job = find(jobs_, {
    phase: phase_name,
    record_id: parseInt(record_id || "undefined"),
  }) as Schedule;

  useEffect(() => {
    (!jobs || jobs_query !== jobs?.id) && !jobs_loading && getAndSetJobs();
  });

  useEffect(() => {
    (!project || project_query !== project?.id) &&
      !project_loading &&
      getAndSetProject();
  });

  useEffect(() => {
    (!line_items || line_items_query !== line_items?.id) &&
      !loading &&
      getAndSetLineItems();
  });

  useEffect(() => {
    if (!filtered_line_items || isEmpty(filtered_line_items)) return;
    const has_line_item = includes(
      map(filtered_line_items, "record_id"),
      line_item?.record_id
    );
    if (!line_item || !has_line_item) {
      setLineItem(filtered_line_items?.[0]);
    }
  }, [line_item, filtered_line_items]);

  useEffect(() => {
    const record_id = job?.related_phase_ticket;
    if (record_id) {
      const phase_activity_query = `{6.EX.${record_id}}AND{80.EX.true}`;
      if (
        !loading_activities &&
        !!record_id &&
        phase_activity_query !== activities?.id
      ) {
        getAndSetActivities(phase_activity_query);
      }
    }
  }, [activities, job, loading_activities, getAndSetActivities]);

  return (
    <JobContext.Provider
      value={{
        activities: activities?.data || [],
        filtered_line_items,
        job_id,
        job: job || null,
        jobs: jobs_,
        line_item:
          find(filtered_line_items, ["record_id", line_item?.record_id]) ||
          null, // Maintain by state?
        line_items: line_items?.data || null,
        loading,
        loading_activities,
        new_line_item: line_item,
        phase: phase_names[phase],
        project: project?.data?.[0] || null,
        record_id,
        room,
        loading_update,
        getAndSetLineItems,
        getAndSetJobs,
        setLineItem,
        setLoading,
        setUpdateLoading,
        updateLineItem,
        updatePhaseActivity,
      }}
    >
      {children}
    </JobContext.Provider>
  );
}
