import { FormattedMessage } from "react-intl";
import { useJob } from "../JobContext";
export default function ItemsListItem() {
  const { filtered_line_items, new_line_item, setLineItem } = useJob();

  return (
    <div className="flex flex-col gap-2 text-black text-xs2 leading-xs2 text-ppi-light-text">
      {filtered_line_items.map((line_item: any, idx: number) => {
        const {
          description,
          line_item_id,
          qi_status,
          quantity,
          record_id,
          installation_status,
        } = line_item;

        const display: { [key: string]: any } = {
          Complete: (
            <span className="text-green-500">
              <FormattedMessage id="Complete" /> ({quantity})
            </span>
          ),
          Incomplete: (
            <span className="text-red-600">
              <FormattedMessage id="Incomplete" /> ({quantity})
            </span>
          ),
          "Not Started": (
            <span className="text-gray-400">
              <FormattedMessage id="Not Started" /> ({quantity})
            </span>
          ),
        };

        const qi_display: { [key: string]: any } = {
          Pass: (
            <span className="text-green-500">
              <FormattedMessage id="Pass" /> ({quantity})
            </span>
          ),
          Fail: (
            <span className="text-red-600">
              <FormattedMessage id="Fail" /> ({quantity})
            </span>
          ),
        };

        const is_active = record_id === new_line_item?.record_id;

        return (
          <div
            key={idx}
            className={`${
              is_active ? "bg-blue-600 text-white" : "bg-white"
            } border-2 border-black rounded-md px-3 py-1`}
            onClick={() => setLineItem(line_item)}
          >
            <div className={`flex flex-col py-1`}>
              <div className="flex flex-row">
                <div className="font-bold grow">{line_item_id}</div>
                <div className="font-bold whitespace-nowrap">
                  {display[installation_status || "Not Started"]}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="line-clamp-2 grow">{description}</div>
                <div className="font-bold whitespace-nowrap">
                  {qi_display[qi_status || "Select Status"]}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
