import { useState } from "react";
import axios from "axios";
import { compact, every, map, sample } from "lodash";
import { FormattedMessage } from "react-intl";
import { useQIJob } from "../QIJobContext";
import { useUser } from "../../../UserContext";
import {
  REACT_APP_TABLE_PROJECT_LINE_ITEMS,
  REACT_APP_BACKEND_API,
} from "../../../context/variables";
import { translateRoom } from "../../../context/functions";
export default function Header() {
  const { user } = useUser();
  const { filtered_line_items, query, getAndSetLineItems, setLoadingUpdate } =
    useQIJob();
  const [loading_submit_all, setLoading] = useState<boolean>(false);
  const sample_line_item = sample(filtered_line_items);
  const all_passed = every(filtered_line_items, { qi_status: "Pass" });

  const records = map(filtered_line_items, function (i: any) {
    const { record_id } = i;
    return {
      record_id,
      qi_status: "Pass",
    };
  });

  const changes = compact(
    map(filtered_line_items, function (i: any) {
      const { qi_status, record_id } = i;
      if (qi_status === "Pass") return null;
      return {
        date: new Date(),
        old_status: qi_status,
        new_status: "Pass",
        notes: "Passed All",
        //  related_schedule: schedule_id, // Is this needed for QI?
        related_contact: user?.record_id,
        related_project_line_item: record_id,
      };
    })
  );

  function SubmitAllButton() {
    const className = `${
      all_passed ? "bg-gray-400 text-slate-50" : "bg-ppi-green text-white"
    } border-2 border-black font-bold rounded-md px-4 py-1`;
    const text = all_passed ? (
      <FormattedMessage id="Passed" />
    ) : (
      <FormattedMessage
        id={loading_submit_all ? "Submitting..." : "Pass All"}
      />
    );
    return (
      <button
        className={className}
        disabled={loading_submit_all || all_passed}
        onClick={async () => {
          setLoading(true);
          setLoadingUpdate(true);
          await axios.post(
            (REACT_APP_BACKEND_API || "") + "/api/quickbase/update",
            {
              table_id: REACT_APP_TABLE_PROJECT_LINE_ITEMS,
              changes,
              records,
            }
          );
          getAndSetLineItems(query);
          setLoading(false);
          setLoadingUpdate(false);
        }}
      >
        {text}
      </button>
    );
  }

  const room = sample_line_item?.room;
  return (
    <div className="flex flex-row items-center text-xl">
      <div className="font-bold grow">
        {room ? (
          translateRoom(room)
        ) : (
          <span className="text-ppi-skeleton">
            <FormattedMessage id="Loading..." />
          </span>
        )}
      </div>
      <div>
        <SubmitAllButton />
      </div>
    </div>
  );
}
