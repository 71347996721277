const {
  REACT_APP_DEVELOPMENT: _,
  REACT_APP_SC: SC,
  REACT_APP_PD_TABLE_CONTACTS,
  REACT_APP_PROD_TABLE_CONTACTS,
  REACT_APP_PD_TABLE_LIEN_RELEASES,
  REACT_APP_PROD_TABLE_LIEN_RELEASES,
  REACT_APP_PD_TABLE_NOTIFICATIONS,
  REACT_APP_PROD_TABLE_NOTIFICATIONS,
  REACT_APP_PD_TABLE_PHASE_ACTIVITY,
  REACT_APP_PROD_TABLE_PHASE_ACTIVITY,
  REACT_APP_PD_TABLE_PHASE_TICKETS,
  REACT_APP_PROD_TABLE_PHASE_TICKETS,
  REACT_APP_PD_TABLE_PHASES,
  REACT_APP_PROD_TABLE_PHASES,
  REACT_APP_PD_TABLE_PLI_LOG,
  REACT_APP_PROD_TABLE_PLI_LOG,
  REACT_APP_PD_TABLE_PROJECT_LINE_ITEMS,
  REACT_APP_PROD_TABLE_PROJECT_LINE_ITEMS,
  REACT_APP_PD_TABLE_PROJECTS,
  REACT_APP_PROD_TABLE_PROJECTS,
  REACT_APP_PD_TABLE_SCHEDULED_CREW,
  REACT_APP_PROD_TABLE_SCHEDULED_CREW,
  REACT_APP_PD_TABLE_SCHEDULES,
  REACT_APP_PROD_TABLE_SCHEDULES,
  REACT_APP_PD_TABLE_TRUCKS,
  REACT_APP_PROD_TABLE_TRUCKS
} = process.env;

export const REACT_APP_DEVELOPMENT = _;
export const REACT_APP_SC = SC === "true";

export const REACT_APP_TABLE_CONTACTS = _
  ? REACT_APP_PD_TABLE_CONTACTS
  : REACT_APP_PROD_TABLE_CONTACTS;

export const REACT_APP_TABLE_SCHEDULED_CREW = _
  ? REACT_APP_PD_TABLE_SCHEDULED_CREW
  : REACT_APP_PROD_TABLE_SCHEDULED_CREW;

export const REACT_APP_TABLE_SCHEDULES = _
  ? REACT_APP_PD_TABLE_SCHEDULES
  : REACT_APP_PROD_TABLE_SCHEDULES;

export const REACT_APP_TABLE_LIEN_RELEASES = _
  ? REACT_APP_PD_TABLE_LIEN_RELEASES
  : REACT_APP_PROD_TABLE_LIEN_RELEASES;

export const REACT_APP_TABLE_NOTIFICATIONS = _
  ? REACT_APP_PD_TABLE_NOTIFICATIONS
  : REACT_APP_PROD_TABLE_NOTIFICATIONS;

export const REACT_APP_TABLE_PHASE_ACTIVITY = _
  ? REACT_APP_PD_TABLE_PHASE_ACTIVITY
  : REACT_APP_PROD_TABLE_PHASE_ACTIVITY;

export const REACT_APP_TABLE_PHASE_TICKETS = _
  ? REACT_APP_PD_TABLE_PHASE_TICKETS
  : REACT_APP_PROD_TABLE_PHASE_TICKETS;

export const REACT_APP_TABLE_PHASES = _
  ? REACT_APP_PD_TABLE_PHASES
  : REACT_APP_PROD_TABLE_PHASES;

export const REACT_APP_TABLE_PLI_LOG = _
  ? REACT_APP_PD_TABLE_PLI_LOG
  : REACT_APP_PROD_TABLE_PLI_LOG;

export const REACT_APP_TABLE_PROJECT_LINE_ITEMS = _
  ? REACT_APP_PD_TABLE_PROJECT_LINE_ITEMS
  : REACT_APP_PROD_TABLE_PROJECT_LINE_ITEMS;

export const REACT_APP_TABLE_PROJECTS = _
  ? REACT_APP_PD_TABLE_PROJECTS
  : REACT_APP_PROD_TABLE_PROJECTS;

export  const REACT_APP_TABLE_TRUCKS = _
  ? REACT_APP_PD_TABLE_TRUCKS
  : REACT_APP_PROD_TABLE_TRUCKS;

export const REACT_APP_BACKEND_API = process.env.REACT_APP_BACKEND_API;
