import DetailsContextProvider from "./DetailsContext";
import DetailsContent from "./content";
export default function DetailsDrawer({
  setOpen,
  job_id,
  type,
}: {
  setOpen: any;
  job_id: string;
  type: string;
}) {
  return (
    <DetailsContextProvider job_id={job_id} type={type}>
      <DetailsContent setOpen={setOpen} type={type} />
    </DetailsContextProvider>
  );
}
//|| <span className="italic text-gray-400">no general notes</span>
