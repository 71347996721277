import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

import {
  REACT_APP_TABLE_TRUCKS,
  REACT_APP_BACKEND_API,
} from "../context/variables";

interface TrucksValues {
  loading: boolean;
  trucks: any[];
}

export const TrucksContext = createContext<TrucksValues>({
  loading: false,

  trucks: [],
});

export const useTrucks = () => useContext(TrucksContext);

export default function TrucksContextProvider({
  children,
}: //truck_no,
{
  children: any;
  truck_no?: string;
}) {
  const [trucks, setTrucks] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  async function getAndSetTrucks() {
    setLoading(true);
    const { data: trucks_result } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase",
      {
        table_id: REACT_APP_TABLE_TRUCKS,
        //where,
      }
    );
    setTrucks(trucks_result);
    setLoading(false);
  }

  useEffect(() => {
    if (!!(trucks || loading)) return;
    getAndSetTrucks();
  });

  return (
    <TrucksContext.Provider
      value={{
        loading,
        trucks: trucks?.data || [],
      }}
    >
      {children}
    </TrucksContext.Provider>
  );
}
