import { Fragment, useState } from "react";
import axios from "axios";
import { compact, filter, includes, kebabCase, orderBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { useUI } from "../../UIContext";
import DetailsItem from "./item";
import DetailsItemOpen from "./item_open";
import DetailsLink from "./link";
import { useDetails } from "./DetailsContext";
import JobInformation from "./job_information";
import JobContacts from "./contacts";
import { useUser } from "../../UserContext";
import { REACT_APP_BACKEND_API } from "../../context/variables";
import JobDetailsPreloader from "./loader";
export default function DetailsContent({
  setOpen,
  type,
}: {
  setOpen: any;
  type: string;
}) {
  const { phases: phases_, setLocation } = useUI();
  const { user } = useUser();
  const { phases, project } = useDetails();
  const [loading_box_plans_link, setLoadingBoxPlansLink] =
    useState<boolean>(false);
  const [loading_box_photos_link, setLoadingBoxPhotosLink] =
    useState<boolean>(false);
  const [box_plans_link, setBoxPlansLink] = useState<any | null>(null);
  const [box_photos_link, setBoxPhotosLink] = useState<any | null>(null);

  if (!project) return <JobDetailsPreloader loading={!project} />;

  const {
    box_folder_id_job_photos,
    box_folder_id_plans,
    job_id,
    project_box_folder_id,
  } = project;

  const job_phases =
    user?.scheduling_role !== "Crew"
      ? orderBy(
          filter(phases_, (p: any) => {
            const { hide_from_mobile_app, phase } = p;
            return includes(phases, phase) && !hide_from_mobile_app;
          }),
          ["sort_order"],
          ["asc"]
        ).map((item: any) => {
          const { phase } = item;
          const id = kebabCase(phase);

          const urls: { [key: string]: string } = {
            install: `/job/${job_id}/${id}`,
            lookup: `/project/${job_id}`,
            project: `/qi/${job_id}/${id}`,
            qi: `/qi/${job_id}/${id}`,
          };

          return {
            id,
            label: phase,
            url: urls[type || "qi"],
            type: "link",
          };
        })
      : [];

  const job_details = compact([
    {
      id: "job_info",
      label: "Job Information",
      component: <JobInformation job={project} />,
      type: "open",
    },
    {
      label: "Photos",
      link: box_photos_link,
      type: "link",
    },
    {
      label: "Plans",
      link: box_plans_link,
      type: "link",
    },
    false && {
      label: "Quality Inspection",
      url: `/qi/${job_id}`,
      type: "link",
    },
    user?.scheduling_role !== "Crew" &&
      false && {
        id: "purchase_orders",
        label: "Purchase Orders",
        component: <></>,
      },
    {
      id: "contacts",
      label: "Contacts",
      component: <JobContacts job={project} />,
    },
    ...job_phases,
  ]);

  async function getBoxPlansLink() {
    setLoadingBoxPlansLink(true);
    const { data: plans } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase/box_link",
      {
        folder_id: box_folder_id_plans || project_box_folder_id,
      }
    );
    setBoxPlansLink(plans?.shared_link?.url);
    setLoadingBoxPlansLink(false);
  }

  async function getBoxPhotosLink() {
    setLoadingBoxPhotosLink(true);
    const { data: photos } = await axios.post(
      (REACT_APP_BACKEND_API || "") + "/api/quickbase/box_link",
      {
        folder_id: box_folder_id_job_photos,
      }
    );
    setBoxPhotosLink(photos?.shared_link?.url);
    setLoadingBoxPhotosLink(false);
  }

  !loading_box_plans_link &&
    !box_plans_link &&
    box_folder_id_plans &&
    getBoxPlansLink();

  !loading_box_photos_link &&
    !box_photos_link &&
    box_folder_id_job_photos &&
    getBoxPhotosLink();

  return (
    <div
      className={`overflow-y-auto pt-[55px] ${
        type === "project" ? "pb-[100px]" : ""
      }`}
    >
      <div className="fixed top-[72px] flex flex-row w-full bg-white border-b-2 border-black items-center py-3 px-4">
        <div className="absolute left-0 px-4">
          <button
            className="bg-ppi-blue text-white font-bold border-2 border-black rounded-md px-2 py-1"
            onClick={() => setOpen(null)}
          >
            <FormattedMessage id="Close" />
          </button>
        </div>
        <div className="font-semibold text-center w-full">
          <FormattedMessage id="Job Navigation" />
        </div>
      </div>
      <div className={`flex flex-col gap-4 p-4`}>
        {job_details.map((item: any, idx: number) => {
          const { id, component, label, link, type, url } = item;
          const display: { [key: string]: any } = {
            default: <DetailsItem tab={{ id, label }}>{component}</DetailsItem>,
            link: (
              <DetailsLink
                label={label}
                onClick={() => {
                  link ? window.open(link, "_blank") : setLocation(url);
                }}
              />
            ),
            open: (
              <DetailsItemOpen tab={{ id, label }}>{component}</DetailsItemOpen>
            ),
          };
          return <Fragment key={idx}>{display[type || "default"]}</Fragment>;
        })}
      </div>
    </div>
  );
}
