import AppLayout from "./Layout";
import { useUI } from "./UIContext";

export default function Settings() {
  const { setLocation } = useUI();
  return (
    <AppLayout
      header={
        <div className="flex flex-col items-center justify-center h-full">
          <div className="font-bold text-xl">Page Not Found</div>
        </div>
      }
    >
      <div className="flex flex-col items-center justify-center font-bold h-full">
        <div className="text-5xl">404</div>
        <div>Error</div>
        <button
          className="text-blue-700 font-semibold"
          onClick={() => setLocation("/")}
        >
          Return Home
        </button>
      </div>
    </AppLayout>
  );
}
