import { FormattedMessage } from "react-intl";
import { useUI } from "../UIContext";

export default function BackButton({
  route,
  setView,
}: {
  route?: string;
  setView?: any;
}) {
  const { setLocation } = useUI();
  return (
    <div className="flex flex-row justify-items-end px-1 translate-y-[2px] absolute">
      <button
        className="bg-ppi-blue text-white font-bold border-2 border-black rounded-md h-8 px-2"
        onClick={route ? () => setLocation(route) : setView()}
      >
        <FormattedMessage id="Back" />
      </button>
    </div>
  );
}
