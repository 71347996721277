import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  REACT_APP_TABLE_PROJECTS,
  REACT_APP_BACKEND_API,
} from "../context/variables";
interface ProjectValues {
  job_id: string;
  project: any | null;
}

export const ProjectContext = createContext<ProjectValues>({
  job_id: "",
  project: null,
});

export const useProject = () => useContext(ProjectContext);

export default function ProjectContextProvider({
  children,
  job_id,
}: {
  children: any;
  job_id: string;
}) {
  const where = `{6.EX.${job_id}}`;
  const [loading, setLoading] = useState<boolean>(false);
  const [projects, setProjects] = useState<any | null>(null);

  async function getAndSetProjects(where: string) {
    const fields = [
      // "builder",
      // "job_id",
      // "lot",
      "mobile_app_header",
      // "phase",
      // "street",
      // "subdivision",
    ];
    try {
      setLoading(true);
      const { data } = await axios.post(
        (REACT_APP_BACKEND_API || "") + "/api/quickbase",
        {
          table_id: REACT_APP_TABLE_PROJECTS,
          fields,
          where,
        }
      );
      console.log("SETTING PROJECT", data);
      setProjects(data);
    } catch (err) {
      console.log("ERROR GETTING PROJECT DATA", err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!!projects || !!loading) return;
    getAndSetProjects(where);
  });

  return (
    <ProjectContext.Provider
      value={{
        job_id,
        project: projects?.data?.[0] || null,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}
