import { FormattedMessage } from "react-intl";
import ToiletIcon from "../../../assets/icons/toilet.png";
import TubIcon from "../../../assets/icons/tub.png";
import SinkIcon from "../../../assets/icons/sink.png";
import WaterHeaterIcon from "../../../assets/icons/water-heater.png";
import { getAddressLink } from "../../../context/functions";
import { Schedule } from "../../../context/types";

export default function ScheduleItemBottom({ job }: { job: Schedule }) {
  const { address, county, of_sinks, of_toilets, of_tubs, of_water_heaters } =
    job;

  return (
    <div className="flex flex-row gap-3 items-end">
      <div className="flex flex-col grow">
        <div className="leading-xs2">
          {address ? (
            <a
              href={getAddressLink(address)}
              className="text-blue-600 underline"
              target="_blank"
              rel="noreferrer"
            >
              {address}
            </a>
          ) : (
            <span className="text-gray-400 italic">
              <FormattedMessage id="not provided" />
            </span>
          )}
        </div>
        <div>{county}</div>
      </div>
      <div className="flex flex-row gap-2">
        {[
          { icon: ToiletIcon, value: of_toilets },
          { icon: SinkIcon, value: of_sinks },
          { icon: TubIcon, value: of_tubs },
          { icon: WaterHeaterIcon, value: of_water_heaters },
        ].map(({ icon, value }: any, idx: number) => {
          if (!value) return null;
          return (
            <div key={idx} className="flex flex-col gap-1 items-center">
              <div>
                <img src={icon} alt={icon} className="w-[25px]" />
              </div>
              <div>{value || <span className="text-gray-400/80">0</span>}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
