import { useState } from "react";
import axios from "axios";
//import { filter, kebabCase } from "lodash";
import { useQIJob } from "../QIJobContext";
import { FormattedMessage } from "react-intl";
import { every, find, map } from "lodash";
import {
  REACT_APP_TABLE_PROJECT_LINE_ITEMS,
  REACT_APP_BACKEND_API,
} from "../../../context/variables";
import { useUser } from "../../../UserContext";
import { useSchedule } from "../../../Schedule/ScheduleContext";
import { Schedule } from "../../../context/types";
//import { translateRoom } from "../../../context/functions";

export default function Header() {
  const { user } = useUser();
  const { schedule } = useSchedule();
  const { job_id, line_items, phase, query, getAndSetLineItems } = useQIJob();
  const [loading_submit_all, setLoading] = useState<boolean>(false);

  const schedule_id = (
    find(schedule, {
      job_id: parseInt(job_id),
      phase,
    }) as Schedule
  )?.record_id;

  const records = map(line_items, function (i: any) {
    const { record_id } = i;
    return {
      record_id,
      qi_status: "Pass",
    };
  });

  const changes = map(line_items, function (i: any) {
    const { qi_status, record_id } = i;
    return {
      date: new Date(),
      old_status: qi_status,
      new_status: "Pass",
      related_schedule: schedule_id, // Is this needed for QI?
      related_contact: user?.record_id,
      related_project_line_item: record_id,
    };
  });

  const all_complete = every(line_items, { qi_status: "Pass" });

  function SubmitAllButton() {
    const className = `${
      all_complete ? "bg-gray-400 text-slate-50" : "bg-ppi-green text-white"
    } border-2 border-black font-bold rounded-md px-4 py-1`;
    const text = all_complete ? (
      <FormattedMessage id="Passed" />
    ) : (
      <FormattedMessage
        id={loading_submit_all ? "Submitting..." : "Pass All"}
      />
    );
    return (
      <button
        className={className}
        disabled={loading_submit_all || all_complete}
        onClick={async () => {
          setLoading(true);
          await axios.post(
            (REACT_APP_BACKEND_API || "") + "/api/quickbase/update",
            {
              table_id: REACT_APP_TABLE_PROJECT_LINE_ITEMS,
              changes,
              records,
            }
          );
          getAndSetLineItems(query);
          setLoading(false);
        }}
      >
        {text}
      </button>
    );
  }

  return (
    <div className="flex flex-row items-center text-xl">
      <div className="font-bold grow">
        <FormattedMessage id={phase} /> <FormattedMessage id="QI" />
      </div>
      <div>
        <SubmitAllButton />
      </div>
    </div>
  );
}
