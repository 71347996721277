import moment from "moment";
import { orderBy } from "lodash";
import { FormattedMessage } from "react-intl";
import HeaderButtons from "../components/header_buttons";
import { useNotifications } from "../NotificationsContext";
import AppLayout from "../Layout";
import { useUI } from "../UIContext";
import { Notification } from "../context/types";
import NotificationItem from "./item";

export default function Notifications() {
  const { setLocation } = useUI();
  const { notifications } = useNotifications();

  return (
    <AppLayout
      header={
        <>
          <div className="font-bold text-xl text-center">
            <FormattedMessage id="Notifications" />
          </div>
          <div className="flex flex-row justify-items-end px-1 translate-y-[2px] absolute">
            <button
              className="bg-ppi-blue text-white font-bold border-2 border-black rounded-md h-8 px-2"
              onClick={() => setLocation("/")}
            >
              <FormattedMessage id="Back" />
            </button>
          </div>
          <HeaderButtons />
        </>
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-3 px-4 text-xs2 text-ppi-light-text">
          {orderBy(
            notifications,
            (n: Notification) => {
              return moment(n.date_created);
            },
            ["desc"]
          ).map((notification: Notification, idx: number) => {
            return <NotificationItem key={idx} notification={notification} />;
          })}
        </div>
      </div>
    </AppLayout>
  );
}
