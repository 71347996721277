import { groupBy, map, sum, take } from "lodash";
import { useQIJob } from "../QIJobContext";

export default function JobProgress() {
  const { filtered_line_items: line_items } = useQIJob();
  const total = line_items?.length;

  const line_items_breakdown = groupBy(line_items, "qi_status");

  if (!total) return null;

  const complete_count = line_items_breakdown["Pass"]?.length || 0;
  const incomplete_count = line_items_breakdown["Fail"]?.length || 0;
  const not_started_count = line_items_breakdown["Select Status"]?.length || 0;
  const item_array = [
    {
      backgroundColor: "#2CA01C",
      width: complete_count ? complete_count / total : 0,
    },
    {
      backgroundColor: "#FF0000",
      width: incomplete_count ? incomplete_count / total : 0,
    },
    {
      backgroundColor: "#FFD600",
      width: not_started_count ? not_started_count / total : 0,
    },
  ];

  function getStyle(idx: number, style: any) {
    const { backgroundColor } = style;
    switch (true) {
      case idx === 0:
        return {
          backgroundColor,
          width: `${sum(map(take(item_array), "width")) * 100}%`,
          zIndex: 2,
        };
      case idx === 1:
        return {
          backgroundColor,
          width: `${sum(map(take(item_array, 2), "width")) * 100}%`,
          zIndex: 1,
        };
      case idx === 2:
        return {
          backgroundColor,
          width: `${sum(map(item_array, "width")) * 100}%`,
          zIndex: 0,
        };
    }
  }

  function ProgressBar({ idx, style }: { idx: number; style?: any }) {
    if (!style.width) return null;
    return (
      <div
        className={`absolute border-2 border-black rounded-md h-8`}
        style={getStyle(idx, style)}
      />
    );
  }

  return (
    <div className="relative flex flex-row items-center h-8 outline-2 outline-offset-0 outline-black rounded-md">
      {item_array.map((style: any, idx: number) => {
        return <ProgressBar key={idx} idx={idx} style={style} />;
      })}
    </div>
  );
}
