import { useIntl } from "react-intl";

export default function InputComponent({
  id,
  items,
  label,
  options,
  value,
  handleChange,
}: {
  id: string;
  items: any[];
  label: string;
  options?: any[];
  value: any;
  handleChange: any;
}) {
  const intl = useIntl();
  const translated_label = intl.formatMessage({ id: label });
  return (
    <div key={id} className="flex flex-col gap-1">
      <div>
        <input
          className="font-bold text-xs2 h-10 w-full border-2 border-black rounded-md px-4"
          autoComplete="off"
          onChange={(e: any) => {
            handleChange(id, e.target.value);
          }}
          placeholder={translated_label}
          type="text"
          name={id}
          list={`${id}_list`}
        />
        <datalist id={`${id}_list`}>
          {items.map((item: any) => {
            return (
              <option className="" key={item} value={item}>
                {item}
              </option>
            );
          })}
        </datalist>
      </div>
    </div>
  );
}
