import { constant, orderBy, random, times } from "lodash";
import { useLookup } from "./LookupContext";
import SearchTerms from "../components/Filter/terms";
import ResultsItem from "../components/ResultsItem";
import { Project } from "../context/types";

export default function LookupResults() {
  const { filter: filter_, loading, showing, terms, setFilter } = useLookup();
  const { dir, type } = filter_.order;

  function calculateNumElements() {
    const screenHeight = window.innerHeight;
    const availableHeight = screenHeight - 144; // Subtracting 144px
    const elementHeightWithGap = 60 + 16; // Element height + gap
    const calculatedNumElements = Math.floor(
      availableHeight / elementHeightWithGap
    );
    return calculatedNumElements;
  }

  return (
    <div className="flex flex-col gap-4 pb-[72px] overflow-y-touch">
      <SearchTerms terms={terms} setFilter={setFilter} />
      <div className="flex flex-col gap-3 px-4 text-ppi-light-text">
        {loading ? (
          <>
            {times(calculateNumElements(), constant(null)).map(
              (job: any, idx: number) => {
                return (
                  <div
                    className="skeleton w-full h-[60px] card-skeleton"
                    key={idx}
                    style={{
                      animation: `skeleton-loading linear ${random(
                        0.9,
                        1.75
                      )}s`,
                      opacity: loading ? 1 : 0,
                    }}
                  ></div>
                );
              }
            )}
          </>
        ) : (
          <>
            {orderBy(showing, [type], [dir]).map(
              (project: Project, idx: number) => {
                return <ResultsItem key={idx} item={project} type="lookup" />;
              }
            )}
          </>
        )}
      </div>
    </div>
  );
}
