import { FormattedMessage } from "react-intl";

export default function EditItemButtonSet({ config }: { config: any }) {
  const { label, status, setStatus } = config;
  return (
    <div className="flex flex-row gap-2">
      <button
        className={`${
          status.negative ? "bg-red-600" : "bg-gray-400"
        } text-white text-xl font-bold py-1 w-full border-2 border-black rounded-lg`}
        onClick={setStatus.negative}
      >
        <FormattedMessage id={label.negative} />
      </button>
      <button
        className={`${
          status.positive ? "bg-ppi-green text-white" : "bg-gray-400 text-white"
        }
    } text-xl font-bold py-1 w-full border-2 border-black rounded-lg`}
        onClick={setStatus.positive}
      >
        <FormattedMessage id={label.positive} />
      </button>
    </div>
  );
}
