import { filter, isEmpty } from "lodash";
import { useQI } from "./QIContext";
import ResultsTypeHolder from "./result_types";
import { FormattedMessage } from "react-intl";
import { default_filter } from "../context/types";
import { ReactComponent as CloseWhiteIcon } from "../assets/icons/CloseWhite.svg";

export default function QIQueueResults() {
  const { filter: filter_, showing, terms, loadFilter } = useQI();

  function SearchItems() {
    return (
      <div className="w-full overflow-x-scroll scrollbar-hidden">
        <div className="flex flex-row items-center gap-2 px-4">
          {terms.map((term: any[]) => {
            return (
              <div key={term[0]}>
                <button
                  className="bg-ppi-green text-white font-bold px-2 h-[36px] border-2 border-black rounded-md"
                  onClick={() => {
                    // const new_filter = omit(filter_, term[0]);
                    (filter_ as { [key: string]: any })[term[0]] =
                      default_filter[term[0]];
                    loadFilter(filter_);
                  }}
                >
                  <div className="flex flex-row items-center gap-2">
                    <div>
                      <CloseWhiteIcon className="h-4" />
                    </div>
                    <div className="text-xl whitespace-nowrap">
                      {term[0] === "lot" ? (
                        <>
                          <FormattedMessage id="Lot" /> {term[1]}
                        </>
                      ) : (
                        <FormattedMessage id={term[1]} />
                      )}
                    </div>
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {!isEmpty(terms) && <SearchItems />}
      <ResultsTypeHolder
        label="Unwalked"
        results={filter(showing, ["walk_status", "Unwalked"])}
      />
      <ResultsTypeHolder
        label="Walked"
        results={filter(showing, ["walk_status", "Walked"])}
      />
    </div>
  );
}
