import { useQI } from "../../QIQueue/QIContext";
import { useState } from "react";
import { groupBy, keys, map, orderBy, random, take } from "lodash";
import KPICardHolder from "./card_holder";
import KPICardHolderSkeleton from "./card_holder_skeleton";

export default function TopSubdivisionsKPI() {
  const { loading, phase_tickets } = useQI();
  const [view_all] = useState<boolean>(false);
  const grouped = groupBy(phase_tickets, "subdivision");
  const list = orderBy(
    map(keys(grouped), (subdivision: string) => {
      const item = grouped[subdivision];
      return {
        name: subdivision,
        builder: item?.[0]?.builder,
        length: item.length,
      };
    }),
    "length",
    "desc"
  );
  if (loading) {
    return (
      <KPICardHolderSkeleton>
        <div className="flex flex-col gap-4">
          {["1", "2", "3"].map((loader: string) => {
            return (
              <div key={loader} className="flex flex-row gap-4">
                <div className="h-10 w-10 rounded-full bg-[#F4F5F8] rounded-full"></div>
                <div
                  className={`my-1 h-8 bg-[#F4F5F8] rounded-md`}
                  style={{
                    animation: `skeleton-loading linear ${random(0.9, 1.75)}s`,
                    width: random(180, 210),
                  }}
                ></div>
              </div>
            );
          })}
        </div>
      </KPICardHolderSkeleton>
    );
  }
  return (
    <KPICardHolder title="Top Subdivisions">
      <div className="flex flex-col gap-4">
        {(view_all ? list : take(list, 3)).map((item: any) => {
          const { name, builder, length } = item;
          return (
            <div key={name} className="flex flex-row items-center gap-4">
              <div className="flex flex-col items-center justify-center h-10 w-10 bg-ppi-blue text-white border border-black rounded-full">
                <div className="font-bold">{length}</div>
              </div>
              <div className="flex flex-col">
                <div className="font-bold">{name}</div>
                <div className="text-gray-400 text-xs2">{builder}</div>
              </div>
            </div>
          );
        })}
      </div>
      {false && (
        <div className="text-right text-xs2">
          {view_all ? "Close" : "View All"}
        </div>
      )}
    </KPICardHolder>
  );
}
