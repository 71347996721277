export default function DetailsItem({
  children,
  tab,
}: {
  children: any;
  tab: any;
}) {
  const { label } = tab;
  return (
    <div className="flex flex-col bg-white px-6 py-2 border-2 border-black rounded-xl">
      <div className="border-b-2 border-black pb-2 font-bold text-center">
        {label}
      </div>
      <div className="text-xs2 mt-2 py-3">{children}</div>
    </div>
  );
}
