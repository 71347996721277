import moment from "moment";
import {
  compact,
  filter,
  isEmpty,
  join,
  map,
  replace,
  split,
  sum,
  trimEnd,
  uniq,
} from "lodash";
import { FormattedMessage } from "react-intl";
import { Order } from "./types";

export function filterResults(items: any[], filter_: any) {
  if (isEmpty(items)) return [];

  const filters = [
    { field: "status" },
    { field: "phase" },
    { field: "builder" },
    { field: "subdivision" },
    { field: "street", type: "test" },
    { field: "job_id", type: "test" },
    { field: "lot" },
  ];

  return filters.reduce((filteredItems, item) => {
    const value = filter_[item.field];
    if (!value) return filteredItems;

    if (item.type === "test") {
      const regex = new RegExp(value, "i");

      return filter(filteredItems, (project: any) => {
        const test_result = regex.test(project[item.field]);
        return test_result;
      });
    }

    if (item.type === "number") {
      return filter(filteredItems, { [item.field]: parseInt(value) });
    }

    return filter(filteredItems, [item.field, value]);
  }, items);
}

export function getAddressLink(address: string) {
  return `https://www.google.com/maps/dir/?api=1&destination=${replace(
    address,
    /\s+/g,
    "+"
  )}
`;
}

export function formatTimestamp(timestamp: string) {
  const now = moment();
  const date = moment(timestamp);

  if (now.diff(date, "days") === 0) {
    return date.format("h:mmA");
  } else if (now.diff(date, "days") === 1) {
    return "Yesterday, " + date.format("h:mmA");
  } else if (now.diff(date, "weeks") === 0) {
    return date.format("dddd, h:mmA");
  } else if (now.diff(date, "years") === 0) {
    return date.format("MMM D, h:mmA");
  } else {
    return date.format("MMM D, YYYY, h:mmA");
  }
}

export function formatDate(timestamp: string) {
  const now = moment();
  const date = moment(timestamp, "MM-DD-YYYY");

  if (now.diff(date, "days") === 0) {
    return "Today";
  } else if (now.diff(date, "days") === 1) {
    return "Yesterday";
  } else if (now.diff(date, "weeks") === 0) {
    return date.format("dddd");
  } else {
    return date.format("MM/DD/YY");
  }
}

export function translateRoom(room: string) {
  const room_array = split(room, /([0-9])+/);
  return (
    <>
      <FormattedMessage id={trimEnd(room_array[0])} /> {room_array[1]}
    </>
  );
}

export function processNotes(inputString: string) {
  const regex = /\[(.*?)\]\s*(.*?)\s*(?=\[|$)/g;
  const matches = [];
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    matches.push({
      timestamp: trimEnd(match[1], " Process Distillery"),
      text: match[2],
    });
  }

  return matches;
}

export function formattedNotes(notes: string) {
  const notes_array = compact(notes.split(/\r?\n/));
  const notes_broke = map(notes_array, function (mys: string) {
    const message = split(mys, "Distillery] ")[1];
    const date_time = replace(
      mys.match(/\[(.*?)\]/)?.[1] || "",
      " Process Distillery",
      ""
    );
    return { date_time, message };
  });

  return notes_broke;
}

export function getQueryIDs({
  data_set,
  field_no,
}: {
  data_set: any[];
  field_no: string | number;
}) {
  const job_ids = compact(uniq(map(data_set, "job_id")));
  const where = join(
    map(job_ids, function (i) {
      return `{${field_no || "57"}.EX.${i}}`;
    }),
    "OR"
  );
  return where;
}

export function getPercentages({
  items,
  count_field = "quantity",
  status_field = "status",
  status_check,
}: {
  items: any[];
  status_field?: string;
  status_check: string;
  count_field?: string;
}) {
  const number_complete = sum(
    map(filter(items, [status_field, status_check]), count_field)
  );
  const number_in_progress = sum(
    map(filter(items, [status_field, status_check]), count_field)
  );
  const total = items.length;
  return {
    complete: (number_complete / total) * 100 + "%",
    in_progress: (number_in_progress / total) * 100 + "%",
  };
}

export function getProgress({
  items,
  count_field = "quantity",
  status_field = "status",
  status_check,
}: {
  items: any[];
  status_field?: string;
  status_check: string;
  count_field?: string;
}) {
  const number_complete = sum(
    map(filter(items, [status_field, status_check]), count_field)
  );
  const number_in_progress = sum(
    map(filter(items, [status_field, status_check]), count_field)
  );
  const total = items.length;
  return {
    complete: (number_complete / total) * 100 + "%",
    in_progress: (number_in_progress / total) * 100 + "%",
  };
}

export function changeOrder({
  id,
  setting,
  sort_fn,
}: {
  id: string;
  setting: Order;
  sort_fn: any;
}) {
  if (setting.type === id) {
    const flip = setting.dir === "asc" ? "desc" : "asc";
    sort_fn({ type: id, dir: flip });
  } else {
    sort_fn({ ...setting, type: id });
  }
}
