import { useQI } from "../../QIQueue/QIContext";
import { filter, includes, map, random } from "lodash";
import KPICardHolder from "./card_holder";
import KPICardHolderSkeleton from "./card_holder_skeleton";
import { Pie } from "react-chartjs-2";
import { FormattedMessage } from "react-intl";

export default function ActiveJobsKPI() {
  const { loading, phase_tickets } = useQI();

  const legend = [
    { phase: "Trim", color: "#83B57C" },
    { phase: "Rough", color: "#D882E7" },
    { phase: "Underground", color: "#FFB865" },
  ];
  const data = {
    datasets: [
      {
        data: map(legend, function ({ phase }: { phase: string }) {
          if (phase === "Underground") {
            return (
              filter(phase_tickets, (t: any) => {
                return includes(["Slab", "Water & Sewer"], t.phase);
              })?.length || 0
            );
          }
          return filter(phase_tickets, { phase })?.length || 0;
        }),
        backgroundColor: map(legend, "color"),
        borderColor: ["#000000"],
        borderWidth: 1,
      },
    ],
  };
  if (loading) {
    return (
      <KPICardHolderSkeleton>
        <div className="grid grid-cols-2 items-center">
          <div className="flex flex-col gap-4">
            {[1, 2, 3].map((loader: number) => {
              return (
                <div
                  className="my-1 h-8 w-24 bg-[#F4F5F8] rounded-md"
                  key={loader}
                  style={{
                    animation: `skeleton-loading linear ${random(0.9, 1.75)}s`,
                  }}
                ></div>
              );
            })}
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="w-full aspect-square bg-[#F4F5F8] rounded-full"></div>
          </div>
        </div>
      </KPICardHolderSkeleton>
    );
  }
  return (
    <KPICardHolder title="Active Jobs">
      <div className="grid grid-cols-2 items-center">
        <div className="flex flex-col gap-4">
          {legend.map((item: any) => {
            const { color, phase } = item;
            const quantity =
              filter(phase_tickets, (t: any) => {
                if (phase === "Underground") {
                  return includes(["Slab", "Water & Sewer"], t.phase);
                }
                return t.phase === phase;
              })?.length || 0;
            const percentage = phase_tickets ? (
              <span className="text-gray-400">
                {((quantity * 100) / phase_tickets.length).toFixed()}%
              </span>
            ) : (
              ""
            );
            return (
              <div key={phase} className="flex flex-col">
                <div className="flex flex-row gap-2 items-center">
                  <div
                    className={`h-4 w-4 rounded-full`}
                    style={{
                      backgroundColor: color,
                    }}
                  >
                    &nbsp;
                  </div>
                  <div className="font-bold">{quantity}</div>
                </div>
                <div className="text-xs2">
                  <FormattedMessage id={phase} /> {percentage}
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <Pie data={data} />
        </div>
      </div>
    </KPICardHolder>
  );
}
