import { useState } from "react";
import { useJob } from "../JobContext";
import { FormattedMessage } from "react-intl";
import { ReactComponent as DetailsIcon } from "../../assets/icons/Details.svg";
import { ReactComponent as DetailsGreenIcon } from "../../assets/icons/DetailsGreen.svg";
import AppLayout from "../../Layout";
import ScheduleLocations from "./locations";
import FieldNotes from "./field_notes";
import OfficeNotes from "./office_notes";
import { useUI } from "../../UIContext";
import DetailsDrawer from "../../components/JobDetails";
import HeaderButtons from "../../components/header_buttons";
import JobProgress from "./progress";
import RecordActivity from "./activity";
import Billable from "./billable";
import JobHeader from "./header";
import JobRecordSkeleton from "./skeleton";
import HeaderTitle from "../../components/header_title";

export default function RecordPage() {
  const { setLocation } = useUI();
  const [open, setOpen] = useState<string | null>(null);
  const { job_id, loading, job } = useJob();

  function JobHome() {
    return (
      <div className="flex flex-col gap-4 px-4 pb-[72px] overflow-y-touch z-0 fade-in-element show">
        <JobHeader />
        <JobProgress />
        <ScheduleLocations />
        {false && (
          <>
            {job?.notes_office && <OfficeNotes />}
            <FieldNotes />
          </>
        )}
        <Billable />
        <RecordActivity />
      </div>
    );
  }

  function FooterButton() {
    return (
      <div
        className="flex flex-col items-center text-center cursor-pointer"
        onClick={() => {
          setOpen(open === "details" ? null : "details");
        }}
      >
        <div>
          {open ? (
            <DetailsGreenIcon className="h-[21px]" />
          ) : (
            <DetailsIcon className="h-[21px]" />
          )}
        </div>
        <div
          className={`text-xs2 leading-xs2 ${
            open && "text-ppi-green"
          } font-medium`}
        >
          <FormattedMessage id="Details" />
        </div>
      </div>
    );
  }

  return (
    <AppLayout
      details={
        <div
          className="relative bg-[#F4F5F8] flex-1 h-full w-full z-50 pt-[72px] pb-[88px] overflow-y-auto pointer-events-auto"
          style={{
            transform:
              open === "details"
                ? "translate3d(0vw, 0, 0)"
                : "translate3d(100vw, 0, 0)",
            transition: "transform .3s cubic-bezier(0, .52, 0, 1)",
          }}
        >
          <DetailsDrawer job_id={job_id} setOpen={setOpen} type="install" />
        </div>
      }
      footer={<FooterButton />}
      header={
        <>
          <HeaderTitle title={job?.mobile_app_header} />
          <div className="flex flex-row items-center justify-items-end px-1 translate-y-[2px] absolute w-full">
            <div className="grow">
              <button
                className="border-2 border-black text-white bg-ppi-blue font-bold px-2 py-1 rounded-md"
                onClick={() => setLocation("/schedule")}
              >
                <FormattedMessage id="Back" />
              </button>
            </div>
            <HeaderButtons />
          </div>
        </>
      }
    >
      {loading ? <JobRecordSkeleton /> : <JobHome />}
    </AppLayout>
  );
}
