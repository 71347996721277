import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { isEmpty, map, orderBy, uniq } from "lodash";
import AppLayout from "../Layout";
import { ReactComponent as FilterIcon } from "../assets/icons/Filter.svg";
import { ReactComponent as FilterGreenIcon } from "../assets/icons/FilterGreen.svg";
import FilterDrawer from "../components/Filter";
import { useUI } from "../UIContext";
import { useQI } from "./QIContext";
import QIQueueResults from "./results";
import HeaderButtons from "../components/header_buttons";

export default function QIQueue() {
  const { setLocation } = useUI();
  const {
    default_filter,
    filter,
    phase_tickets,
    terms,
    clearFilter,
    loadFilter,
  } = useQI();
  const [open, setOpen] = useState<boolean>(false);
  const { builder, phase, status, subdivision } = filter;

  function selectItems(field: string) {
    return orderBy(uniq(map(phase_tickets, field)));
  }

  const filter_fields = [
    {
      id: "status",
      items: selectItems("status"),
      label: "Status",
      value: status,
    },
    {
      id: "phase",
      items: selectItems("phase"),
      label: "Phase",
      value: phase,
    },
    {
      id: "builder",
      items: selectItems("builder"),
      label: "Builder",
      value: builder,
    },
    {
      id: "subdivision",
      items: selectItems("subdivision"),
      label: "Subdivision",
      value: subdivision,
    },
    // { id: "lot", items: selectItems("lot"), label: "Lot", value: lot },
    // {
    //   id: "lot_",
    //   items: [],
    //   label: "Lot",
    //   type: "input",
    //   value: lot,
    // },
    // {
    //   id: "address_",
    //   items: [],
    //   label: "Street",
    //   type: "input",
    //   value: street,
    // },
    // {
    //   id: "job_id_",
    //   items: [],
    //   label: "Job ID",
    //   type: "input",
    //   value: job_id,
    // },
  ];

  return (
    <AppLayout
      edit={
        <div
          className="absolute bg-[#F4F5F8] flex-1 w-full z-50 pointer-events-auto"
          style={{
            height: "calc(100% - 144px)",
            transform: open
              ? "translate3d(0, 72px, 0)"
              : "translate3d(0, 100vh, 0)",
            transition: "transform .3s cubic-bezier(0, .52, 0, 1)",
          }}
        >
          <FilterDrawer
            default_filter={default_filter}
            header="Sort & Filter"
            items={phase_tickets || []}
            filter={filter}
            filter_fields={filter_fields}
            open={open}
            setOpen={setOpen}
            clearFilter={clearFilter}
            loadFilter={loadFilter}
          />
        </div>
      }
      footer={
        <div
          className="flex flex-col items-center text-center cursor-pointer"
          onClick={() => {
            const flip = !open;
            setOpen(flip);
          }}
        >
          {!isEmpty(terms) && (
            <div className="absolute bg-ppi-green text-white flex flex-col items-center justify-center min-w-[13px] h-[13px] rounded-full text-[10px] font-bold translate-y-[-3px] translate-x-[8px]">
              <div>{terms.length}</div>
            </div>
          )}
          <div>
            {open ? (
              <FilterGreenIcon className="h-[21px]" />
            ) : (
              <FilterIcon className="h-[21px]" />
            )}
          </div>
          <div
            className={`text-xs2 leading-xs2 ${
              open && "text-ppi-green"
            } font-medium`}
          >
            <FormattedMessage id="Filter" />
          </div>
        </div>
      }
      header={
        <>
          <div className="font-bold text-xl text-center">
            <FormattedMessage id="QI Queue" />
          </div>
          <div className="flex flex-row justify-items-end px-1 translate-y-[2px] absolute">
            <button
              className="bg-ppi-blue text-white font-bold border-2 border-black rounded-md h-8 px-2"
              onClick={() => setLocation("/")}
            >
              <FormattedMessage id="Back" />
            </button>
          </div>
          <HeaderButtons />
        </>
      }
    >
      <QIQueueResults />
    </AppLayout>
  );
}
