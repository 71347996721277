import { FormattedMessage } from "react-intl";

export default function ItemHolder({
  children,
  label,
}: {
  children: any;
  label?: string;
}) {
  return (
    <div className="flex flex-col bg-white px-4 py-2 border-2 border-black rounded-xl">
      {label && (
        <div className="border-b-2 border-black pb-2 font-bold text-center">
          <FormattedMessage id={label} />
        </div>
      )}
      <div className={`text-xs2 ${label ? "mt-1 py-1" : ""}`}>{children}</div>
    </div>
  );
}
