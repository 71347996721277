import { useUI } from "./UIContext";

export default function PopUp() {
  const { popUp } = useUI();

  function PopUpHolder({ children }: { children: any }) {
    return (
      <div className="fixed top-0 left-0 h-screen w-screen bg-black/60 z-50 flex flex-row justify-center items-center">
        <div className="m-6 p-3 bg-slate-50 min-h-40 max-w-[90%] rounded-lg overflow-hidden">
          {children}
        </div>
      </div>
    );
  }
  if (!popUp) return null;
  return <PopUpHolder>{popUp}</PopUpHolder>;
}
