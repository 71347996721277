import { useState } from "react";
import { useUI } from "../../UIContext";
import { FormattedMessage } from "react-intl";
import { REACT_APP_TABLE_PHASE_ACTIVITY } from "../../context/variables";
import { useJob } from "../JobContext";
import { useUser } from "../../UserContext";
export default function Billable() {
  const { language } = useUI();
  const { user } = useUser();
  const { job, loading_activities: loading, updatePhaseActivity } = useJob();

  const [billable, setBillable] = useState<boolean>(false);
  const [note, setNote] = useState("");
  const [amount, setAmount] = useState<string>("");
  const placeholder = language === "es" ? "Dejar una nota" : "Leave a note";

  const records = [
    {
      amount,
      billable,
      note,
      related_contact: user?.record_id,
      related_phase_ticket: job?.related_phase_ticket,
    },
  ];

  const payload = {
    table_id: REACT_APP_TABLE_PHASE_ACTIVITY,
    records,
  };

  function canSubmit() {
    if (billable) {
      return !!note && !!amount;
    } else {
      return !!note;
    }
  }
  const is_ready = canSubmit();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-end">
        <button
          className={`border-2 border-black rounded-md ${
            loading || !is_ready ? "bg-gray-400" : "bg-ppi-green"
          } text-white font-bold px-2 py-1`}
          disabled={!is_ready}
          onClick={() => {
            updatePhaseActivity(payload);
            setAmount("");
            setNote("");
          }}
        >
          <FormattedMessage id={loading ? "Submitting..." : "Submit"} />
        </button>
      </div>
      <div>
        <textarea
          className="border-2 border-black rounded-md shadow-inner p-2 w-full"
          onChange={(e: any) => setNote(e.target.value)}
          placeholder={placeholder}
          rows={5}
          value={note}
        />
      </div>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <button
            className={`border-2 border-black rounded-md ${
              billable ? "bg-ppi-green" : "bg-ppi-red"
            } text-white text-lg font-bold h-full w-full`}
            onClick={() => {
              const flip = !billable;
              setBillable(flip);
            }}
          >
            <FormattedMessage id={billable ? "Billable" : "Not Billable"} />
          </button>
        </div>
        <div>
          <input
            className={`${
              billable ? "bg-white" : "bg-gray-200"
            } border-2 border-black rounded-md shadow-inner px-2 py-1 w-full`}
            disabled={!billable}
            onChange={(e: any) => setAmount(e.target.value)}
            pattern="[0-9]*"
            placeholder={language === "es" ? "Cantidad" : "Amount"}
            value={amount}
          />
        </div>
      </div>
    </div>
  );
}
