import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UIContextProvider, { UIContext } from "./UIContext";
import PopUp from "./PopUp";
import UserContextProvider from "./UserContext";
import { IntlProvider } from "react-intl";
import messages_en from "./context/translations/en.json";
import messages_es from "./context/translations/es.json";
import SettingsContextProvider from "./Settings/SettingsContext";

// We have a DEV branch

const messages: { [key: string]: any } = {
  en: messages_en,
  es: messages_es,
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <SettingsContextProvider>
        <UIContextProvider>
          <UIContext.Consumer>
            {({ language }) => {
              return (
                <IntlProvider
                  locale={language}
                  messages={messages[language]}
                  onError={(e: any) => {
                    if (e.code === "MISSING_TRANSLATION") {
                      return null;
                    }
                  }}
                >
                  <App />
                  <PopUp />
                </IntlProvider>
              );
            }}
          </UIContext.Consumer>
        </UIContextProvider>
      </SettingsContextProvider>
    </UserContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
